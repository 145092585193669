/* eslint-disable max-len */
export const action = `
    <section class="textRegular">
        <h2 class="headerOne">Action Games — Play Online</h2>
        <p>Action games have always been at the heart of the gaming world, tracing back to the classic arcade era. The genre's roots can be traced back to the mid-1970s with the release of iconic titles such as "Space Invaders" and "Defender," which emphasised coordination, quick reactions, and combat, setting the blueprint for future action games. These games encouraged players to engage in high-speed, explosive on-screen battles and challenges that tested both their gaming skills and reflexes. Modern action games have evolved to feature diverse themes, from epic adventures, survival battles, to intense shootouts, featuring immersive storylines, complex characters, and groundbreaking graphics. The genre encompasses subgenres such as beat 'em ups, hack and slash, and shooter games, each offering unique challenges, gameplay mechanics, and experiences, ensuring there is an action game for every player to enjoy.</p>
    </section>
    <section class="textRegular">
        <h2 class="headerOne">How to Play Action Games</h2>
        <p>While the exact control schemes and mechanics may vary across different action games, the genre generally promotes fast-paced gameplay focused on real-time combat, exploration, and achieving objectives. Players may need to navigate through complex environments, defeat enemies using a variety of weapons and skills, solve puzzles, or survive against challenging opponents. Mastery of controls is key, with buttons and key combinations often responsible for different attacks, movements, and defensive manoeuvres. As players progress, they may also need to strategize, upgrade their characters or weapons, and learn to adapt to increasingly challenging scenarios.</p>
        <ul>
            <li>Understanding the game’s controls and mechanics is crucial. Get familiar with the different attacks, movements, defensive manoeuvres, and any character abilities or weapon upgrades.</li>
            <li>Timing is paramount in action games. Know when to attack, dodge, block and, importantly, when to retreat and heal.</li>
            <li>Action games can be fast-paced so a clear strategy can be the difference between victory and defeat. Always be aware of your surroundings and use the game's environment to your advantage.</li>
            <li>Practice makes perfect. You can hone your skills and reactions by replaying levels or encounters. Learning enemy patterns and tactics can provide you with an advantage.</li>
            <li>In games with multiple characters or weapons, experiment with different combinations to find what fits your playstyle best. Every game provides a unique experience, enjoy the thrill of the action!</li>
        </ul>
    </section>
`
