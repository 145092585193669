import { defineStore } from 'pinia'
import { ref } from 'vue'
import { AdMediator, AdMediatorOptions } from '@/modules/adv-service'

export const useAdsStore = defineStore('ads', () => {
    const adMediator = ref<AdMediator|null>(null)

    function setAdServiceConfig(options: AdMediatorOptions) {
        adMediator.value = new AdMediator(options)
    }

    return {
        adMediator,
        setAdServiceConfig,
    }
})
