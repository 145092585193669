<template>
    <li :class="$style.social">
        <span :class="[$style.socialTag, 'captionLight']">
            {{ item.tag }}
            <RouterLink
                v-if="item.isSupport"
                to="/contacts"
                :class="$style.supportLink"
            >
                {{ t('support') }}
            </RouterLink>
        </span>
        <div :class="$style.icon">
            <Icon
                :name="item.name"
                width="24"
                height="24"
            />
        </div>
        <span
            ref="input"
            :class="[
                $style.userName,
                isChanging ? $style.multiline : ''
            ]"
            contenteditable="true"
            @input="handleInput"
            @keydown.enter.prevent="saveAccount"
            @click="startEdit"
            @blur="saveAccount"
        >
            {{ accountTag }}
        </span>
        <Button
            v-if="isChanging"
            :title="t('save')"
            size="m"
            :class="$style.saveBtn"
            @click="saveAccount"
        />
        <Icon
            v-if="!isChanging"
            :class="$style.editBtn"
            :name="item.account ? 'pencil' : 'add'"
            @click="initEdit"
        />
        <span
            v-if="isError"
            :class="[$style.errorMessage, 'captionLight']"
        >
            {{t('error', {name: item.tag})}}
        </span>
    </li>
</template>

<script setup lang="ts">
import {
    defineProps,
    ref,
    computed,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/store/user-store'
import { type UserSocialsType } from '@/types'
import { capitalizeFirstLetter } from '@/utils/helpers'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon/index.vue'

type SocialItemProps = {
    item: UserSocialsType,
}

const props = defineProps<SocialItemProps>()
const userStore = useUserStore()
const input = ref<HTMLSpanElement|null>(null)

const { t } = useI18n()
const isChanging = ref(false)
const isError = ref(false)
const accountTag = computed(() => {
    if (isChanging.value) {
        return props.item.account || ''
    }
    if (props.item.account) {
        return props.item.account
    }
    return t('add', { name: capitalizeFirstLetter(props.item.type) })
})

function handleInput() {
    isError.value = false
}

function saveAccount() {
    if (!input.value) {
        return
    }
    const value = input.value.innerText
    if (!props.item.validate(value)) {
        isError.value = true
        return
    }
    userStore.updateSocials({
        ...props.item,
        account: value,
    })
    isChanging.value = false
}

function initEdit() {
    if (input.value) {
        isChanging.value = true
        input.value.focus()
    }
}

function startEdit() {
    isChanging.value = true
}
</script>

<style module>
.social {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.socialTag {
    width: 100%;
    margin-bottom: 4px;
    display: flex;
    color: var(--white-50)
}
.errorMessage {
    width: 100%;
    display: block;
    color: var(--error-text)
}
.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: var(--gap-small);
    border-radius: 50%;
    background: center center / cover var(--accent-purple);
}

.accountValue {
    display: flex;
    gap: var(--gap-small);
    align-items: center;
}

.userName {
    max-width: 120px;
    word-wrap: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.userName.multiline {
    outline: none;
    cursor: text;
    caret-color: var(--white);
    border-bottom: 1px solid var(--white-25);
    min-width: 100px;
    text-overflow: clip;
    word-wrap: break-word;
    white-space: normal;
}

.editBtn {
    margin-left: auto;
    cursor: pointer;
    fill-opacity: .5;
}

.editBtn:hover {
    fill-opacity: 1;
}

.saveBtn {
    margin-top: var(--gap-medium);
    width: 100%;
    height: 32px;
}

.supportLink {
    margin-left: auto;
    color: var(--white-50);
}
</style>

<i18n lang="json">
    {
        "en": {
            "add": "Add {name}",
            "save": "Save",
            "support": "Support",
            "error": "Please provide a valid {name}"
        }
    }
</i18n>
