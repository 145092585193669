import { CategoriesType } from '@/types'
import { IconNameType } from '@/components/Icon/icons-list'

export const MIN_DESKTOP_WIDTH = 750

export const MIN_DESKTOP_HEIGHT = 600

export const ICONS_FOR_CATEGORIES: Record<CategoriesType, IconNameType> = {
    all_games: 'all_games',
    recommended: 'recommended',
    trending_now: 'trending',
    new: 'new',
    popular: 'popular',
    random: 'random',
    '2_player': '2_player',
    '3d': '3d',
    action: 'action',
    adventure: 'adventure',
    arcade: 'arcade',
    baby: 'baby',
    bejeweled: 'bejeweled',
    boys: 'boys',
    clicker: 'clicker',
    cooking: 'cooking',
    farming: 'farming',
    girls: 'girls',
    hypercasual: 'hypercasual',
    io: 'io',
    multiplayer: 'multiplayer',
    puzzle: 'puzzle',
    racing: 'racing',
    shooting: 'shooting',
    sports: 'sports',
    stickman: 'stickman',
    soccer: 'soccer',
    social: 'social',
    in_game_purchase: 'in_game_purchase',
    cards: 'cards',
    kids: 'kids',
    educational: 'educational',
    quiz: 'quiz',
    simulator: 'simulator',
    strategy: 'strategy',
    tabletop: 'tabletop',
    match3: 'match3',
    role: 'role',
    casino: 'casino',
    balloons: 'balloons',
    economic: 'economic',
    midcore: 'midcore',
    horrors: 'horrors',
    imitations: 'imitations',
    applications: 'applications',
    tests: 'tests',
    top_picks: 'popular',
}
