export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get"])},
        "play-mins": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Be playing any game for ", _interpolate(_named("mins")), " minutes"])},
        "play-games": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Play ", _interpolate(_named("count")), " games"])}
      }
    }
  })
}
