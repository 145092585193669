<template>
    <div :class="$style.rotate">
        <Icon name="rotate" :class="$style.icon" />
        <p class="headerOne">{{ t('rotate') }}</p>
        <p class="textRegular">{{ t('message', { orientation }) }}</p>
    </div>
</template>

<script lang="ts" setup>
import { defineProps } from 'vue'
import { useI18n } from 'vue-i18n'
import Icon from '@/components/Icon/index.vue'

interface RotateMessageProps {
    orientation: string
}

defineProps<RotateMessageProps>()

const { t } = useI18n()
</script>

<style module>
.rotate {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: var(--active);
    border-radius: var(--radius-regular);
    z-index: 2;
}

.icon {
    max-width: 50%;
    max-height: 50%;
}
</style>

<i18n lang="json">
{
    "en": {
        "rotate": "Rotate phone",
        "message": "The game supports only { orientation } orientation"
    }
}
</i18n>
