export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
        "ended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop ended"])},
        "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In"])},
        "accountLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Account limit ", _interpolate(_named("purchased")), "/", _interpolate(_named("limit"))])}
      }
    }
  })
}
