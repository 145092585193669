<template>
    <div :class="$style.threeColContainer">
        <div :class="$style.leftSide">
            <slot name="leftSide">
            </slot>
        </div>
        <div :class="$style.content">
            <PlaygamaHeader />
            <slot />
        </div>
        <div :class="$style.rightSide">
            <slot name="rightSide" />
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import PlaygamaHeader from '@/components/PlaygamaHeader/PlaygamaHeader.vue'

export default defineComponent({
    name: 'ThreeColumnsLayout',
    components: {
        PlaygamaHeader,
    },
})
</script>

<style module>
.threeColContainer {
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    display: flex;
}

.leftSide {
    width: 208px;
    padding: var(--gap-large);
    box-sizing: border-box;
    border-right: 1px solid var(--white-25);
}

.content {
    flex-grow: 1;
    max-width: calc(100% - 576px); /* 576 = 208 + 368 */
    border-right: 1px solid var(--white-25);
    padding: var(--gap-large);
    box-sizing: border-box;
}

.rightSide {
    width: 368px;
    padding: var(--gap-large);
    box-sizing: border-box;
}

@media (--desktop-small) {
    .leftSide {
        width: 192px;
        padding-left: var(--gap-regular);
        padding-right: var(--gap-regular);
    }

    .rightSide {
        display: none;
    }

    .content {
        max-width: calc(100% - 192px);
        border-right: none;
        padding-left: var(--gap-regular);
        padding-right: var(--gap-regular);
    }
}

@media (--mobile) or (--tablet) {
    .threeColContainer {
        flex-direction: column;
    }

    .leftSide {
        width: 100%;
        padding: 20px 20px 0;
        border-right: none;
        border-bottom: 1px solid var(--white-25);
    }

    .content {
        width: 100%;
        min-width: 100%;
        border-right: none;
        padding: 20px var(--gap-regular);
    }

    .rightSide {
        display: none;
    }
}
</style>
