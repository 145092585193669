<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none"><path fill="#fff" fill-opacity="inherit" d="m17.586 2.586-1.172-1.171a3 3 0 0 0-4.242 0L0 13.586V19h5.414L17.586 6.83a3 3 0 0 0 0-4.243M4.586 17H2v-2.585l9-9L13.586 8zM16.172 5.414 15 6.586l-2.586-2.585 1.172-1.172a1.003 1.003 0 0 1 1.414 0L16.172 4a1.003 1.003 0 0 1 0 1.413"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Pencil',
})
</script>
