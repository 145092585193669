<template>
    <div>
        <div id="g_id_onload"
             :data-client_id="VUE_APP_GAUTH_CLID"
             :data-login_uri="VUE_APP_GAUTH_REDIRECT"
             data-auto_prompt="false"
        >
        </div>
        <div class="g_id_signin"
             data-type="standard"
             data-size="large"
             data-theme="outline"
             data-text="sign_in_with"
             data-shape="rectangular"
             data-logo_alignment="left">
        </div>
    </div>
</template>
<script lang="ts" setup>
import { onBeforeMount } from 'vue'

const { VUE_APP_GAUTH_CLID, VUE_APP_GAUTH_REDIRECT } = process.env

onBeforeMount(() => {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    document.body.appendChild(script)
})
</script>
