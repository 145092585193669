export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in you Playgama Account"])},
        "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Playgama and Track Your Gaming Progress!"])}
      }
    }
  })
}
