<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M17.6 2.406c-2.164 0-2.484 2.065-3.58 3.161l-4.193 4.26 5.342 5.342 4.577-4.663c1.19-1.213 1.854-2.603 1.854-4.095 0-1.25-1.1-4.005-4-4.005m-3.2 4.8c.444 0 .8.356.8.8s-.356.8-.8.8a.797.797 0 0 1-.8-.8c0-.444.356-.8.8-.8m2.4 2.4c.444 0 .8.356.8.8s-.356.8-.8.8a.797.797 0 0 1-.8-.8c0-.444.356-.8.8-.8m-6.98.22L5.562 14.02c-1.096 1.097-3.16 1.417-3.16 3.581 0 2.9 2.754 4 4.004 4 1.492 0 2.882-.664 4.094-1.855l4.662-4.576zM20.88 12a1.35 1.35 0 0 0-.586.137c-.2.094-.392.23-.56.397-.264.265-.448.59-.51.901-.063.312.002.587.178.763.177.177.451.241.763.179.312-.063.636-.247.901-.511.265-.265.449-.59.511-.901s-.002-.587-.178-.763A.74.74 0 0 0 20.88 12M8 13.6c.444 0 .8.356.8.8s-.356.8-.8.8a.797.797 0 0 1-.8-.8c0-.444.356-.8.8-.8m2.4 2.4c.444 0 .8.356.8.8s-.356.8-.8.8a.797.797 0 0 1-.8-.8c0-.444.356-.8.8-.8m3.275 3.2a1.35 1.35 0 0 0-.586.137c-.2.094-.392.23-.56.397-.264.265-.448.59-.51.901-.063.312.001.587.178.763.176.177.45.241.763.179.312-.063.636-.247.9-.511.265-.265.45-.59.512-.901s-.002-.587-.178-.763a.74.74 0 0 0-.52-.202"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Boys',
})
</script>
