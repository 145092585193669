<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M12.4.906a3.99 3.99 0 0 0-3.25 1.672A4 4 0 0 0 4 6.411c0 .104 0 .207.016.303A3.185 3.185 0 0 0 1.6 9.811c0 .594.166 1.142.446 1.614a2.4 2.4 0 0 0-.389.858 2.41 2.41 0 0 0 1.085 2.572q.282.17.61.264v.092c0 1.216.527 2.08 1.071 2.551.328.288.473.297.69.385.295.744 2.127 5 6.943 5.056q.048.01.095.008.049.002.097-.008c4.816-.056 6.648-4.312 6.936-5.056.224-.088.36-.096.695-.384.544-.473 1.072-1.336 1.072-2.552v-.2c.264-.114.505-.272.71-.472.412-.384.681-.922.73-1.517.009-.067.009-.144.009-.211a2.4 2.4 0 0 0-.368-1.266 3.25 3.25 0 0 0 .568-1.839c0-1-.448-1.903-1.16-2.503a4 4 0 0 0 .064-.697 4 4 0 0 0-5.664-3.64A3.99 3.99 0 0 0 12.4.906m1.664 7.64q.226.387.536.712c.4.424.888.76 1.44.976.16.984.743 1.825 1.567 2.313.002.024.012.046.015.07-.006.065-.022.127-.022.194 0 .864.46 1.621 1.142 2.044q.282.17.61.264v.092c0 .784-.273 1.12-.529 1.351-.256.224-.464.272-.464.272l-.431.105-.136.416s-1.377 4.256-5.64 4.256c-4.265 0-5.64-4.256-5.64-4.256l-.136-.416-.433-.105s-.208-.047-.464-.271c-.256-.232-.528-.568-.528-1.352v-.2c.264-.114.505-.272.71-.472.412-.384.681-.922.73-1.517.009-.067.009-.144.009-.211v-.23a3.2 3.2 0 0 0 1.151-1.139c.192-.312.32-.663.392-1.031H8a3.99 3.99 0 0 0 3.248-1.672q.552.168 1.152.167c.592 0 1.16-.127 1.664-.36M8.55 12.812a1.2 1.2 0 1 0 0 2.4 1.2 1.2 0 0 0 0-2.4m7.2 0a1.2 1.2 0 1 0 0 2.4 1.2 1.2 0 0 0 0-2.4M12 15.36a2.4 2.4 0 1 0 0 4.8 2.4 2.4 0 0 0 0-4.8"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Imitations',
})
</script>
