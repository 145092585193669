<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M10.46 19.999a1 1 0 0 1-1-.996c-.003-1.365-.008-3.844-.01-5.559a1 1 0 0 0-1-.999H6.325c-.878-.002-1.329-1.052-.725-1.69l5.684-5.99a1 1 0 0 1 1.451 0l5.67 5.985c.603.636.153 1.686-.724 1.688l-2.124.004a1 1 0 0 0-.998 1L14.557 19a1 1 0 0 1-1 1z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Up',
})
</script>
