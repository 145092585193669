<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><rect width="24" height="5" x="1.747" y="17.218" fill="#fff" rx="1" transform="rotate(-45 1.747 17.218)"/><rect width="24" height="5" x="5.282" y=".247" fill="#fff" rx="1" transform="rotate(45 5.282 .247)"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Cross',
})
</script>
