<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="m17.656 16.827-1.136-1.136a9.54 9.54 0 0 1-2.688-5.376h1.632V8.587l-1.72-.864h-.008V7.26a3.44 3.44 0 0 0 1.728-2.992A3.45 3.45 0 0 0 12.008.811a3.45 3.45 0 0 0-3.456 3.456A3.44 3.44 0 0 0 10.28 7.26v.464l-1.728.864v1.728h1.632a9.54 9.54 0 0 1-2.688 5.376L6.36 16.827a4.3 4.3 0 0 0-1.264 3.048v1.672c4.4 1.176 9 1.184 13.824 0v-1.672c0-1.144-.456-2.24-1.264-3.048m-6.344-13.52c-.664.664-1.472.936-1.8.608-.336-.336-.064-1.144.6-1.808.656-.656 1.464-.928 1.8-.592.328.328.056 1.136-.6 1.792"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Tabletop',
})
</script>
