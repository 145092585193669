<template>
    <li :class="styles.about">
        <h1>{{ t('aboutTitle') }}</h1>
        <p class="textRegular" :class="styles.aboutText">{{ t('aboutText') }}</p>
        <ul :class="styles.categories">
            <li v-for="category in categories" :key="category.name">
                <CategoryButton
                    :name="t(category.name)"
                    :link="category.link"
                    type="highlighted"
                    :isActive="false"
                />
            </li>
        </ul>
    </li>
</template>

<script lang="ts" setup>
import { useCssModule } from 'vue'
import { useI18n } from 'vue-i18n'
import CategoryButton from '@/components/CategoryButton.vue'
import translations from './translations'

const { t } = useI18n({ messages: translations })
const styles = useCssModule()

const categories = [
    {
        name: 'action',
        link: '/category/action',
    },
    {
        name: 'adventure',
        link: '/category/adventure',
    },
    {
        name: 'arcade',
        link: '/category/arcade',
    },
    {
        name: 'cards',
        link: '/category/cards',
    },
    {
        name: 'casino',
        link: '/category/casino',
    },
    {
        name: 'hypercasual',
        link: '/category/hypercasual',
    },
]
</script>

<style module>
.about {
    padding: 48px var(--gap-large);
    text-align: center;
}

.aboutText {
    margin-top: var(--gap-small);
}

.categories {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: var(--gap-small);
    margin-top: var(--gap-large);
}
</style>
