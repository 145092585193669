<template>
    <DashboardCard>
        <template #title>
            {{ t('spend') }}
            <img
                src="/img/fennec-coin.png"
                alt="fennec coin"
                :class="$style.fennecCoin"
            />
            {{ t('coins', { total: fennecsLabel }) }}
        </template>
        <ul :class="$style.list">
            <Item
                v-for="item in shopItems"
                :key="item._id"
                :item="item"
                :isDisabled="item.price > fennecs || isBuyingDisabled"
                @buyItem="buyItem"
            />
        </ul>
    </DashboardCard>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ShopItem } from '@/types'
import DashboardCard from './DashboardCard.vue'
import Item from './ShopItem.vue'

type ShopwindowProps = {
    fennecs: number,
    fennecsLabel: string,
    shopItems: ShopItem[],
    isBuyingDisabled: boolean,
}

defineProps<ShopwindowProps>()
/* eslint-disable no-unused-vars */
const emit = defineEmits<{(e: 'buyItem', item: ShopItem): void}>()
const isBuyingDisabled = ref(false)

const { t } = useI18n()

function buyItem(item: ShopItem) {
    emit('buyItem', item)
}
</script>

<style module>
.fennecCoin {
    width: 24px;
    height: 24px;
}

.list {
    display: flex;
    flex-direction: column;
    gap: var(--gap-small);
}
</style>

<i18n lang="json">
{
    "en": {
        "spend": "Shop! Spend",
        "coins": "{ total } coins"
    }
}
</i18n>
