/* eslint-disable max-len */
export const sports = `
    <section class="textRegular">
        <h2 class="headerOne">Sports Games — Play Online</h2>
        <p>Welcome to the exciting world of online Sports Games where competition meets fun! This game genre simulates the practice of traditional sports and their diverse flavors provide different challenges for every type of player. To journey through history, Pong, an arcade game developed by Atari, can be deemed as the forerunner of electronic sports games. Ever since then, the evolution of technology and software has given us realistic and immersive experiences that were hard to dream of during Pong’s era. From football, basketball, baseball and hockey to motorsports, golf, cricket and even windsurfing, we have a plethora of options for you to choose from. Whether you're a fan of team sports or prefer solo activities, our range of sports games will transport you directly onto the pitch, the track or the Olympic stadium!</p>
    </section>
    <section class="textRegular">
        <h2 class="headerOne">How to Play Sports Games Online</h2>
        <p>The game mechanics of sports games differ widely based on the sport in question. For instance, a football or basketball game involves running, passing, shooting and scoring points. On the other hand, sports like golf demand accurate swings and an understanding of the physics involved. Regardless of the sport, mastering the controls is the first step to victory. Our sports games replicate the rules and thrill of real sports, offering a blend of skill, strategy and luck. Whether it's getting the ideal tee-off in golf, scoring a last-minute goal in football, or beating the world record in hurdles, it all comes down to timing and precision.</p>
        <ul>
            <li>Start off by learning the game controls. Each game will have different key bindings or touch controls for different actions. This can range from running, passing, shooting to controlling speed and direction.</li>
            <li>Understanding the rules of the game is vital! Whether it's scoring points through goals in football or hitting pitches in baseball, every game has its unique rules and ways to win.</li>
            <li>Practice makes perfect. The more you play, the better you will become. Each game is a chance to improve your skills and strategies for future matches.</li>
            <li>Plan your game strategy wisely, playing aggressively or defensively can make a huge difference with each sport having different opportunities and vulnerabilities</li>
            <li>Lastly, don’t forget to have fun! Games are all about enjoyment and our range of sports games are designed to give you hours of entertainment.</li>
        </ul>
    </section>
`
