import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { GameType } from '@/types'
import { getGameByParam } from '@/utils/requests/games'

export const useGamePageStore = defineStore('gamePage', () => {
    const game = ref<GameType | undefined>(undefined)

    function setGame(param: string): Promise<GameType> {
        return getGameByParam(param).then((data) => {
            game.value = data
            return data
        })
    }

    function resetGameData(): void {
        game.value = undefined
    }

    return {
        game,
        setGame,
        resetGameData,
    }
})
