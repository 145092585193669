<template>
    <RouterView v-if="pageStatus === 200" />
    <NotFoundPage v-if="pageStatus === 404" />
</template>
<script lang="ts" setup>
// необходимый для SSR и правильных статусов ответов враппер
import {
    computed, onBeforeMount, onMounted, onServerPrefetch, ref, useSSRContext,
} from 'vue'
import { storeToRefs } from 'pinia'
import { RouteLocationNormalized, useRoute, useRouter } from 'vue-router'
import { useHead } from '@unhead/vue'
import { useAppStore } from '@/store/app-store'
import { useAdsStore } from '@/store/ads-store'
import { useLoggerStore } from '@/store/logger-store'
import { useCategoriesStore } from '@/store/categories-store'
import NotFoundPage from '@/pages/NotFoundPage.vue'
import { getCurrentPlatform } from '@/utils/helpers'
import { PlatformType, GameProviders } from '@/types'
import { useUserStore } from '@/store/user-store'
import { sendEvent } from '@/utils/analytics'

const appStore = useAppStore()
const adsStore = useAdsStore()
const loggerStore = useLoggerStore()
const $router = useRouter()
const $route = useRoute()

const categoriesStore = useCategoriesStore()

const {
    pageStatus,
} = storeToRefs(appStore)

const userStore = useUserStore()

const CLID_PARAM_NAME = 'clid'
const GD_GAME_ID_KEY = 'gd_game_id'
const GD_KEY = 'use_gd_ads'

function getPageScripts(route: typeof $route) {
    const scripts = []
    if (!route.query[GD_KEY]) {
        scripts.push(
            // gtm
            `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer', '${process.env.VUE_APP_GA_MEASUREMENT_ID}');`,
            // metrika
            `(function m (m,e,t,r,i,k,a){
                m[i]=m[i] || function() {
                    (m[i].a=m[i].a||[]).push(arguments)
                };
            m[i].l=1*new Date();

            for (var j = 0; j < document.scripts.length; j++) {
                if (document.scripts[j].src === r) {
                    return;
                }
            }
            k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)
            })
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

            ym(96742331, "init", {
                    clickmap:true,
                    trackLinks:true,
                    accurateTrackBounce:true
            });`,
        )
    }

    return scripts
}

// предзаданные параметры страницы
useHead(computed(() => ({
    title: 'Free Online Games Now on Playgama 🎮 Play NOW!',
    meta: [
        {
            name: 'description',
            content: 'Discover the best free online games at Playgama! Enjoy a wide variety of high-quality browser games without downloads, registration, or pop-ups. ✨ Play now on desktop, tablet, and mobile. 🕹️',
        },
        {
            name: 'validation-token',
            content: 'hb42hn0e5uyz0bfp',
        },
    ],
    script: getPageScripts($route),
})))

const ctx = useSSRContext()
const loggerPingInterval = ref<ReturnType<typeof setInterval>>()

onServerPrefetch(() => {
    appStore.$reset()
    appStore.setPlatform(ctx?.platform as PlatformType)
})

function getPageInfo(page: RouteLocationNormalized) {
    return `${page.name?.toString()} ${page.params.game || page.params.category || ''}`
}

onBeforeMount(async () => {
    let currentPageSessionStartedAt = Date.now()
    const currentSessionStartedAt = Date.now()
    let currentPageData = getPageInfo($route)

    $router.beforeEach((to) => {
        appStore.setPageStatus(200)

        const delta = Date.now() - currentPageSessionStartedAt
        loggerStore.logEvent({
            event: 'user_time',
            eventName: 'navigation',
            label: delta / 1000,
            action: currentPageData,
            pageName: $route.name as string || undefined,
        })

        currentPageSessionStartedAt = Date.now()
        currentPageData = getPageInfo(to)
    })

    window.addEventListener('beforeunload', () => {
        const delta = Date.now() - currentPageSessionStartedAt
        const sessionTime = Date.now() - currentSessionStartedAt
        loggerStore.logEvent({
            event: 'user_time',
            eventName: 'navigation',
            label: delta / 1000,
            action: currentPageData,
            pageName: $route.name as string || undefined,
        })

        loggerStore.logEvent({
            event: 'user_time',
            eventName: 'session_end',
            label: sessionTime / 1000,
            action: currentPageData,
            pageName: $route.name as string || undefined,
        })
    })

    const ua = window.navigator.userAgent
    const platform = getCurrentPlatform(ua)
    appStore.setPlatform(platform)

    const isGD = !!$route.query[GD_KEY]
    const clid = $route.query[CLID_PARAM_NAME]
    if (clid) {
        appStore.setClid(clid as string)
    }
    const GD_GAME_ID = $route.query[GD_GAME_ID_KEY]
    if (GD_GAME_ID) {
        window.gd_id = GD_GAME_ID as string
    }

    const configKey = isGD ? GameProviders.GAME_DISTRIBUTION : GameProviders.GOOGLE_AD
    adsStore.setAdServiceConfig({
        configKey,
        targeting: { playgama_clid: clid as string || 'direct' },
        logEvent: loggerStore.logEvent,
        route: $route,
    })
})

function checkTasks() {
    if (
        !document.hidden
    ) {
        // todo: websockets
        setInterval(() => {
            userStore.getUserTasks()
        }, 1000 * 60)
    }
}

onMounted(async () => {
    if ($route.name !== 'export-game-page') {
        categoriesStore.setRecommendedGames() // ignore the promise intentionally
        userStore.getUserTasks() // ignore the promise intentionally
        const user = await userStore.getUser()
        sendEvent({
            eventName: 'session_start',
            userId: user ? user._id : undefined,
            clid: appStore.clid,
            pageName: $route.name as string || undefined,
        })

        if (user) {
            checkTasks()
        }

        loggerPingInterval.value = setInterval(() => {
            if (
                !document.hidden
            ) {
                loggerStore.logEvent({
                    event: 'custom_event',
                    eventName: 'ping',
                    label: '',
                    action: '',
                    pageName: $route.name as string || undefined,
                })
            }
        }, 15000)

        appStore.initAuthBannerOpen()
    }
})

</script>
