export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
        "fullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullscreen"])},
        "problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a Problem"])}
      }
    }
  })
}
