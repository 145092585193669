<template>
    <!-- eslint-disable max-len -->
    <DashboardCard>
        <template #title>
            {{ t('profile') }}
        </template>
        <ul :class="$style.socialList">
            <li>
                <span :class="[$style.socialTag, 'captionLight']">{{ t('username') }}</span>
                <div :class="$style.userName">
                    <UserAvatar
                        :class="$style.avatar"
                        :avatar="avatar"
                        :firstName="firstName"
                        :lastName="lastName"
                    />
                    <span :class="$style.word">{{ firstName }}</span>
                    <span :class="$style.word">{{ lastName }}</span>
                </div>
            </li>
            <SocialItem
                v-for="social in socials"
                :key="social.tag"
                :item="social"
            />
        </ul>
    </DashboardCard>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type UserSocialsType } from '@/types'
import UserAvatar from '@/components/UserAvatar.vue'
import DashboardCard from './DashboardCard.vue'
import SocialItem from './SocialItem.vue'

defineProps<{
    avatar: string | undefined
    firstName: string
    lastName: string,
    socials: UserSocialsType[],
}>()

const { t } = useI18n()
</script>

<style module>
.socialList {
    display: flex;
    flex-direction: column;
    gap: var(--gap-regular);
}

.socialTag {
    display: block;
    width: 100%;
    margin-bottom: 4px;
    color: var(--white-50);
}

.avatar {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: center center / cover var(--accent-purple);
}

.userName {
    display: flex;
    width: 100%;
    line-height: 24px;
    justify-content: flex-start;
    align-items: center;
    gap: 0 var(--gap-small);
    flex-wrap: wrap;
}

.word {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 208px;
}

.editBtn {
    margin-left: auto;
}
</style>

<i18n lang="json">
{
    "en": {
        "profile": "Profile",
        "username": "Username"
    }
}
</i18n>
