<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none"><path fill="#fff" d="M11.42.92C5.332.92.38 5.227.38 10.52c0 2.97 1.601 5.792 4.296 7.603-.064.504-.32 1.729-1.328 3.161l-.53.752.948.004c2.606 0 4.369-1.598 4.948-2.21.88.192 1.789.29 2.706.29 6.088 0 11.04-4.307 11.04-9.6S17.508.92 11.42.92"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Social',
})
</script>
