export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 Level Not Found"])},
        "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, the page you requested does not exist on this site"])},
        "alt-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])},
        "randomGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play random game"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back home"])}
      }
    }
  })
}
