<template>
    <Layout>
        <template #leftSide>
            <LeftSide
                :highlightCategories="menuHighlightCategoriesList"
                :categories="menuCategoriesList"
            />
        </template>
        <template #default>
            <div :class="$style.banner">
                <img :class="$style.img" src="/img/404.png" :alt="t('alt-text')">
                <h1>{{t('header')}}</h1>
                <span class="subheader">{{t('subheader')}}</span>
                <div :class="$style.buttons">
                    <Button
                        :title="t('back')"
                        :class="$style.button"
                        link="/"
                        size="l"
                    />
                    <Button
                        v-if="randomGame"
                        :title="t('randomGame')"
                        :class="$style.button"
                        :link="randomGame.href"
                        size="l"
                    />
                </div>
            </div>
            <GameCardsList
                v-if="popularGames?.length"
                appearance="small"
                :title="t('popular')"
                :icon="ICONS_FOR_CATEGORIES['popular']"
                :class="$style.category"
                :games="popularGames"
            />
            <GameCardsList
                v-if="randomGames?.length"
                appearance="small"
                :title="t('random')"
                :icon="ICONS_FOR_CATEGORIES['random']"
                :class="$style.category"
                :games="randomGames"
            />
        </template>
        <template #rightSide>
            <RightSide :recentlyGames="recommendedGames" />
        </template>
    </Layout>
</template>
<script setup lang="ts">
import {
    onServerPrefetch,
    onMounted,
} from 'vue'
import { storeToRefs } from 'pinia'
import { useCategoriesStore } from '@/store/categories-store'
import Layout from '@/layouts/ThreeColumnsLayout.vue'
import LeftSide from '@/components/LeftSide.vue'
import RightSide from '@/components/RightSide.vue'
import { useI18n } from 'vue-i18n'
import { categoriesTranslation } from '@/utils/translations/categories'
import { ICONS_FOR_CATEGORIES } from '@/constants/general'

import Button from '@/components/Button.vue'
import GameCardsList from '@/components/GameCardsList.vue'

const categoriesStore = useCategoriesStore()
const {
    contentCategoriesList,
    menuCategoriesList,
    menuHighlightCategoriesList,
    recommendedGames,
    randomGame,
    popularGames,
    randomGames,
} = storeToRefs(categoriesStore)
const { t } = useI18n({
    messages: {
        ...categoriesTranslation,
    },
})

onServerPrefetch(async () => {
    await categoriesStore.setMainData()
})

onMounted(async () => {
    // Prevents the request from being made twice if the data is already in the store
    if (contentCategoriesList.value.length) {
        return
    }
    await categoriesStore.setMainData()
})
</script>

<style module>
.banner {
    background-color: var(--black-russian);
    box-shadow: 0 0 48px 0 var(--shadow);
    border-radius: var(--radius-regular);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--gap-large);
    gap: var(--gap-regular);
}

.img {
    width: 307px;
    height: 128px;
}
.category {
    margin-top: var(--gap-large);
}
.buttons {
    display: flex;
    justify-content: center;
    gap: var(--gap-small);
    flex-wrap: wrap;
    margin-top: var(--gap-small);
}

@media (--mobile) {
    .img {
        width: 191px;
        height: 80px;
    }
    .button {
        width: 100%;
    }
}
@media (--tablet) {
    .img {
        width: 191px;
        height: 80px;
    }
    .button {
        width: 100%;
    }
}
</style>
<i18n lang="json">
    {
        "en": {
            "header": "404 Level Not Found",
            "subheader": "Sorry, the page you requested does not exist on this site",
            "alt-text": "Not found",
            "randomGame": "Play random game",
            "back": "Go back home"
        }
    }
</i18n>
