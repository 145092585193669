export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["page ", _interpolate(_named("page"))])},
        "ads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ads"])}
      }
    }
  })
}
