<template>
    <NotFoundPage />
</template>

<script setup lang="ts">
import { onServerPrefetch } from 'vue'
import { useAppStore } from '@/store/app-store'
import NotFoundPage from '@/pages/NotFoundPage.vue'

const appStore = useAppStore()
onServerPrefetch(() => {
    appStore.setPageStatus(404)
})
</script>
