import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { CategoriesType, SimplifiedGame } from '@/types'
import { getGamesByCategories } from '@/utils/requests/games'
import { PlatformType, PaginationType, CategoryDataType } from '@/types'
import { useAppStore } from '@/store/app-store'
import { categoriesDescriptions, categoriesNamesTranslation } from '@/utils/translations/categories'
import { categoriesImages } from './category-images'

const PAGE_SIZE = 48

type SetCategoryParams = {
    categoryName: string,
    page?: number
}

type UpdateListRequestParams = {
    categoryName: CategoriesType,
    platform: PlatformType,
    pagination: PaginationType,
}

type Category = {
    id: CategoriesType | null,
    total: number,
    description: string,
    name: string,
    totalPages: number,
    img: string,
}

function updateList({ categoryName, platform, pagination }: UpdateListRequestParams): Promise<CategoryDataType> {
    return getGamesByCategories({
        categoriesIds: [categoryName],
        platform,
        pagination,
    })
}

export const useCategoryPageStore = defineStore('categoryPage', () => {
    const categoryGames = ref<SimplifiedGame[]>([])
    const pagination = ref<PaginationType>({ page: 1, pageSize: PAGE_SIZE })
    const category = ref<Category>({
        name: '',
        description: '',
        id: null,
        total: 0,
        totalPages: 0,
        img: '',
    })

    const appStore = useAppStore()

    function setCategory(params: SetCategoryParams): Promise<CategoryDataType|undefined> {
        if (
            category.value.id === params.categoryName
            && pagination.value.page === params.page
            && categoryGames.value.length > 0
        ) {
            // не грузим данные если они уже есть и именно такие
            return Promise.resolve(undefined)
        }
        // loader
        categoryGames.value = []
        if (category.value.id !== params.categoryName) {
            if (!categoriesNamesTranslation.en[params.categoryName as CategoriesType]) {
                // Скорее всего 404
                category.value = {
                    id: null,
                    name: '',
                    total: 0,
                    totalPages: 0,
                    description: '', // temp
                    img: '',
                }
            } else {
                // пока эти данные не отдаем с бэка можно менять их сразу, без ожидания списка игр и пагинации
                category.value = {
                    id: params.categoryName as CategoriesType,
                    name: categoriesNamesTranslation.en[params.categoryName as CategoriesType], // en locale temp
                    total: 0,
                    totalPages: 0,
                    description: categoriesDescriptions[params.categoryName as CategoriesType], // temp
                    img: categoriesImages[params.categoryName as CategoriesType],
                }
            }
        }

        pagination.value.page = params.page || 1
        // loader
        return updateList({
            categoryName: params.categoryName as CategoriesType,
            pagination: pagination.value,
            platform: appStore.platform,
        }).then((data) => {
            if (!data.categories[0]) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(404)
            }
            categoryGames.value = data.categories[0].games
            category.value = {
                id: data.categories[0].id,
                name: categoriesNamesTranslation.en[data.categories[0].id], // en locale temp
                total: data.categories[0].totalCount,
                totalPages: Math.ceil(data.categories[0].totalCount / PAGE_SIZE),
                description: categoriesDescriptions[data.categories[0].id], // temp
                img: categoriesImages[data.categories[0].id],
            }
            return data
        })
    }

    return {
        categoryGames,
        category,
        setCategory,
    }
})
