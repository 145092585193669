<template>
    <img
        v-if="avatar && !userpicError"
        :alt="`${firstName} ${lastName} photo`"
        :src="avatar"
        @error="onUserpicLoadError"
    />
    <span v-else>
        {{ avatarPlaceholder }}
    </span>
</template>

<script lang="ts" setup>
import {
    ref,
    computed,
    defineProps,
    watch,
} from 'vue'

type UserAvatarProps = {
    avatar?: string,
    firstName: string,
    lastName: string,
}

const props = defineProps<UserAvatarProps>()
const userpicError = ref(false)

const avatarPlaceholder = computed(() => {
    if (!props.avatar) {
        return ''
    }
    return `${props.firstName[0].toUpperCase()}${props.lastName[0].toUpperCase()}`
})

watch(() => props.avatar, () => {
    userpicError.value = false
})

function onUserpicLoadError() {
    userpicError.value = true
}
</script>
