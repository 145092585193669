<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" fill="none"><path fill="#fff" d="M1.4 0a.8.8 0 0 0-.8.8v12.8c0 .373.26.672.606.76L6.2 15.804V1.367L1.715.053A1 1 0 0 0 1.4 0m12.8.148L7.8 1.44v14.399l6.4-1.279zM15.8.2v14.434l4.545 1.314c.08.028.164.052.255.052a.8.8 0 0 0 .8-.8V2.4c0-.38-.273-.656-.625-.764z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Adventure',
})
</script>
