<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none"><path fill="#fff" d="M21.728 17.728a2 2 0 0 0 0-2.829l-8.485-8.485a2 2 0 1 0-2.829 2.829l5.657 5.656a2 2 0 0 1 0 2.829l-5.657 5.657a2 2 0 0 0 2.829 2.828z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Arrow',
})
</script>
