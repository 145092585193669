<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><g clip-path="url(#a)"><path fill="#fff" d="M8.56 0q-.21 0-.4.006V5.76a.48.48 0 0 1-.96 0V.07C6.024.21 5.569.502 5.47.576l-.16.121L5.284.9c-.02.16-.484 3.9-.484 6.3 0 1.884 1.082 2.692 1.798 3.225.322.242.602.45.602.615 0 .394-.075 1.3-.163 2.35-.143 1.7-.32 3.815-.317 5.33 0 3.947.484 5.278 1.918 5.28h.01c.472 0 1.06-.114 1.434-1.009.328-.782.48-2.14.478-4.271 0-1.513-.175-3.624-.315-5.321-.088-1.054-.165-1.965-.165-2.36 0-.162.275-.363.592-.597.72-.529 1.808-1.328 1.808-3.242 0-1.583-.463-6.098-.482-6.289l-.026-.238-.205-.124C11.7.51 11.21.234 10.08.09v5.67a.48.48 0 0 1-.96 0V.014A13 13 0 0 0 8.56 0m6.277.002A.48.48 0 0 0 14.4.48v5.28c0 .1.005.622.013 1.312.013 1.352.034 3.347.034 4.041.002.43-.098 1.843-.205 3.34-.152 2.15-.322 4.59-.322 5.707 0 2.653.135 3.816 1.44 3.817 1.095 0 2.4-.995 2.4-5.737-.002-1.558-.379-2.7-.653-3.533-.172-.519-.307-.931-.307-1.267-.002-.673.47-1.524.967-2.423.688-1.24 1.467-2.647 1.468-4.261-.001-1.157-.303-5.063-4.168-6.717a.5.5 0 0 0-.23-.037"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Cooking',
})
</script>
