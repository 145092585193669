/* eslint-disable max-len */
export const io = `
    <section class="textRegular">
        <h2 class="headerOne">io Games — Play Online</h2>
        <p>Welcome to our io games category, where you can find a vast array of online multiplayer games that will keep you engaged and entertained for hours on end. The io genre is a unique and captivating multiplayer experience that emerged from the online gaming industry in the mid-2010s, and it has been gaining in popularity ever since. This game genre derives its name from the domain name extension ".io", initially used by the very first games of this genre, like Agar.io and Slither.io, which have reached legendary status among online gamers. Our io games category is a tribute to this phenomenon, offering numerous games incorporating high-speed, high-intensity, and highly social elements that define the io genre.</p>
    </section>
    <section class="textRegular">
        <h2 class="headerOne">How To Play io Games</h2>
        <p>Playing io games is a thrilling experience that requires skill, strategy, and fast reflexes. Each io game has its own rules and mechanisms. Still, the objective is generally to gain as much territory or points as possible while battling or avoiding other players in a shared online arena. This genre highlights constant evolution and competition. As you play, you will need to adapt your strategies to face new challenges, and survival often depends on your ability to respond quickly to changes in the game environment. For some games, the goal is to become the biggest and last player standing in the game. For others, it’s all about teamwork and collaboration. One thing is sure - every io game guarantees an intense multiplayer showdown with players worldwide.</p>
        <ul>
            <li>Start small and gradually build up. In most io games, you start small and have to eat, collect, or conquer to get bigger and more potent.</li>
            <li>Always be on the move. Staying stationary is rarely a good strategy in io games. Always be ready to evade or engage with other players.</li>
            <li>Master the art of timing. Knowing when to attack or retreat based on the situation is a key strategy in many io games.</li>
            <li>Use your size to your advantage. In many games, bigger players can take down smaller ones, but sometimes being smaller can make you quicker and harder to catch.</li>
            <li>And lastly, practice. The more you play, the better you’ll understand the mechanics of each game and improve your strategies.</li>
        </ul>
    </section>
`
