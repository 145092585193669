export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play, Earn, and Get Super Drops on Playgama!"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play games, tackle tasks, earn coins, and spend them on real rewards"])},
        "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up Now & Get 999 Coins"])},
        "closeButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["× Close"])}
      }
    }
  })
}
