<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M6.4 3v2.4a.4.4 0 0 0 .8 0V3zm4.4 0v3.2a.4.4 0 0 0 .8 0V3zm2.4 0v2a.4.4 0 1 0 .8 0V3zm3.2 0v6.8a.4.4 0 1 0 .8 0V3zM8.2 6.2c-.992 0-1.8.808-1.8 1.8s.808 1.8 1.8 1.8c.993 0 1.8-.808 1.8-1.8s-.807-1.8-1.8-1.8m6.167 1.034a.8.8 0 0 0-.453.12L11.56 8.81l-.001-.01-.253.169-.112.069q-.025.016-.049.036c-.73.488-1.412.992-2.027 1.528a.8.8 0 0 0-.184.161L7.444 12.6H5.426a.8.8 0 1 0 0 1.6h2.4q.038-.002.077-.008l.02-.001a1 1 0 0 0 .126-.026l.03-.01a.8.8 0 0 0 .342-.224l.03-.031.854-1.053.438 2.994.011.009a.8.8 0 0 0 .48.58l2.567 1.095v2.658q-.391.016-.8.017v-2.146l-2.081-.888a1.59 1.59 0 0 1-.915-1.02.8.8 0 0 1-.053-.189l-.186-1.266a2 2 0 0 1-.133.086l-.097.054a1.6 1.6 0 0 1-.55.157 1 1 0 0 1-.16.012h-2.4a1.6 1.6 0 0 1-.788-.216C3.727 15.415 3.2 16.174 3.2 17c0 2.243 3.865 4 8.8 4l.178-.004q.767-.006 1.492-.068h.011q.292-.027.576-.06l.215-.025q.265-.035.522-.077C18.41 20.22 20.8 18.762 20.8 17c0-.685-.364-1.323-1.005-1.881-.026.066-.048.132-.082.196a1.6 1.6 0 0 1-.953.77c-.41.122-.843.076-1.218-.129l-2.35-1.276-1.201.743.231.1c.2.082.38.206.529.366.282.28.45.683.45 1.111v2.927q-.389.069-.8.121V17a.8.8 0 0 0-.236-.566.7.7 0 0 0-.256-.175l-1.624-.693s.482-.325.552-.368l2.329-1.443 2.76 1.5a.8.8 0 1 0 .763-1.406l-3.163-1.72c-.02-.01-.04-.015-.06-.024l-.006-.003a1 1 0 0 0-.133-.044l-.028-.006a1 1 0 0 0-.112-.015q-.033-.002-.067 0-.038 0-.074.004a1 1 0 0 0-.105.02l-.035.01a1 1 0 0 0-.125.05l-.01.005q-.03.013-.057.028L12.41 13.58l-.545-3.06 2.892-1.807a.8.8 0 0 0-.39-1.48"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Io',
})
</script>
