/* eslint-disable max-len */
import { CategoriesType } from '@/types'
import { twoPlayer } from './2Player'
import { action } from './action'
import { adventure } from './adventure'
import { arcade } from './arcade'
import { balloons } from './balloons'
import { boys } from './boys'
import { cards } from './cards'
import { casino } from './casino'
import { economic } from './economic'
import { educational } from './educational'
import { girls } from './girls'
import { horrors } from './horrors'
import { hypercasual } from './hypercasual'
import { imitations } from './imitations'
import { io } from './io'
import { kids } from './kids'
import { match3 } from './match3'
import { midcore } from './midcore'
import { puzzle } from './puzzle'
import { quiz } from './quiz'
import { racing } from './racing'
import { role } from './role'
import { simulator } from './simulator'
import { sports } from './sports'
import { strategy } from './strategy'
import { tabletop } from './tabletop'
import { tests } from './tests'

const categoriesSEODescriptions: Record<CategoriesType, string> = {
    popular: '',
    all_games: '',
    recommended: '',
    trending_now: '',
    new: '',
    random: '',
    '2_player': twoPlayer,
    '3d': '',
    action,
    adventure,
    applications: '',
    arcade,
    baby: '',
    balloons,
    bejeweled: '',
    boys,
    cards,
    casino,
    clicker: '',
    cooking: '',
    economic,
    educational,
    farming: '',
    girls,
    horrors,
    hypercasual,
    imitations,
    in_game_purchase: '',
    io,
    kids,
    match3,
    midcore,
    multiplayer: '',
    puzzle,
    quiz,
    racing,
    role,
    simulator,
    shooting: '',
    sports,
    stickman: '',
    strategy,
    soccer: '',
    social: '',
    tabletop,
    tests,
    top_picks: '',
}

export default categoriesSEODescriptions
