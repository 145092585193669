<template>
    <div :class="$style.taskItem">
        <div :class="$style.taskMainRow">
            <span :class="$style.emojiBlock" :style="{ backgroundColor: task.iconBgColor }">
                {{ task.icon }}
            </span>
            <span :class="$style.middleBlock">
                <span :class="$style.taskTitle">
                    {{ getTextForTask(task) }}
                </span>
                <span :class="$style.rewardBlock">
                    <img
                        src="/img/fennec-coin.png"
                        alt="fennec coin"
                        :class="$style.rewardCoin"
                    />
                    +{{ reducePriceTag(task.reward) }}
                </span>
            </span>
            <span :class="$style.completed">
                <Button
                    v-if="task.status === TaskStatus.FULFILLED"
                    :title="t('get')"
                    size="m"
                    :class="$style.completedBtn"
                    @click="emit('openClaim')"
                />
                <span
                    v-else-if="task.status === TaskStatus.ACTIVE || task.status === TaskStatus.PARTIAL"
                    :class="$style.claimed"
                >
                    {{ partialStatus }}
                </span>
                <span
                    v-if="task.status === TaskStatus.CLAIMED || task.status === TaskStatus.CLAIMED_ARCHIVED"
                    :class="$style.claimed"
                >
                    <Icon name="checkMark" />
                </span>
            </span>

        </div>
        <UserTaskGames
            v-if="task.type === TaskType.PLAY_GAMES"
            :task="task"
            :class="$style.userTaskGames"
        />
    </div>
</template>
<script setup lang="ts">
import { Task, TaskStatus, TaskType } from '@/types'
import { reducePriceTag } from '@/utils/helpers'
import Button from '@/components/Button.vue'
import UserTaskGames from '@/components/UserCabinet/components/UserTaskGames.vue'
import Icon from '@/components/Icon/index.vue'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const props = defineProps<{task: Task}>()

// eslint-disable-next-line no-unused-vars
const emit = defineEmits<{(e: 'openClaim'): void}>()

const { t } = useI18n()

function getTextForTask(task: Task) {
    switch (task.type) {
    case TaskType.PLAY_GAME_N_MINS:
        return t('play-mins', { mins: task.settings })
    case TaskType.PLAY_GAMES:
        return t('play-games', { count: task.settings.split(',').length })
    default:
        return task.title
    }
}

const partialStatus = computed(() => {
    if (props.task.type === TaskType.PLAY_GAMES) {
        const all = props.task.settings?.split(',').length
        const completed = props.task.completed?.split(',').length || 0
        return `${completed}/${all}`
    }
    return '0/1'
})
</script>
<style module>
.taskItem {
    padding: var(--gap-regular) 0;
    border-bottom: 1px solid var(--white-25);
}

.taskMainRow {
    display: flex;
    gap: var(--gap-regular);
    align-items: center;
}

.taskItem:first-child {
    padding-top: 0;
}

.taskItem:last-child {
    border-bottom: none;
}

.userTaskGames {
    padding-left: 72px;
    padding-top: 8px;
}

.rewardCoin {
    width: 24px;
    height: 24px;
}

.completed {
    display: flex;
    justify-content: flex-end;
}

.emojiBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border-radius: var(--radius-small);
    font-size: 24px; /* не переменная, так как это и не текст */
    flex: 0 0 56px;
}

.rewardBlock {
    display: flex;
    flex-wrap: nowrap;
    gap: var(--gap-small);
    align-items: center;
    width: 75px;
    flex-shrink: 0;
    flex-grow: 0;
    color: var(--light-pastel-purple);
}

.middleBlock {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    gap: var(--gap-regular);
}

.taskTitle {
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
}

.completedBtn {
    width: 80px;
    height: 34px;
}

.claimed {
    display: flex;
    width: 80px;
    height: 40px;
    align-items: center;
    justify-content: center;
}

@media(--mobile) {
    .middleBlock {
        flex-direction: column;
        gap: var(--gap-xsmall);
    }

    .rewardBlock {
        order: 2;
    }
}
</style>
<i18n lang="json">
{
    "en": {
        "get": "Get",
        "play-mins": "Be playing any game for {mins} minutes",
        "play-games": "Play {count} games"
    }
}
</i18n>
