/* eslint-disable max-len */
export const hypercasual = `
    <section class="textRegular">
        <h2 class="headerOne">Hypercasual Games — Play Online</h2>
        <p>Simplicity is key in the realm of Hypercasual Games, a genre that takes over the internet with its addictive and easily understandable mechanics. These games, which have been ruling the mobile and online gaming market since the mid-2010s, have concise gameplay typically consisting of one to two mechanics, allowing anyone — gamers or not — to play with minimal instruction. Hypercasual games encompass a range of sub-genres, from endless runners like "Temple Run", time-killers like "2048", to even brain teasers like "Trivia Crack". Its simplicity and ease of play have led to its mass appeal, becoming a go-to for individuals seeking a quick entertainment break.</p>
    </section>
    <section class="textRegular">
        <h2 class="headerOne">How to Play Hypercasual Games</h2>
        <p>Hypercasual games are easy to navigate and can often be mastered with just a few taps or clicks. Most of them have you progressing per level, with each level increasing in difficulty in order to maintain the player's interest. Some games, like endless runners, have no distinct ending and challenge the player to achieve the best high score. Whilst others, like puzzle or teaser games, can have a definite objective or goal to reach. The goal in every hypercasual game is simple — play to the best of your ability and aim to improve on your next run.</p>
        <ul>
            <li>Understand the core mechanic: Since hypercasual games are built around one or two mechanics, understanding these are key to mastering the game.</li>
            <li>Start slow: Don't rush. Use the earlier easier rounds to get acquainted with the game mechanic.</li>
            <li>Practice: The key to improvement in any game genre; more so here. The more you play, the better you'll get at navigating the game.</li>
            <li>Focus on self-improvement: The primary competition in hypercasual games is with oneself. Aim to beat your own high score.</li>
            <li>Enjoy the process: Remember, these games are meant to be a stress-reliever, so don't work yourself up if you can't get it right initially. Relax, play, and enjoy.</li>
        </ul>
    </section>
`
