<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M19.8 7.4h-4c-1.213 0-2.2.987-2.2 2.2v5a.8.8 0 0 0 1.6 0v-4.212c0-.108.092-.2.2-.2s.2.092.2.2v9.132c0 .626.334 1.08.96 1.08.593 0 1.04-.464 1.04-1.08v-5.137a.2.2 0 0 1 .4 0v5.254l.006.006c.055.553.481.957 1.034.957.626 0 .96-.454.96-1.08v-9.062c0-.108.092-.2.2-.2s.2.092.2.2V14.6a.8.8 0 0 0 1.6 0v-5c0-1.213-.987-2.2-2.2-2.2m-7.08 2.523c-.23-.383-.67-.507-1.158-.249l-1.057.596L9.6 8.8C8.952 7.66 8.213 7.4 7 7.4H4.2C2.987 7.4 2 8.387 2 9.6v5a.8.8 0 0 0 1.6 0v-4.212c0-.108.092-.2.2-.2s.2.092.2.2v9.132c0 .626.334 1.08.96 1.08.593 0 1.04-.464 1.04-1.08v-5.137a.2.2 0 0 1 .4 0v5.254l.006.006c.055.553.481.957 1.034.957.626 0 .96-.454.96-1.08v-9.062a.2.2 0 0 1 .162-.192l.915 1.371a.967.967 0 0 0 1.286.303L12.4 11c.483-.292.474-.828.32-1.077M6.2 3.4a1.8 1.8 0 1 0 0 3.6 1.8 1.8 0 0 0 0-3.6m11.6 0a1.8 1.8 0 1 0 0 3.6 1.8 1.8 0 0 0 0-3.6"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'TwoPlayer',
})
</script>
