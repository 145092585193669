/* eslint-disable max-len */
export const imitations = `
    <section class="textRegular">
        <h2 class="headerOne">Imitations Games — Play Online</h2>
        <p>Step into the intriguing world of online imitation games! This unique category embraces games that replicate, imitate, or emulate different aspects of real-life scenarios, popular games, or indulge in a playful twist on reality. The niche genre roots its beginnings in traditional parlor games where imitation was the key to entertainment. The rise of digital technology brought this genre to video gaming, with some of the first imitations video games being the iconic 'SimCity' and 'The Sims', emulating city management and real-life situations respectively. These games opened the gate to an array of digital experiences where players could interact and influence a simulated environment or circumstance. Today, this genre has expanded to include a broad spectrum of themes and styles — from games that imitate popular game mechanics or scenarios to ones that playfully reproduce societal situations or jobs. Imitations games engage players with their knack for copying elements from the real or digital world, providing an immersive and often humorous gaming experience.</p>
    </section>
    <section class="textRegular">
        <h2 class="headerOne">How to Play Imitation Games</h2>
        <p>Playing imitation games online typically involves taking control of a character, set-up, or simulator that seeks to copy a particular scenario or game. The gameplay specifics can greatly vary depending on the type and theme of the game. Some games might require you to control a character and navigate them through different situations, imitating real-life tasks or actions. Others might have you manage a simulator or an environment, requiring strategic planning and decision-making skills. While some games are a time-based challenge, others give you an open world for exploration. Despite the differences, the goal remains the same — successfully imitate the given scenario or role for the best gaming score or result.</p>
        <ul>
            <li>Understand the game's theme and goal. Each game in this genre will have specific characters or scenario to imitate.</li>
            <li>Learn the controls. These games often have unique game mechanics that you need to familiarize yourself with for smooth gameplay.</li>
            <li>Master strategic planning. Some games may need proper strategy and decision-making skills for managing resources or situations.</li>
            <li>Remain adaptable. The versatility of this genre can surprise you with unexpected situations, remain flexible and quick-thinking.</li>
            <li>Enjoy the experience. These games often involve elements of humor, surprise, and fun. Immerse yourself in the bizarre and exciting world of imitations.</li>
        </ul>
    </section>
`
