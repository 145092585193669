/* eslint-disable max-len */
export const adventure = `
    <section class="textRegular">
        <h2 class="headerOne">Adventure Games — Play Online</h2>
        <p>Step into the shoes of a courageous hero, explore different worlds and solve intricate puzzles as you embark on exhilarating quests in our collection of Adventure Games. In the fascinating world of gaming, the adventure genre traces its roots back to the 1970s, introduced with text-based titles such as "Colossal Cave Adventure." Over time, it has evolved exponentially, encompassing various sub-genres such as point-and-click games and graphical adventures. Riding the waves of technological advancements, the genre saw a gigantic leap with the advent of HTML5. Boasting high-end graphics and immersive storytelling, our handpicked adventure games provide a whole new level of interactivity, enabling you to become part of the narrative thoroughly and profoundly influence the game outcome.</p>
    </section>
    <section class="textRegular">
        <h2 class="headerOne">How to Play Adventure Games</h2>
        <p>Playing adventure games online requires a blend of sharp intellect, keen observation, and unflinching bravery. Each game transports you to a unique world awaiting exploration and interaction. The mechanics of these games typically involve navigating through the game world, interacting with various characters and objects, solving puzzles, and occasionally, engaging in combat. The controls are often simple, relying heavily on the use of the mouse or tap controls on mobile devices. The complexity comes from the vast array of situations, requiring strategic decision-making skills to progress.</p>
        <ul>
            <li>Begin by familiarising yourself with the controls. Most adventure games require simple point-and-click or swipe actions.</li>
            <li>Pay close attention to details. Subtle clues and hints are often weaved into the game scenario, which would help you solve puzzles and progress further.</li>
            <li>Interact with every character and object in the game. Often, they hold the key to progress or provide valuable information.</li>
            <li>Follow the storyline closely. Understanding the narrative will give you insight into the challenges you may face and how to overcome them.</li>
            <li>Think creatively and outside the box. Solutions may not always be straightforward and can require lateral thinking.</li>
        </ul>
    </section>
`
