/* eslint-disable max-len */
export const kids = `
    <section class="textRegular">
        <h2 class="headerOne">Kids Games — Play Online</h2>
        <p>Kids games, belonging to a category of games specifically designed for children, have a rich history that dates back to the early days of computer technology. These games aim to engage young minds and provide them with fun, interactive experiences while also imparting knowledge and developing skills. The very first games considered as kids' games were simple, text-based adventures or learning challenges. They have since evolved, with the advancement of technology, into a dynamic medium of play, learning, and entertainment equipped with stunning visuals, engaging narratives, and interactive components. Games such as 'Pong', 'Lemonade Stand', and 'Oregon Trail' were among the pioneers in this genre that brought together education and leisure in one platform. Today, we offer a broad range of online kids games, allowing children to explore their curiosity, creativity, and critical thinking while enjoying a quality and safe playing experience.</p>
    </section>
    <section class="textRegular">
        <h2 class="headerOne">How to Play Kids Games Online</h2>
        <p>Playing online kids games is a fun and interactive experience. The game mechanics vary according to the game type and its objective. Puzzle games promote problem-solving skills; adventure games improve strategic planning; educational games focus on teaching valuable lessons on maths, science, and language, and creative games enhance artistic abilities. Most games are designed with simplicity in mind, emphasizing user-friendly interfaces perfect for young gamers. Kids games let children navigate through colorful settings, full of engaging characters and captivating storylines, by clicking, dragging, and dropping using the mouse or touch controls on a screen.</p>
        <ul>
            <li>Start off by choosing the right game suitable for the child's age and interest. Younger children may prefer more straightforward games, while older children may want more challenging ones.</li>
            <li>Follow the game's instructions or tutorial if it provides one. This will set a clear understanding of how to play the game. It's also a great way to acquaint the player with the game's controls and objectives.</li>
            <li>Ensure to display a supportive attitude when the child is playing. Kids games are made to be fun, educational, and mildly challenging, but remember, the focus is to enjoy and learn at the same time.</li>
            <li>Supervise the kids while they are playing online. This is necessary to ensure the child's online safety.</li>
            <li>Encourage the child to take regular breaks to avoid fatigue and eye-strain.</li>
        </ul>
    </section>
`
