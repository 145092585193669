<template>
    <li :class="$style.shopItem">
        <img
            :src="item.icon"
            alt="partner logo"
            :class="$style.partnerLogo"
        />
        <span :class="$style.middleBlock">
            <span :class="['subheader', $style.itemName]">{{ item.title }}</span>
            <span :class="['textRegular', $style.itemPrice]">
                <img
                    src="/img/fennec-coin.png"
                    alt="fennec coin"
                    :class="$style.fennecCoin"
                />
                {{ priceTag }}
            </span>
        </span>
        <div :class="$style.rightBlock">
            <Button
                v-if="item.status === 'AVAILABLE' || isCountdownEnded"
                :title="t('buy')"
                size="m"
                :disabled="isDisabled"
                :class="$style.btn"
                @click="buyItem"
            />
            <span
                v-else-if="message || countdownMessage"
                :class="[
                    $style.rightBlock,
                    $style.message,
                    isExpired ? $style.expired : '',
                    'accent'
                ]"
            >
                {{ message || `${t('in')} ${countdownMessage}` }}
            </span>
            <span v-if="!isExpired" :class="$style.limit">
                {{ t('accountLimit', { purchased: item.boughtCount, limit: item.maxItemsPerUser }) }}
            </span>
        </div>
    </li>
</template>

<script lang="ts" setup>
import {
    defineProps,
    defineEmits,
    computed,
    ref,
    onMounted,
} from 'vue'
import { useI18n } from 'vue-i18n'
import type { ShopItem } from '@/types'
import Button from '@/components/Button.vue'
import { reducePriceTag } from '@/utils/helpers'

type ShopItemProps = {
    item: ShopItem,
    isDisabled: boolean,
}

const props = defineProps<ShopItemProps>()

const { t } = useI18n()
/* eslint-disable no-unused-vars */
const emit = defineEmits<{(e: 'buyItem', item: ShopItem): void}>()

const priceTag = computed(() => reducePriceTag(props.item.price))
const isExpired = computed(() => props.item.status === 'ENDED')
const startDate = computed(() => new Date(props.item.startDate))
const countdownMessage = ref('')
const isCountdownEnded = ref(false)

function createCountdownMessage() {
    const currentDate = new Date()
    const diff = startDate.value.getTime() - currentDate.getTime()
    let hours: string | number = Math.floor(diff / (1000 * 60 * 60))
    let minutes: string | number = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
    let seconds: string | number = Math.floor((diff % (1000 * 60)) / 1000)

    if (hours <= 0 && minutes <= 0 && seconds <= 0) {
        isCountdownEnded.value = true
    } else {
        hours = hours < 10 ? `0${hours}` : hours
        minutes = minutes < 10 ? `0${minutes}` : minutes
        seconds = seconds < 10 ? `0${seconds}` : seconds

        countdownMessage.value = `${hours}:${minutes}:${seconds}`
    }
}

function setCountdown() {
    if (!startDate.value) {
        return
    }
    setInterval(() => {
        createCountdownMessage()
    }, 1000)
}

const message = computed(() => {
    if (isExpired.value) {
        return t('ended')
    }
    return ''
})

function buyItem() {
    emit('buyItem', props.item)
}

onMounted(() => {
    if (props.item.status === 'SOON') {
        setCountdown()
    }
})
</script>

<style module>
.shopItem {
    display: flex;
    gap: var(--gap-regular);
    width: 100%;
    box-sizing: border-box;
    padding: var(--gap-medium) var(--gap-regular);
    height: 80px;
    align-items: center;
    background: linear-gradient(0deg, rgba(3, 0, 20, 0.4) 0%, rgba(50, 41, 95, 0.4) 100%);
    border: 1px solid #43377C;
    border-radius: var(--radius-regular);
}

.middleBlock {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    gap: var(--gap-regular);
}

.partnerLogo {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    flex-grow: 0;
}

.itemName {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
}

.itemPrice {
    display: flex;
    gap: var(--gap-small);
    flex-shrink: 0;
    flex-grow: 0;
    align-items: center;
    width: 72px;
    color: var(--light-pastel-purple);
}

.btn {
    width: 100%;
    height: 34px;
}

.fennecCoin {
    width: 24px;
    height: 24px;
}

.rightBlock {
    display: flex;
    flex-direction: column;
    gap: var(--gap-small);
    width: 80px;
    text-align: center;
    flex-shrink: 0;
    flex-grow: 0;
}

.message.expired {
    color: var(--button);
}

.limit {
    font-size: 9px;
    line-height: 10px;
    color: var(--white-50);
    text-transform: uppercase;
}

@media (--mobile) {
    .shopItem {
        height: 84px;
    }

    .middleBlock {
        flex-direction: column;
        gap: var(--gap-xsmall);
    }
}
</style>

<i18n lang="json">
{
    "en": {
        "buy": "Buy",
        "ended": "Drop ended",
        "in": "In",
        "accountLimit": "Account limit {purchased}/{limit}"
    }
}
</i18n>
