<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M18.339 8.883c-1.845-.419-.834-6.483-6.342-6.483-5.655 0-4.39 5.768-6.41 6.482C3.642 9.572 2.4 11.39 2.4 13.6c4.734 0 2.115 3.723 3.741 4.265 1.666.555 2.375.68 2.998 1.998.45.955 1.11 1.763 2.924 1.74 2.924-.038 2.519-2.443 3.997-2.887 3.812-1.147.274-4.316 5.54-4.316 0-2.209.01-4.774-3.261-5.517M9.6 8.8c-.441 0-.8-.538-.8-1.2s.359-1.2.8-1.2.8.538.8 1.2-.358 1.2-.8 1.2m3.2 0c-.441 0-.8-.538-.8-1.2s.359-1.2.8-1.2.8.538.8 1.2-.358 1.2-.8 1.2"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Horrors',
})
</script>
