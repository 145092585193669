<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M16 1.6a.69.69 0 0 0-.61.356l-1.094 1.932-2.138-.594a.702.702 0 0 0-.864.864l.594 2.137L9.957 7.39a.701.701 0 0 0 0 1.22l1.93 1.094.005.005 2.811-2.813a1.698 1.698 0 0 1 2.4 2.4l-2.81 2.813.004.005 1.092 1.93a.702.702 0 0 0 1.222 0l1.092-1.93 2.14.592a.7.7 0 0 0 .862-.863l-.592-2.139 1.931-1.092a.702.702 0 0 0 0-1.222l-1.931-1.094.594-2.137a.702.702 0 0 0-.864-.864l-2.138.594-1.094-1.932a.7.7 0 0 0-.61-.356m-4.11 8.11-8.993 8.993a1.698 1.698 0 0 0 2.4 2.4l8.994-8.994zm3.298 6.28a.8.8 0 0 0-.788.81v.8h-.8a.8.8 0 1 0 0 1.6h.8v.8a.8.8 0 1 0 1.6 0v-.8h.8a.801.801 0 1 0 0-1.6H16v-.8a.8.8 0 0 0-.812-.81m5.6 2.4a.8.8 0 0 0-.788.81v.8h-.8a.8.8 0 1 0 0 1.6h.8v.8a.8.8 0 1 0 1.6 0v-.8h.8a.801.801 0 1 0 0-1.6h-.8v-.8a.8.8 0 0 0-.812-.81"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Role',
})
</script>
