<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M12 2.4c-5.292 0-9.6 4.308-9.6 9.6s4.308 9.6 9.6 9.6c5.293 0 9.6-4.308 9.6-9.6S17.293 2.4 12 2.4m-1.504 1.745.16 1.599c-.77.165-1.487.47-2.126.884L7.507 5.377a7.95 7.95 0 0 1 2.989-1.232m3.01 0a7.95 7.95 0 0 1 2.988 1.232l-1.023 1.251a6.4 6.4 0 0 0-2.125-.884zm-8.13 3.361L6.63 8.53a6.4 6.4 0 0 0-.885 2.125l-1.598-.16a7.95 7.95 0 0 1 1.231-2.989m13.248 0a7.95 7.95 0 0 1 1.231 2.99l-1.598.159a6.4 6.4 0 0 0-.885-2.125zM12 8a4 4 0 1 1 0 8 4 4 0 0 1 0-8m-6.256 5.345c.165.77.47 1.486.885 2.125l-1.252 1.024a7.95 7.95 0 0 1-1.231-2.99zm12.513 0 1.598.16a7.95 7.95 0 0 1-1.231 2.989l-1.252-1.024a6.4 6.4 0 0 0 .885-2.125M8.53 17.372a6.4 6.4 0 0 0 2.125.884l-.16 1.599a7.95 7.95 0 0 1-2.988-1.232zm6.94 0 1.024 1.252a7.95 7.95 0 0 1-2.989 1.23l-.16-1.598a6.4 6.4 0 0 0 2.126-.884"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Casino',
})
</script>
