<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M11.2 2.4A1.6 1.6 0 0 0 9.6 4H3.2a1.6 1.6 0 0 0-1.6 1.6v7.2a1.6 1.6 0 0 0 1.6 1.6h17.6a1.6 1.6 0 0 0 1.6-1.6V5.6A1.6 1.6 0 0 0 20.8 4h-6.4a1.6 1.6 0 0 0-1.6-1.6zm.8 8.8a.8.8 0 1 1-.001 1.6.8.8 0 0 1 0-1.6M1.6 15.555V18.4A1.6 1.6 0 0 0 3.2 20h17.6a1.6 1.6 0 0 0 1.6-1.6v-2.845a3.17 3.17 0 0 1-1.6.445H3.2c-.586 0-1.128-.17-1.6-.445"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Economic',
})
</script>
