<template>
    <component :is="iconsList[props.name]" />
</template>

<script setup lang="ts">
import { defineProps } from 'vue'
import { iconsList, IconNameType } from './icons-list'

export interface IconProps {
    name: IconNameType
}

const props = defineProps<IconProps>()

</script>
