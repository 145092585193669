<template>
    <GameFrame
        v-if="game"
        :title="game.title"
        :game-src="gameSrc"
    />
</template>
<script lang="ts" setup>
import {
    onBeforeMount, onBeforeUnmount, onServerPrefetch, ref,
} from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useHead } from '@unhead/vue'
import { PageStatus, useAppStore } from '@/store/app-store'
import { MainFrameService } from '@/modules/main-frame'
import { useAdsStore } from '@/store/ads-store'
import { sendEvent } from '@/utils/analytics'
import GameFrame from './components/Frame.vue'
import { generateHead } from './units'
import { useGamePageStore } from './game-store'

const CLID_PARAM_NAME = 'clid'
const PAYLOAD_PARAM_NAME = 'payload'

const gamePageStore = useGamePageStore()
const appStore = useAppStore()
const { game } = storeToRefs(gamePageStore)

useHead(generateHead({ game }))

const route = useRoute()
const initialized = ref(false)
const gameSrc = ref('')

const clid = route.query[CLID_PARAM_NAME] as string || undefined

const mainFrameService = ref<MainFrameService>()
const adsStore = useAdsStore()

onBeforeMount(() => {
    if (!game.value) {
        return
    }

    if (!adsStore.adMediator) {
        console.error('Error: No ad service was passed to the main frame')
        return
    }

    const payload = route.query[PAYLOAD_PARAM_NAME] || ''

    const url = new URL(game.value.game_url)

    if (clid) {
        url.searchParams.set('clid', clid as string)
    }
    if (payload) {
        url.searchParams.set('payload', payload as string)
    }

    sendEvent({
        eventName: 'game_opened',
        userId: undefined,
        clid: appStore.clid,
        pageName: route.name as string || undefined,
        gameHru: game.value?.hru,
    })

    url.searchParams.set('utm_source', 'parent_window_api')
    url.searchParams.set('flags', '{"adv_types_disabled":16}')

    gameSrc.value = url.href

    initialized.value = true

    mainFrameService.value = new MainFrameService({
        clid,
        adMediator: adsStore.adMediator,
        logger: window.dataLayer,
    })
})

onBeforeUnmount(() => {
    mainFrameService.value?.destroy()
})

onServerPrefetch(async () => {
    try {
        const serverGame = await gamePageStore.setGame(route.params.game as string)
        if (serverGame.id === route.params.game && route.params.game !== serverGame.hru) {
            const search = route.fullPath.replace(/^[^?]+/i, '')
            appStore.setPageStatus(301)
            appStore.setTargetURL(`/export/game/${serverGame.hru}${search}`)
        }
    } catch (e) {
        if (typeof e === 'number') {
            appStore.setPageStatus(e as PageStatus)
        } else {
            appStore.setPageStatus(503)
        }
    }
})

</script>
