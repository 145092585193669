/* eslint-disable max-len */
export const arcade = `
    <section class="textRegular">
        <h2 class="headerOne">Arcade Games — Play Online</h2>
        <p>Immerse yourself in a world of simple, addictive gameplay and rapid action with our vast selection of arcade games. Taking their roots from the coin-operated classics of the 1970s and 80s, arcade games have stood the test of time and continue to be a staple in the industry. These games often have a primary focus on performing specific tasks or reaching a top score, challenging both your reflexes and strategic thinking. Pioneered by iconic names like 'Asteroids', 'Space Invaders', and 'Pac-Man', this genre keeps players engaged with its vibrant graphics, fast-paced action, and thrilling gameplay elements.</p>
    </section>
    <section class="textRegular">
        <h2 class="headerOne">How to Play Arcade Games</h2>
        <p>While the objective of arcade games can vary dramatically, the overarching theme usually revolves around quick decision-making and precise execution. Whether it's managing a snake's growth in a confined space or catching falling objects in a basket, mastering the gameplay mechanics is key. Some games will test your reflexes and coordination, while others rely more on puzzle-solving skills or strategic planning. The controls are usually straightforward, allowing you to quickly jump in and experience the game without a lengthy tutorial.</p>
        <ul>
            <li>Stay alert: Arcade games require quick reflexes, so staying focused is key. Try to minimize distractions around you.</li>
            <li>Learn from failure: Don't get discouraged if you don't succeed straight away. The best way to improve is through repeated play and learning from mistakes.</li>
            <li>Test different strategies: If you're struggling, mix up your tactics. Sometimes a different approach can make all the difference in achieving a high score.</li>
            <li>Take Regular Breaks: These games can be intense; remember to take regular breaks to avoid fatigue and maintain high performance.</li>
            <li>Enjoy the process: Remember, arcade games aim to provide fun and entertainment. Enjoy each game without focusing solely on the results.</li>
        </ul>
    </section>
`
