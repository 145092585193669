export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prev"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
      }
    }
  })
}
