<template>
    <Layout itemscope itemtype="https://schema.org/VideoGame">
        <template #default>
            <main :class="styles.game">
                <GameHeader
                    @openReportModal="openReportModal"
                />
                <GameDetails
                    v-if="!isGameStarted"
                    :game="game"
                    @startPlayingGame="startGame"
                />
                <div v-else-if="game" :class="[styles.gameContent, styles[game.orientation]]">
                    <RotateMessage
                        v-if="shouldRotate"
                        :orientation="game.orientation"
                    />
                    <GameFrame
                        :class="styles.iframe"
                        :title="game.title"
                        :game-src="gameSrc"
                    />
                </div>
                <div v-if="recommendedGamesList" :class="styles.recommendations">
                    <GameCardsList
                        v-for="category in recommendedGamesList"
                        :key="category.href"
                        appearance="tiny-rows"
                        :title="t(category.name)"
                        :icon="category.icon"
                        :link="category.href"
                        :class="styles.category"
                        :games="category.games as SimplifiedGame[]"
                        isMore
                    />
                </div>
                <div
                    v-if="game?.seoText"
                    v-html="game.seoText"
                    :class="$style.seoText"
                />
                <ReportProblemModal
                    v-if="isReportModalOpen"
                    :isOpen="isReportModalOpen"
                    :page="route.fullPath"
                    @closeReportModal="closeReportModal"
                />
                <GameStickyBanner v-if="isGameStarted" />
            </main>
        </template>
        <template #rightSide>
            <RightSide :recentlyGames="recommendedGames" />
        </template>
    </Layout>
</template>
<script lang="ts" setup>
import {
    onServerPrefetch,
    onMounted,
    onBeforeUnmount,
    ref,
    computed,
    useCssModule,
} from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, onBeforeRouteUpdate, onBeforeRouteLeave } from 'vue-router'
import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import { GameType, SimplifiedGame } from '@/types'
import { categoriesTranslation } from '@/utils/translations/categories'
import { type PageStatus, useAppStore } from '@/store/app-store'
import { useAdsStore } from '@/store/ads-store'
import { useCategoriesStore } from '@/store/categories-store'
import { MainFrameService } from '@/modules/main-frame'
import Layout from '@/layouts/TwoColumnsLayout.vue'
import RightSide from '@/components/RightSide.vue'
import GameCardsList from '@/components/GameCardsList.vue'
import { sendEvent } from '@/utils/analytics'
import { useUserStore } from '@/store/user-store'
import GameStickyBanner from './components/GameStickyBanner.vue'
import GameHeader from './components/GameHeader.vue'
import ReportProblemModal from './components/ReportProblemModal.vue'
import GameDetails from './components/GameDetails.vue'
import GameFrame from './components/Frame.vue'
import RotateMessage from './components/RotateMessage.vue'
import { useGamePageStore } from './game-store'
import { generateHead } from './units'

const mainFrameService = ref<MainFrameService>()

const categoriesStore = useCategoriesStore()
const gamePageStore = useGamePageStore()
const appStore = useAppStore()
const adsStore = useAdsStore()
const userStore = useUserStore()
const route = useRoute()
const styles = useCssModule()

const { t } = useI18n({
    messages: {
        ...categoriesTranslation,
    },
})

const {
    contentCategoriesList,
    recommendedGames,
} = storeToRefs(categoriesStore)
const { game } = storeToRefs(gamePageStore)

const isGameStarted = ref(false)
const isReportModalOpen = ref(false)
const screenOrientation = ref('')

const gameSrc = computed(() => {
    if (!game.value) {
        return ''
    }
    const url = new URL(game.value.game_url)
    url.searchParams.set('utm_source', 'parent_window_api')
    url.searchParams.set('flags', '{"adv_types_disabled":16}')

    return url.href
})

const shouldRotate = computed(() => {
    if (
        appStore.platform === 'desktop'
        || (game.value && screenOrientation.value.includes(game.value.orientation))
    ) {
        return false
    }
    return true
})
const recommendedGamesList = computed(() => categoriesStore.getRandomCategories(2))

const pageMeta = computed(() => generateHead({ game }))

useHead(pageMeta)

function openReportModal() {
    isReportModalOpen.value = true
}

function closeReportModal() {
    isReportModalOpen.value = false
}

function startGame() {
    isGameStarted.value = true
    categoriesStore.updateViewedGame(game.value!.hru, game!.value as GameType)
    sendEvent({
        eventName: 'game_opened',
        userId: userStore.user ? userStore.user._id : undefined,
        clid: appStore.clid,
        pageName: route.name as string || undefined,
        gameHru: game.value?.hru,
    })
}

async function updateData(routeParams: string) {
    // Prevents the request from being made twice if the data is already in the store
    if (!game || !game.value || game.value.hru !== routeParams) {
        gamePageStore.resetGameData()
        isGameStarted.value = false
        await gamePageStore.setGame(routeParams)
    }
}

onServerPrefetch(async () => {
    await categoriesStore.setMainData()
    try {
        const serverGame = await gamePageStore.setGame(route.params.game as string)
        if (serverGame.id === route.params.game && route.params.game !== serverGame.hru) {
            const search = route.fullPath.replace(/^[^?]+/i, '')
            appStore.setPageStatus(301)
            appStore.setTargetURL(`/game/${serverGame.hru}${search}`)
        }
    } catch (e) {
        if (typeof e === 'number') {
            appStore.setPageStatus(e as PageStatus)
        } else {
            appStore.setPageStatus(503)
        }
    }
})

onMounted(async () => {
    if (!adsStore.adMediator) {
        console.error('Error: No ad service was passed to the main frame')
        return
    }
    await updateData(route.params.game as string)
    mainFrameService.value = new MainFrameService({
        logger: window.dataLayer,
        adMediator: adsStore.adMediator,
    })
    if (appStore.platform !== 'desktop') {
        window.screen.orientation.addEventListener('change', () => {
            screenOrientation.value = window.screen.orientation.type
        })
        screenOrientation.value = window.screen.orientation.type
    }
    if (contentCategoriesList.value.length) {
        return
    }
    await categoriesStore.setMainData()
})

onBeforeRouteUpdate(async (to, from, next) => {
    await updateData(to.params.game as string)
    next()
})

onBeforeRouteLeave((to, from, next) => {
    gamePageStore.resetGameData()
    next()
})

onBeforeUnmount(() => {
    mainFrameService.value?.destroy()
    isGameStarted.value = false
})
</script>
<style module>
.game {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.gameContent {
    position: relative;
    max-width: 100%;
    max-height: 80vh;
}

.gameContent.landscape {
    aspect-ratio: 4 / 3;
}

.gameContent.portrait {
    aspect-ratio: 2 / 3;
}

.recommendations {
    display: flex;
    flex-direction: column;
    gap: var(--gap-large);
    align-self: center;
    width: 80%;
    margin-top: var(--gap-large);
}

.iframe {
    object-fit: contain;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: var(--radius-regular);
}

.adBlock {
    margin-top: var(--gap-regular);
    height: 90px;
    max-width: 100%;
}

.seoText {
    max-width: 640px;
    color: var(--white-50);
    padding: var(--gap-large);
    border-radius: var(--radius-large);
    margin-top: var(--gap-xlarge);
    background-color: var(--black-russian);
    font-family: var(--font-secondary);
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-regular);
}

.seoText h2 {
    margin-top: var(--gap-xlarge);
    padding-top: var(--gap-xlarge);
    margin-bottom: var(--gap-regular);
    color: var(--white);
    font-family: var(--font-primary);
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-big);
    border-top: 1px solid var(--white-25);
}

.seoText h2:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
}

.seoText p {
    margin-top: var(--gap-regular);
}

.seoText p:first-child {
    margin-top: 0;
}

.seoText ul {
    margin-top: var(--gap-regular);
    padding-left: var(--gap-regular);
}

.seoText li {
    list-style: disc;
    margin-top: var(--gap-small);
}

.seoText li:first-child {
    margin-top: 0;
}

@media (--desktop-small) {
    .game {
        padding: var(--gap-small);
    }
}

@media (--mobile) {
    .game {
        padding: 0;
    }
    .gameContent {
        max-width: 100%;
        max-height: 100vh;
    }
    .category {
        width: 100%;
    }
    .recommendations {
        width: 326px;
        box-sizing: border-box;
        max-width: 100%;
    }
}
</style>
