<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none"><path fill="#fff" d="m1.331.2 3.2 3.2h12.8L14.366.434A.8.8 0 0 0 13.8.2zM.201 1.331V13.8c0 .212.083.416.234.566L3.4 17.33V4.53zM5 5v12.8h7.2v-6.4c0-1.323 1.076-2.4 2.4-2.4.524 0 1.034.174 1.454.497l.015.01 1.73 1.237V5zm9.612 5.59a.8.8 0 0 0-.813.886V18.6a.8.8 0 0 0 .8.8c.23 0 .434-.1.58-.256l.005.001 1.504-1.778 1.596 3.19a.8.8 0 0 0 1.073.359.8.8 0 0 0 .358-1.074l-1.628-3.254 2.332-.42.002-.013A.79.79 0 0 0 21 15.4a.79.79 0 0 0-.37-.656l.003-.016-5.472-3.903a.8.8 0 0 0-.548-.234"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ThreeD',
})
</script>
