<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M13.502 2.007c-2.141.292-3.331 1.578-3.74 2.11A.8.8 0 0 0 9.6 4.6v1.347c-.874 0-1.6.725-1.6 1.6v5.6a.8.8 0 0 0-.8.8v6.4a1.6 1.6 0 0 0 1.6 1.6h6.4a1.6 1.6 0 0 0 1.6-1.6v-6.4a.8.8 0 0 0-.8-.8v-5.6c0-.875-.725-1.6-1.6-1.6V2.806a.802.802 0 0 0-.898-.799M9.6 7.547h4.8v5.6H9.6z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Girls',
})
</script>
