<template>
    <Layout>
        <template #leftSide>
            <LeftSide
                :highlightCategories="menuHighlightCategoriesList"
                :categories="menuCategoriesList"
            />
        </template>
        <template #default>
            <section v-if="category">
                <template v-if="!category.img">
                    <h1> {{ title }}</h1>
                    <p :class="$style.description">
                        {{ category.description }}
                    </p>
                </template>
                <div v-else :class="[$style.descWithImage, isImgLoading ? $style.hide : '']">
                    <img
                        :src="category.img"
                        :alt="`fennec for ${category.name}`"
                        :class="$style.categoryImage"
                        @load="onImageLoad"
                        @error="onImageLoad"
                    >
                    <span>
                        <h1> {{ title }}</h1>
                        <p :class="$style.description">
                            {{ category.description }}
                        </p>
                    </span>
                </div>
                <LoginBanner :class="$style.loginBanner"/>
                <ul :class="[$style.categoryRow, $style.gamesList]">
                    <li :class="$style.gameItem" v-for="game in categoryGames" :key="game.hru">
                        <GameCard
                            :game="game"
                            appearance="medium"
                        />
                    </li>
                </ul>
                <Pagination
                    :pages="category.totalPages"
                    :activePage="activePage"
                    :maxIndexButtons="5"
                />
                <div
                    v-if="category.id && seoDescription[category.id]"
                    v-html="seoDescription[category.id]"
                    :class="$style.seoText"
                />
            </section>
        </template>
        <template #rightSide>
            <h2 class="adHeader" :class="$style.adHeader">{{t('ads')}}</h2>
            <AdBlock
                :class="$style.adBlock"
                type="sidebar_desktop"
                :sizes="bannerSizes"
                :refresh="REFRESH_TIMEOUT"
            />
        </template>
    </Layout>
</template>
<script setup lang="ts">
import {
    onServerPrefetch,
    onMounted,
    computed,
    watch,
    ref,
} from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useCategoriesStore } from '@/store/categories-store'
import seoDescription from '@/utils/translations/categories-seo-desc'
import Layout from '@/layouts/ThreeColumnsLayout.vue'
import LeftSide from '@/components/LeftSide.vue'
import GameCard from '@/components/GameCard.vue'
import LoginBanner from '@/components/LoginBanner/LoginBanner.vue'

import { PageStatus, useAppStore } from '@/store/app-store'
import Pagination from '@/components/Pagination.vue'
import AdBlock from '@/components/AdBlock.vue'
import { PageAdSize } from '@/modules/adv'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import { useCategoryPageStore } from './category-page-store'

const categoriesStore = useCategoriesStore()
const categoryPageStore = useCategoryPageStore()
const appStore = useAppStore()

const { t } = useI18n()

const REFRESH_TIMEOUT = 2 * 60 // in seconds
const WINDOW_HEIGHT_FOR_600_BANNERS = 900
const bannerSizes = ref<PageAdSize[]>(['fluid', [300, 250]])

const {
    contentCategoriesList,
    menuCategoriesList,
    menuHighlightCategoriesList,
} = storeToRefs(categoriesStore)

const { categoryGames, category } = storeToRefs(categoryPageStore)

const $route = useRoute()

const activePage = computed(() => parseInt($route.query.page as string, 10) || 1)
const isImgLoading = ref(false)

function routeChanged() {
    categoryPageStore.setCategory({
        categoryName: $route.params.category as string,
        page: parseInt($route.query.page as string, 10) || 1,
    })
}
watch(() => category.value.img, () => {
    isImgLoading.value = true
})

function getMetaURL(id: string, page: string|null) {
    return `${process.env.VUE_APP_HOST_URL}/category/${id}${page ? `?page=${page}` : ''}`
}

const pageStr = computed(() => {
    if (activePage.value > 1) {
        return ` | ${t('page', { page: activePage.value })}`
    }
    return ''
})
const title = computed(() => {
    if (!category.value || !category.value.name) {
        return ''
    }
    return `${category.value.name}${pageStr.value}`
})
const pageMeta = computed(() => {
    if (!category.value || !category.value.id) {
        return {}
    }

    return {
        title: () => `${category.value.name} Online for Free 🎮 Play now | Playgama${pageStr.value}`,
        meta: [
            {
                name: 'description',
                // eslint-disable-next-line max-len
                content: `Dive into the best ${category.value.name} on Playgama! Enjoy free, high-quality browser games without downloads or registrations. ✨ Play now on desktop, tablet, and mobile. 🕹️${pageStr.value}`,
            },
        ],
        link: [
            {
                rel: 'canonical',
                href: () => getMetaURL(category.value.id!, $route.query.page as string|null),
            },
        ],
    }
})

useHead(pageMeta)

watch(
    () => $route.params.category,
    routeChanged,
)

watch(
    () => $route.query.page,
    routeChanged,
)

onServerPrefetch(async () => {
    await Promise.all([
        categoryPageStore.setCategory({
            categoryName: $route.params.category as string,
            page: parseInt($route.query.page as string, 10) || 1,
        }).catch((e) => {
            if (typeof e === 'number') {
                appStore.setPageStatus(e as PageStatus)
            } else {
                appStore.setPageStatus(503)
            }
        }),
        categoriesStore.setMainData(),
    ])
})

onMounted(async () => {
    if (!categoryGames || !categoryGames.value.length || category.value.id !== $route.params.category) {
        categoryPageStore.setCategory({
            categoryName: $route.params.category as string,
        })
    }

    const height = window.innerHeight
    if (height > WINDOW_HEIGHT_FOR_600_BANNERS) {
        bannerSizes.value.push([160, 600], [120, 600], [300, 600])
    }

    // Prevents the request from being made twice if the data is already in the store
    if (contentCategoriesList.value.length) {
        return
    }
    await categoriesStore.setMainData()
})

function onImageLoad() {
    isImgLoading.value = false
}
</script>

<style module>
.categoryRow {
    margin-bottom: var(--gap-small);
}
.description {
    composes: textRegular from global;
    margin-top: var(--gap-regular);
    max-width: 640px;
    width: 100%;
}

.descWithImage {
    display: flex;
    gap: var(--gap-regular);
    max-width: 640px;
}

.categoryImage {
    width: 144px;
    height: 144px;
}

.adHeader {
    margin-top: var(--gap-large);
}

.adBlock {
    margin-top: var(--gap-regular);
    margin-left: auto;
    margin-right: auto;
    width: 300px;
}

.gamesList{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap-regular);
    margin-top: var(--gap-xlarge);
    margin-bottom: var(--gap-xlarge);
    box-sizing: border-box;
}

.gameItem {
    composes: headerTwo from global;
    width: 216px;
    height: 128px;
}

.hide {
    visibility: hidden;
}

.seoText {
    max-width: 640px;
    color: var(--white-50);
}

.seoText section {
    padding: var(--gap-large);
    border-radius: var(--radius-large);
    margin-top: var(--gap-xlarge);
    background-color: var(--black-russian);
}

.seoText h2 {
    margin-bottom: var(--gap-regular);
    color: var(--white);
}

.seoText p {
    margin-top: var(--gap-regular);
}

.seoText p:first-child {
    margin-top: 0;
}

.seoText ul {
    margin-top: var(--gap-regular);
    padding-left: var(--gap-regular);
}

.seoText li {
    list-style: disc;
    margin-top: var(--gap-small);
}

.seoText li:first-child {
    margin-top: 0;
}

@media (--desktop-small) {
    .description, .descWithImage, .seoText {
        max-width: 520px;
    }
    .seoText section {
        margin-top: var(--gap-large);
    }
}

@media (--tablet) {
    .description, .descWithImage, .seoText {
        max-width: none;
    }
}

@media (--mobile) {
    .gameItem {
        width: 128px;
        height: 72px;
    }

    .descWithImage {
        flex-direction: row-reverse;
    }

    .categoryImage {
        width: 96px;
        height: 96px;
    }
    /* //TODO: fix styles for mobile version */
    .loginBanner {
       display: none;
    }
}
</style>
<i18n lang="json">
{
    "en": {
        "page": "page {page}",
        "ads": "Ads"
    }
}
</i18n>
