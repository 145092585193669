<template>
    <Layout>
        <template #leftSide>
            <LeftSide
                :highlightCategories="menuHighlightCategoriesList"
                :categories="menuCategoriesList"
            />
        </template>
        <template #default>
            <ul>
                <ContentItem
                    v-for="(category, index) in contentCategoriesList"
                    :key="category.name"
                    :category="category"
                    :cardAppearance="getCardsAppearance(index)"
                    :isLoginBanner="index === 1"
                    :isAd="index === 2 || index === 10"
                    :isAbout="Math.ceil(contentCategoriesList.length / 2) === index"
                    :isEnd="contentCategoriesList.length - 1 === index"
                />
            </ul>
        </template>
        <template #rightSide>
            <RightSide :recentlyGames="recommendedGames" />
        </template>
    </Layout>
</template>
<script setup lang="ts">
import {
    onServerPrefetch,
    onMounted,
} from 'vue'
import { storeToRefs } from 'pinia'
import { useHead } from '@unhead/vue'
import { useCategoriesStore } from '@/store/categories-store'
import Layout from '@/layouts/ThreeColumnsLayout.vue'
import LeftSide from '@/components/LeftSide.vue'
import RightSide from '@/components/RightSide.vue'
import ContentItem from './ContentItem.vue'

useHead({
    link: [
        {
            rel: 'canonical',
            href: process.env.VUE_APP_HOST_URL,
        },
    ],
})
const categoriesStore = useCategoriesStore()

const {
    contentCategoriesList,
    menuCategoriesList,
    menuHighlightCategoriesList,
    recommendedGames,
} = storeToRefs(categoriesStore)

function getCardsAppearance(index: number) {
    if (index === 0) {
        return 'big'
    }
    if (index === 6) {
        return 'highlighted'
    }
    if (index % 2 === 0) {
        return 'small'
    }
    return 'medium'
}

onServerPrefetch(async () => {
    await categoriesStore.setMainData()
})

onMounted(async () => {
    // Prevents the request from being made twice if the data is already in the store
    if (contentCategoriesList.value.length) {
        return
    }
    await categoriesStore.setMainData()
})
</script>
