/* eslint-disable max-len */
export const racing = `
    <section class="textRegular">
        <h2 class="headerOne">Racing Games — Play Online</h2>
        <p>Dive into the exhilarating world of online racing games! This genre dates back to the early days of video games, with classics such as Atari's Gran Trak 10 paving the way in 1974. Over the years, racing games have evolved to feature a high level of realism and intricate details, transporting players to a world of breakneck speed, precise control, and thrilling competition. From epic track races to breathtaking off-road adventures, high-speed boat races to futuristic spaceship contests, we host a diverse range of racing games designed to satisfy every speed enthusiast's cravings. Engage in thrilling multiplayer battles, or immerse yourself in a captivating single-player career mode. The games also widely vary in style, from realistic simulations, like Forza Motorsport and Gran Turismo, to more arcade-friendly experiences, like Mario Kart. With state-of-the-art graphics and immersive sound effects, these games make every race feel like a heart-stopping, adrenaline-pumping journey.</p>
    </section>
    <section class="textRegular">
        <h2 class="headerOne">How to Play Racing Games</h2>
        <p>In most racing games, the primary objective is to reach the finish line before the other competitors. The controls are typically straightforward, with arrows or WASD keys used for steering, acceleration, and braking. However, mastering these games requires a good understanding of various racing strategies, such as drifting, drafting, and cornering. Moreover, some games add complexity with fuel management, vehicle upgrades, and the handling of different road scenarios. Games like Burnout Paradise revolve around open-world exploration and stunts, while others, like F1 series, focus on real-world racing physics and strategy. So, strap in and get ready to leave your opponents in the dust!</p>
        <ul>
            <li>Racing lines are crucial. These are the ideal paths you should follow on a race track to minimize the total time taken on the race. Learn these paths to gain an advantage.</li>
            <li>Master the art of drifting. This is a technique that can make a significant difference in your lap time. Practice this skill to improve your turns dramatically.</li>
            <li>Get to know your car. Different vehicles have unique handling characteristics. Learn how your chosen vehicle performs best.</li>
            <li>Pace yourself. While it's a race, going full speed all the time isn't usually the best approach. Learn when to brake, when to speed, and when to coast.</li>
        </ul>
    </section>
`
