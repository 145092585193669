import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardTitle = _resolveComponent("DashboardTitle")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    (_ctx.$slots.title)
      ? (_openBlock(), _createBlock(_component_DashboardTitle, { key: 0 }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "title")
          ]),
          _: 3
        }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}