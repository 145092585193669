<template>
    <div :class="styles.gameHeader">
        <RouterLink to="/"><Icon name="logo" /></RouterLink>
        <div :class="styles.buttonBlock">
            <IconButton
                :class="styles.reportBtn"
                iconName="exclamationMark"
                :title="t('problem')"
                @click="emit('openReportModal')"
            />
            <IconButton
                :class="styles.fullscreenBtn"
                :iconName="isFullscreen ? 'exitFullscreen' : 'fullscreen'"
                :title="t('fullscreen')"
                @click="handleFullscreen"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    useCssModule,
    ref,
    onMounted,
    onBeforeUnmount,
    defineEmits,
} from 'vue'
import { useI18n } from 'vue-i18n'
import Icon from '@/components/Icon/index.vue'
import IconButton from '@/components/IconButton.vue'

/* eslint-disable no-unused-vars */
/* eslint-disable no-spaced-func */
/* eslint-disable func-call-spacing */
const emit = defineEmits<{
    (e: 'openReportModal'): void
}>()

const styles = useCssModule()
const isFullscreen = ref(false)
const { t } = useI18n()

const elem = ref<HTMLElement | null>(null)
function toggleFullscreenState() {
    isFullscreen.value = !isFullscreen.value
}

onMounted(() => {
    elem.value = document.documentElement
    elem.value.addEventListener('fullscreenchange', toggleFullscreenState)
})

onBeforeUnmount(() => {
    elem.value!.removeEventListener('fullscreenchange', toggleFullscreenState)
})

async function handleFullscreen() {
    try {
        if (!isFullscreen.value) {
            await elem.value!.requestFullscreen()
        } else {
            document.exitFullscreen()
        }
    } catch (err) {
        console.error(err)
    }
}
</script>

<style module>
.gameHeader {
    display: flex;
    align-items: center;
    margin-bottom: var(--gap-large);
}

.buttonBlock {
    display: flex;
    margin-left: auto;
    gap: var(--gap-xsmall);
}

@media (--mobile) {
    .fullscreenBtn {
        display: none;
    }
}
</style>
<i18n lang="json">
    {
        "en": {
            "details": "Details",
            "fullscreen": "Fullscreen",
            "problem": "Report a Problem"
        }
    }
</i18n>
