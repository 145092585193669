<template>
    <button
        :class="[styles.iconButton, styles[appearance]]"
        :title="title"
    >
        <Icon :name="iconName" />
        <template v-if="text">{{ text }}</template>
    </button>
</template>

<script setup lang="ts">
import { defineProps, withDefaults, useCssModule } from 'vue'
import Icon, { type IconProps } from '@/components/Icon/index.vue'

interface IconButtonProps {
    iconName: IconProps['name'],
    text?: string,
    title?: string,
    appearance?: 'normal' | 'transparent',
}

withDefaults(defineProps<IconButtonProps>(), {
    appearance: 'normal',
})

const styles = useCssModule()
</script>

<style module>
.iconButton {
    display: flex;
    align-items: center;
    gap: var(--gap-xsmall);
    height: 40px;
    background-color: var(--black);
    border-radius: var(--radius-regular);
    padding: var(--gap-small);
    cursor: pointer;
    box-shadow: 0px 0px 144px 0px var(--shadow);
}

.iconButton.transparent {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
}

.iconButton:hover {
    box-shadow: 0px 0px 38px 4px var(--shadow);
}

.iconButton.transparent:hover,
.iconButton.transparent:focus,
.iconButton.transparent:active {
    box-shadow: none;
}
</style>
