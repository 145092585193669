<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M15.35 1.44H8.345c-1.44 0-2.784 1.391-2.784 2.88v15.36c0 1.776 1.68 2.88 2.88 2.88h6.91c1.44 0 2.69-1.249 2.69-2.689V4.13c0-1.44-1.25-2.69-2.69-2.69M11.8 21.36a.74.74 0 0 1-.72-.72c0-.384.335-.72.72-.72s.72.336.72.72-.336.72-.72.72m3.84-2.16H7.96V4.32h7.68z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Applications',
})
</script>
