<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" fill="none"><path fill="#fff" d="M17.8 4.4h-4c-1.213 0-2.2.987-2.2 2.2v5a.8.8 0 0 0 1.6 0V7.388c0-.108.092-.2.2-.2s.2.092.2.2v9.132c0 .625.334 1.08.96 1.08.593 0 1.04-.465 1.04-1.08v-5.137a.2.2 0 0 1 .4 0v5.254l.006.006c.055.553.481.957 1.034.957.626 0 .96-.455.96-1.08V7.458c0-.109.092-.2.2-.2s.2.091.2.2V11.6a.8.8 0 0 0 1.6 0v-5c0-1.213-.987-2.2-2.2-2.2m-7.08 2.523c-.23-.383-.67-.508-1.158-.249l-1.057.596L7.6 5.8C6.952 4.66 6.213 4.4 5 4.4H2.2C.987 4.4 0 5.387 0 6.6v5a.8.8 0 0 0 1.6 0V7.388c0-.108.092-.2.2-.2s.2.092.2.2v9.132c0 .625.334 1.08.96 1.08.593 0 1.04-.465 1.04-1.08v-5.137a.2.2 0 0 1 .4 0v5.254l.006.006c.055.553.481.957 1.034.957.626 0 .96-.455.96-1.08V7.458a.2.2 0 0 1 .162-.192l.915 1.371a.967.967 0 0 0 1.286.303L10.4 8c.483-.292.474-.828.32-1.077M4.2.4a1.8 1.8 0 1 0 0 3.6 1.8 1.8 0 0 0 0-3.6m11.6 0a1.8 1.8 0 1 0 0 3.6 1.8 1.8 0 0 0 0-3.6"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Multiplayer',
})
</script>
