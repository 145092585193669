/* eslint-disable max-len */
export const role = `
    <section class="textRegular">
        <h2 class="headerOne">Role-Playing Games — Play Online</h2>
        <p>In the world of video games, role-playing games (RPGs) hold a unique spot. Emerging from the tradition of tabletop role-playing games such as Dungeons &amp; Dragons, they immerse you in a world of adventure and story. RPGs first started making a shift to the digital format in the early 1970s with text-based adventures but it was in the mid-1980s that the genre came into its own with the release of seminal titles like Ultima and Final Fantasy. As the genre continues to evolve, we now have HTML5 RPGs which offer the excitement, immersion, and narrative depth of traditional RPGs, with the added benefit of being playable in your browser.</p>
    </section>
    <section class="textRegular">
        <h2 class="headerOne">How to Play Role-Playing Games Online</h2>
        <p>Role-playing games typically revolve around a complex narrative, requiring you to complete quests, interact with characters, and explore open-world environments. Your character gains skills and abilities as you progress that allow you to tackle increasingly difficult challenges. HTML5 RPG games continue this tradition and simplify the controls to work seamlessly with your keyboard and mouse. You control your character’s actions and make decisions that affect the course of the game. Many HTML5 RPGs also feature complex combat systems, character advancement and skill trees, and vast worlds to explore right from your browser.</p>
        <ul>
            <li>Start by familiarizing yourself with the character creation process. Most RPG games offer you a chance to determine the abilities, skills, and appearance of your character. It's always best to choose a character that matches your preferred playstyle.</li>
            <li>Get to know the game mechanics. RPG games often have complex systems for character advancement, combat, and exploration. Knowing how these systems work can give you an edge.</li>
            <li>Don't rush. RPG games are often about exploring and immersing yourself in a fictional world. Look around, talk to non-player characters, and take your time to truly enjoy the gaming experience.</li>
            <li>Save often. Many RPGs feature challenging combat and complex puzzles. Saving your progress regularly ensures you don't lose any hard-earned progress.</li>
            <li>Finally, interact with everything. NPCs, objects, and even seemingly inconsequential details may provide valuable resources, quest hints, or interesting story bits.</li>
        </ul>
    </section>
`
