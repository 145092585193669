<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><g clip-path="url(#a)"><path fill="#fff" d="M9.61 0C8.123 0 6.04.91 6.04 2.91c0 2.732 1.94 5.1 3.225 6.675.308.379.666.83.87 1.14-.322-.137-.78-.386-1.2-.615-1.528-.834-3.62-1.98-5.805-1.98-.465 0-1.61.092-2.325.99-.51.641-.649 1.496-.405 2.55.182.786.456 1.24.66 1.56.113.174.199.298.225.42.028.135.008.4-.015.69-.041.533-.105 1.271.06 2.055.274 1.294 1.258 2.805 3.135 2.805.242 0 .508-.037.765-.09 2.126-.427 3.791-2.512 5.01-4.035.285-.356.63-.767.93-1.095-.356 2.664-.272 6.094 1.53 9.045l.045.075c.343.574.984.9 1.755.9.57 0 1.288-.204 1.53-.645a.63.63 0 0 0-.015-.66.6.6 0 0 0-.09-.105c-2.3-2.046-2.98-5.16-3.525-9 .62.308 1.352 1.016 2.115 1.755 1.19 1.153 2.552 2.466 4.17 3.015.296.101.606.15.915.15 1.303 0 2.475-.906 2.73-2.115.167-.787.101-1.446.06-1.92-.022-.26-.028-.482 0-.615.026-.127.101-.262.21-.45.195-.338.463-.799.63-1.59.184-.874.13-2.107-.57-2.97-.347-.428-1.009-.945-2.19-.945-2.182 0-4.279 1.219-5.805 2.1-.371.214-.787.461-1.11.615.221-.352.574-.84.885-1.26 1.344-1.815 3.18-4.309 3.18-6.69 0-1.549-1.496-2.67-3.57-2.67-.802 0-1.32.139-1.695.24-.42.112-.626.114-1.05 0A6 6 0 0 0 9.61 0"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Match3',
})
</script>
