<template>
    <div :class="$style.container">
        <DashboardTitle v-if="$slots.title">
            <slot name="title" />
        </DashboardTitle>
        <slot />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DashboardTitle from './DashboardTitle.vue'

export default defineComponent({
    name: 'DashboardCard',
    components: {
        DashboardTitle,
    },
})
</script>

<style module>
.container {
    width: 100%;
    box-sizing: border-box;
    padding: var(--gap-large);
    border: 1px solid var(--white-25);
    border-radius: var(--radius-large);
}
</style>
