<template>
    <div :class="['headerOne', $style.title]">
        <slot />
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'DashboardTitle',
})
</script>
<style module>
.title {
    display: flex;
    align-items: center;
    gap: var(--gap-small);
    padding-bottom: var(--gap-regular);
    border-bottom: 1px solid var(--white-25);
    margin-bottom: var(--gap-regular);
}
</style>
