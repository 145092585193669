export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "great": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That's great!"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ooops! Something went wrong"])},
        "tryLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are already fixing it. Please try again later"])},
        "addTonWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You should add your TON Wallet to buy this item"])},
        "claimed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus claimed!"])},
        "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your daily rewards have been added to your balance. Enjoy your gaming!"])}
      }
    }
  })
}
