export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
        "gamesPlayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games Played"])},
        "memberFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member for"])},
        "membershipDays": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 day"]), _normalize([_interpolate(_named("n")), " days"])])},
        "playstreak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playstreak"])},
        "playstreakDays": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), " days"])}
      }
    }
  })
}
