<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M12 0a2.41 2.41 0 0 0-2.4 2.4H6.4A2.41 2.41 0 0 0 4 4.8v14.4c0 1.316 1.084 2.4 2.4 2.4h11.2c1.316 0 2.4-1.084 2.4-2.4V4.8c0-1.316-1.084-2.4-2.4-2.4h-3.2C14.4 1.084 13.316 0 12 0m0 1.6c.451 0 .8.349.8.8s-.349.8-.8.8a.79.79 0 0 1-.8-.8c0-.451.349-.8.8-.8M6.4 4h3.2v.8a.8.8 0 0 0 .8.8h3.2a.8.8 0 0 0 .8-.8V4h3.2c.452 0 .8.348.8.8v14.4c0 .452-.348.8-.8.8H6.4a.787.787 0 0 1-.8-.8V4.8c0-.452.348-.8.8-.8m5.605 4a.8.8 0 0 0-.752.514l-2.751 7.2a.8.8 0 1 0 1.495.572l.414-1.086h3.178l.414 1.086a.802.802 0 0 0 1.479.04.8.8 0 0 0 .016-.612l-2.751-7.2A.8.8 0 0 0 12.005 8M12 11.04l.978 2.56h-1.956z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Tests',
})
</script>
