<template>
    <div :class="$style.sidebar">
        <IconButton
            :iconName="isDropdownOpen ? 'cross' : 'burger'"
            appearance="transparent"
            :class="$style.button"
            @click="toggleOverlay"
        />
        <RouterLink :class="$style.pgLogo" to="/">
            <Icon name="logo" />
        </RouterLink>
        <Overlay
            :isOpen="isDropdownOpen"
            :class="[$style.overlay, isDropdownOpen ? $style.open :'']"
            @click="closeOverlay"
        >
            <div :class="$style.menu">
                <MenuBigCategories
                    :class="$style.bigCategories"
                    :categories="props.highlightCategories"
                    isBigItem
                />
                <button
                    :class="[
                        'title',
                        $style.categoryButton,
                        isCategoriesOpen ? $style.open : ''
                    ]"
                >
                    {{ t('categories') }}
                    <Icon name="arrow" />
                </button>
                <MenuRegularCategories
                    :class="[$style.hide, isCategoriesOpen && $style.show]"
                    :categories="props.categories"
                    isBigItem
                />
            </div>
            <MenuFooter :class="$style.footer" isBigItem />
        </Overlay>
        <div :class="[$style.menu, $style.desktopLayout]">
            <MenuBigCategories
                :categories="highlightCategories"
            />
            <MenuRegularCategories
                :categories="categories"
            />
        </div>
        <MenuFooter :class="[$style.footer, $style.desktopLayout]"/>
    </div>
</template>

<script setup lang="ts">
import {
    defineProps,
    ref,
    useCssModule,
} from 'vue'
import { useI18n } from 'vue-i18n'
import type { CategoryListType } from '@/types'
import MenuFooter from '@/components/Menu/MenuFooter.vue'
import IconButton from '@/components/IconButton.vue'
import Icon from '@/components/Icon/index.vue'
import Overlay from '@/components/Overlay.vue'
import MenuBigCategories from '@/components/Menu/MenuBigCategories.vue'
import MenuRegularCategories from './Menu/MenuRegularCategories.vue'

interface LeftSideProps {
    highlightCategories: CategoryListType[],
    categories: CategoryListType[],
}

const props = defineProps<LeftSideProps>()

const isDropdownOpen = ref(false)
const isCategoriesOpen = ref(false)
const $style = useCssModule()
const { t } = useI18n()

function toggleOverlay(): void {
    isDropdownOpen.value = !isDropdownOpen.value
    isCategoriesOpen.value = false
}

function closeOverlay(e: MouseEvent): void {
    const isElement = e.target instanceof HTMLElement || e.target instanceof SVGElement
    const isCategoryBtn = isElement && Boolean(e.target.closest(`.${$style.categoryButton}`))
    if (isCategoryBtn) {
        isCategoriesOpen.value = !isCategoriesOpen.value
        return
    }
    isDropdownOpen.value = false
    isCategoriesOpen.value = false
}
</script>

<style module>
.sidebar {
  position: fixed;
  top: var(--gap-large);
  bottom: var(--gap-large);
  display: flex;
  flex-direction: column;
}

.menu {
    flex: 1 1 100%;
    overflow-y: scroll;
    scrollbar-width: none;
}

.menu::-webkit-scrollbar {
    display: none;
}

.footer {
    flex-shrink: 0;
}

.footer.desktopLayout {
    margin-top: var(--gap-regular);
    padding-top: var(--gap-regular);
    border-top: 1px solid var(--white-25);
}

.pgLogo {
    display: block;
    padding-bottom: var(--gap-regular);
    margin-bottom: var(--gap-regular);
    border-bottom: 1px solid var(--white-25);
}

.button {
    position: absolute;
    right: 20px;
    display: none;
}

.hide {
    display: none;
}

.show {
    display: flex;
}

@media (--mobile) or (--tablet) {
    .pgLogo {
        margin-bottom: var(--gap-small);
        border-bottom: none;
    }
    .sidebar {
        position: static;
    }

    .button {
        display: block;
    }

    .desktopLayout {
        display: none;
    }

    .categoriesBlock {
        border-top: 1px solid var(--white-25);
        border-bottom: 1px solid var(--white-25);
    }

    .categoryButton {
        display: flex;
        width: 100%;
        height: 72px;
        justify-content: space-between;
        align-items: center;
    }

    .categoryButton.open svg {
        transform: rotate(90deg);
    }

    .bigCategories {
        border-bottom: 1px solid var(--white-25);
    }

    .footer {
        padding: var(--gap-regular) 0 0;
        border-top: 1px solid var(--white-25);
    }

    .sidebar .overlay.open {
        display: block;
        top: 81px;
        height: calc(100% - 81px);
        background-color: var(--background);
    }
}
</style>
<i18n lang="json">
{
    "en": {
        "categories": "Categories"
    }
}
</i18n>
