<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M20.31 2.4c-1.46 3.044-4.92 7.176-10.118 11.145l1.883 2.65c4.083-3.07 7.59-6.63 9.147-9.63A3.315 3.315 0 0 0 20.31 2.4M7.19 13.59a.801.801 0 0 0-.655 1.254l.434.65-3.867 2.748a1.663 1.663 0 0 0-.394 2.32l.24.336c.531.75 1.571.927 2.321.394l3.775-2.684.69 1.036a.8.8 0 1 0 1.332-.888l-3.2-4.8a.8.8 0 0 0-.675-.365"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Midcore',
})
</script>
