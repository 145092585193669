<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M15 3v6h-2V5H4v14h9v-4h2v6H2V3zM8 13v-2h12.086l-2.5-2.5L19 7.086 23.914 12 19 16.914 17.586 15.5l2.5-2.5z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ArrowOut',
})
</script>
