<template>
    <!-- eslint-disable max-len -->
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2202_1859)"><path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37257 18.6274 0 12 0C5.37257 0 0 5.37257 0 12C0 18.6274 5.37257 24 12 24Z" fill="#0098EA"/><path d="M16.0972 6.69751H7.9022C6.39543 6.69751 5.4404 8.32287 6.19846 9.63682L11.2561 18.4032C11.5862 18.9756 12.4133 18.9756 12.7433 18.4032L17.802 9.63682C18.559 8.32497 17.604 6.69751 16.0982 6.69751H16.0972ZM11.252 15.7743L10.1505 13.6425L7.49278 8.8891C7.31746 8.58485 7.53401 8.19498 7.90117 8.19498H11.251V15.7753L11.252 15.7743ZM16.5046 8.88807L13.8479 13.6435L12.7464 15.7743V8.19395H16.0962C16.4633 8.19395 16.6799 8.58382 16.5046 8.88807Z" fill="white"/></g><defs><clipPath id="clip0_2202_1859"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Ton',
})
</script>
