<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" fill="none"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="m2.188 5.438 4.596 4.596 7.778-7.778"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'CheckMark',
})
</script>
