import { defineStore } from 'pinia'
import type {
    EventForLoggersType,
    CustomEventForGa,
    PlaygamaLoggerEventType,
} from '@/types'
import { useUserStore } from '@/store/user-store'
import { useAppStore } from '@/store/app-store'
import { useGamePageStore } from '@/pages/Games/game-store'
import { sendEvent } from '@/utils/analytics'

export type EventData = {
    gameHru?: string,
    clid?: string,
    userId?: string,
}

export const useLoggerStore = defineStore('logger', () => {
    async function getEventData(): Promise<EventData> {
        const userStore = useUserStore()
        const appStore = useAppStore()
        const gameStore = useGamePageStore()
        const user = await userStore.getUser()
        return {
            gameHru: gameStore.game?.hru,
            clid: appStore.clid,
            userId: user?._id,
        }
    }

    async function logEvent(event: EventForLoggersType): Promise<void> {
        const { clid, userId, gameHru } = await getEventData()
        if (event.eventName !== 'game_opened' && event.eventName !== 'session_start') {
            const gaEvent: CustomEventForGa = {
                event: event.event,
                eventName: event.eventName,
                label: event.label,
                action: event.action,
            }
            if (event.clid) {
                gaEvent.clid = event.clid
            }
            window.dataLayer.push(gaEvent)
        }

        const analyticsEvent: PlaygamaLoggerEventType = {
            eventName: event.eventName,
            pageName: event.pageName,
            userId,
            clid,
            gameHru,
        }
        sendEvent(analyticsEvent as PlaygamaLoggerEventType)
    }

    return {
        logEvent,
    }
})
