<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="21" fill="none"><path fill="#fff" d="M5 .6a2.4 2.4 0 1 0 0 4.8A2.4 2.4 0 0 0 5 .6m-.642 1.916h1.284a.642.642 0 1 1 0 1.284H4.358a.642.642 0 1 1 0-1.284M9.8 3.8a.8.8 0 0 0-.647.33l-.003.004-.012.018-1.222 1.68 1.295.94 1.236-1.702A.8.8 0 0 0 9.8 3.8M3.59 7c-.908 0-1.742.515-2.148 1.327L.285 10.642A.8.8 0 0 0 .2 11v3.2a.8.8 0 1 0 1.6 0v-3.01l.8-1.6v10.183a.827.827 0 0 0 1.652.055l.35-5.256a.4.4 0 0 1 .797.002l.35 5.254a.827.827 0 0 0 1.651-.055V9.59l.8 1.6v3.01a.8.8 0 1 0 1.6 0V11a.8.8 0 0 0-.084-.358L8.558 8.327A2.4 2.4 0 0 0 6.411 7z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Arcade',
})
</script>
