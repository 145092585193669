/* eslint-disable max-len */
export const twoPlayer = `
    <section class="textRegular">
        <h2 class="headerOne">2 player Games — Play Online</h2>
        <p>Enjoy the thrill of competition and shared fun with our amazing collection of 2 player games. These games celebrate the spirit of friendly rivalry and companionship, offering a diverse variety of challenges and scenarios. The genre of 2 player games has a rich history, dating back to the earliest days of video gaming. Notable games like Pong and Space Invaders allowed players to face off against each other, forging a competitive spirit that has evolved into today’s diverse range of 2 player games. From classic card or board games reimagined digitally to exciting new releases across various genres, 2 player games offer something for everyone.</p>
    </section>
    <section class="textRegular">
        <h2 class="headerOne">How to Play 2 player Games</h2>
        <p>2 player games are all about interaction, split choice making, teamwork, or competing against each other. Designed with two players in mind, these games create an enjoyable, competitive atmosphere that requires both skill and strategy. Most 2 player games are easy to learn but can offer a lifetime of mastery. The gameplay varies greatly depending on the game, from racing against your friend in a high-speed pursuit to working together to defeat hordes of enemies in a cooperative battle. Regardless of the game or genre, most 2 player games provide an accessible and enjoyable gaming experience.</p>
        <ul>
            <li>Communicate with Your Partner — Whether you're working together or competing, communication is key in 2 player games.</li>
            <li>Learn the Rules — Each 2 player game has distinct rules. Start by familiarising yourself with them before diving into the game.</li>
            <li>Strategize Wisely — Most 2 player games require a level of strategy. Think wisely and anticipate your opponent's next move.</li>
            <li>Practice For Perfection — The key to success in 2 player games is practice. The more you play, the better you'll become. So, keep practicing and watch your skills improve!</li>
            <li>Enjoy the Game — Win or lose, the most important part of 2 player games is having fun!</li>
        </ul>
    </section>
`
