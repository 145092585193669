<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="32" height="32"><path fill="#fff" d="M16 2 2 16l14 14 14-14zm-1.414 6.457h2.828v6.583l-.592 4.542h-1.49l-.746-4.52zm1.425 15.755c-.9 0-1.514-.812-1.514-1.8 0-1.052.614-1.82 1.514-1.82.768 0 1.492.768 1.492 1.82 0 .988-.724 1.8-1.492 1.8"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ExclamationMark',
})
</script>
