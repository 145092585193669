<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M22.211 8.68c-.862-1.427-2.143-1.735-3.182-1.636.105-.294.178-.643.178-1.044 0-2.75-3.954-5.28-4.606-5.28-.47 0-.587.456-.636.65-.088.336-.143.486-.542.503-.324.013-.825-.446-1.177-.784-.385-.367-.689-.658-1.054-.658-.664 0-.718.68-.739.936-.01.105-.024.304-.056.345 0 0-.03.017-.116.017-.405 0-.844-.418-1.197-.754-.36-.343-.67-.64-1.059-.64a.58.58 0 0 0-.456.21c-.22.263-.157.618-.084 1.028.043.236.131.73.04.851-.006.008-.032.021-.074.027h-.02c-.413 0-1.318-1.15-1.662-1.588C5.291.253 5.093 0 4.761 0c-.754 0-.754 1.228-.754 1.631 0 .5.067 2.263.952 3.878.867 1.577 2.21 2.411 3.882 2.411h.062c-1.96 1.601-4.92 4.157-5.804 5.66a.48.48 0 0 0 .111.616c.578.47 1.46.686 2.633.648a5.1 5.1 0 0 0-.89 2.489.48.48 0 0 0 .363.51c.423.105 2.012-.209 3.097-.477-.324 1.045-.68 2.612-.407 3.347.077.204.283.33.5.31.892-.092 1.91-.488 2.643-.809-.246.88-.401 1.67-.437 2.519a.483.483 0 0 0 .405.494c.817.128 1.766-.416 2.368-.886q.05.14.098.285c.196.59.442 1.327 1.209 1.327.2 0 .38-.125.45-.315.292-.793 1.01-3.735 1.37-5.835-1.918-.306-2.636-1.991-2.636-3.163 0-1.17.7-2.623 1.376-3.35a.48.48 0 1 1 .704.654c-.54.581-1.12 1.803-1.12 2.696 0 .709.4 1.984 1.832 2.216 1.234-.184 2.008-1.067 2.008-2.36 0-.67-.244-1.658-.431-2.322.49.088 1.088.263 1.468.58a.478.478 0 0 0 .774-.48c-.279-1.182-1.044-1.965-1.653-2.42.677-.26 1.721-.55 2.822-.447a.48.48 0 0 0 .451-.223.48.48 0 0 0 .004-.505m-7.61.44A.96.96 0 1 1 14.6 7.2a.96.96 0 0 1 0 1.919"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Farming',
})
</script>
