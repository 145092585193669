<template>
    <div :class="$style.wrapper">
        <router-link
            v-for="game in task.games"
            :key="game"
            :to="{name: 'game-page', params: {game: game.hru}}"
            :class="[$style.link, {[$style.completed]: completed.indexOf(game.hru) > -1}]"
        >
            <div :class="$style.imageWrapper">
                <img
                    :src="game.preview.replace('enlarged', 'small')"
                    :alt="game.title"
                    :class="$style.image"
                />
            </div>

            <span :class="$style.title"> {{game.title}} </span>
        </router-link>
    </div>
</template>
<script lang="ts" setup>
import { computed, defineProps } from 'vue'
import { Task } from '@/types'

const props = defineProps<{task: Task}>()

const completed = computed(() => props.task.completed?.split(',') || [])

</script>
<style module>
.wrapper{

}
.link {
    display: flex;
    color: var(--white-50);
    font-size: 9px;
    text-transform: uppercase;
    margin-bottom: 8px;
    align-items: center;
}
.imageWrapper {
    height: 24px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}
.completed .imageWrapper::after {
    content: '✓';
    position: absolute;
    font-size: 18px;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--black-50);
}

.image {
    height: 24px;
}
.title {
    margin-left: 8px;
}
</style>
