<template>
    <nav :class="styles.menu">
        <RouterLink
            v-for="item in props.categories"
            :key="item.name"
            :to="item.href"
            :class="[
                regularTextStyle,
                styles.regularCategory,
                item.href === route.path && styles.active,
            ]"
        >
            {{ t(item.name) }}
        </RouterLink>
    </nav>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import {
    useCssModule,
    defineProps,
    withDefaults,
    computed,
} from 'vue'
import { useI18n } from 'vue-i18n'
import type { CategoryListType } from '@/types'
import { categoriesTranslation } from '@/utils/translations/categories'

interface MenuProps {
    categories: CategoryListType[],
    isBigItem?: boolean,
}

const props = withDefaults(defineProps<MenuProps>(), {
    isBigItem: false,
})

const styles = useCssModule()
const { t } = useI18n({ messages: categoriesTranslation })
const route = useRoute()

const regularTextStyle = computed(() => {
    if (props.isBigItem) {
        return 'headerOne'
    }
    return 'accent'
})
</script>

<style module>
.menu {
    display: flex;
    flex-direction: column;
    margin-top: var(--gap-large);
}

.regularCategory {
    position: relative;
    margin-top: var(--gap-xsmall);
    padding-top: 0;
    padding-left: var(--gap-medium);
    border-top: none;
}

.regularCategory:before {
    content: '';
    position: absolute;
    display: none;
    left: 0;
    top: 7px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--light-pastel-purple);
}

.regularCategory:hover,
.regularCategory.active {
    color: var(--light-pastel-purple);
}

.regularCategory.active:before {
    display: inline-block;
}

@media (--mobile) {
    .menu {
        overflow-y: scroll;
        padding: var(--gap-small);
        margin: 0 -20px;
    }
    .regularCategory {
        margin-top: 0;
        padding-left: var(--gap-large);
    }

    .regularCategory:before,
    .regularCategory:first-child:before {
        top: 10px;
        left: 14px;
    }
}
</style>
