<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none"><path fill="#fff" d="M10.008.35a.505.505 0 0 1 .961 0l2.13 6.553c.067.208.261.35.48.35h6.891c.49 0 .694.626.297.914l-5.574 4.05a.51.51 0 0 0-.184.565l2.13 6.554a.506.506 0 0 1-.779.565l-5.574-4.05a.505.505 0 0 0-.595 0L4.616 19.9a.505.505 0 0 1-.778-.565l2.13-6.554a.51.51 0 0 0-.184-.565L.21 8.167a.505.505 0 0 1 .297-.915h6.891a.505.505 0 0 0 .481-.349z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Popular',
})
</script>
