<template>
    <Layout>
        <template #leftSide>
            <LeftSide
                :highlightCategories="menuHighlightCategoriesList"
                :categories="menuCategoriesList"
            />
        </template>
        <template #default>
            <UserCabinet
                v-if="user"
                :user="user"
                :shopItems="shopItems"
                :tasksByTags="tasksByTags"
                :popularGames="popularGames"
                :viewedGames="viewedGames"
                :isBuyingDisabled="isBuyingDisabled"
                :fennecsLabel="fennecsLabel"
                :class="$style.profile"
                @logoutUser="logoutUser"
                @buyItem="buyItem"
                @openClaimModal="openClaimModal"
            />
            <NotificationModal
                v-if="isNotificationModalOpen && notificationModalOptions"
                :isOpen="isNotificationModalOpen"
                :options="notificationModalOptions"
                @handleAction="handleAction"
            />
        </template>
        <template #rightSide>
            <RightSide
                :recentlyGames="recommendedGames"
            />
        </template>
    </Layout>
</template>
<script setup lang="ts">
import {
    onServerPrefetch,
    onMounted,
    ref,
    watch,
} from 'vue'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useCategoriesStore } from '@/store/categories-store'
import { useUserStore } from '@/store/user-store'
import type {
    Task,
    ShopItem,
} from '@/types'
import Layout from '@/layouts/ThreeColumnsLayout.vue'
import LeftSide from '@/components/LeftSide.vue'
import RightSide from '@/components/RightSide.vue'
import UserCabinet from '@/components/UserCabinet/UserCabinet.vue'
import NotificationModal,
{
    type NotificationModalOptions,
    type BtnActions,
} from '@/components/NotificationModal/NotificationModal.vue'

const { t } = useI18n()
const categoriesStore = useCategoriesStore()
const userStore = useUserStore()
const router = useRouter()

const {
    contentCategoriesList,
    menuCategoriesList,
    menuHighlightCategoriesList,
    recommendedGames,
    popularGames,
    viewedGames,
} = storeToRefs(categoriesStore)
const {
    user,
    shopItems,
    tasksByTags,
    fennecsLabel,
} = storeToRefs(userStore)

onServerPrefetch(async () => {
    await categoriesStore.setMainData()
})

const isNotificationModalOpen = ref(false)
const notificationModalOptions = ref<NotificationModalOptions | null>(null)
const isBuyingDisabled = ref(false)

async function openClaimModal(task: Task) {
    const status = await userStore.claimUserBonus(task)
    isNotificationModalOpen.value = true
    if (status === 'error') {
        return
    }
    notificationModalOptions.value = {
        imgSrc: '/img/coins.png',
        imgAlt: 'fennec coins',
        header: String(task.reward),
        title: t('claimed'),
        subtitle: t('added'),
        buttonText: t('great'),
        buttonAction: 'close',
    }
}

async function buyItem(item: ShopItem) {
    isBuyingDisabled.value = true
    if (item.requires === 'ton_wallet' && !user.value?.tonWallet) {
        notificationModalOptions.value = {
            title: t('error'),
            subtitle: t('addTonWallet'),
            buttonText: t('close'),
            buttonAction: 'close',
        }
        isBuyingDisabled.value = false
        isNotificationModalOpen.value = true
        return
    }
    const status = await userStore.buyItem(item)
    isBuyingDisabled.value = false
    isNotificationModalOpen.value = true
    if (status === 'error') {
        notificationModalOptions.value = {
            imgSrc: '/',
            imgAlt: 'partner logo',
            title: t('error'),
            subtitle: t('tryLater'),
            buttonText: t('close'),
            buttonAction: 'close',
        }
        return
    }
    notificationModalOptions.value = {
        imgSrc: item.image,
        imgAlt: 'partner logo',
        title: item.title,
        subtitle: item.boughtDescription,
        buttonText: t('great'),
        buttonAction: 'close',
    }
}

function closeNotificationModal() {
    isNotificationModalOpen.value = false
    notificationModalOptions.value = null
}

function handleAction(action: BtnActions) {
    if (action === 'close') {
        closeNotificationModal()
    }
}

async function logoutUser() {
    router.push({ name: 'main' })
    await userStore.logout()
}

watch(() => userStore.isUserInfoLoaded, (value) => {
    if (value && !userStore.isAuthorized) {
        router.push({ name: 'main' })
    }
})

onMounted(async () => {
    userStore.getShopItems()
    // Prevents the request from being made twice if the data is already in the store
    if (contentCategoriesList.value.length) {
        return
    }
    await categoriesStore.setMainData()
})
</script>

<style module>
.profile {
    margin-left: auto;
    margin-right: auto;
    max-width: 750px;
}

@media (--mobile) {
    .profile {
        max-width: 100%;
    }
}
</style>

<i18n lang="json">
{
    "en": {
        "great": "That's great!",
        "close": "Close",
        "error": "Ooops! Something went wrong",
        "tryLater": "We are already fixing it. Please try again later",
        "addTonWallet": "You should add your TON Wallet to buy this item",
        "claimed": "Bonus claimed!",
        "added": "Your daily rewards have been added to your balance. Enjoy your gaming!"
    }
}
</i18n>
