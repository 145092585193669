import type { AdvAction } from '@/modules/adv'
import type { MessageAction } from '@/modules/main-frame'
import { IconNameType } from '@/components/Icon/icons-list'

export enum GameProviders {
    GOOGLE_AD = 'googleAd',
    GAME_DISTRIBUTION = 'gameDistribution',
    YANDEX_AD = 'yandexAd',
    DEBUG_PROVIDER = 'debug',
}

export type TMessageAction = MessageAction & AdvAction

export type GameEvent = Omit<MessageEvent, 'data'> & {
    source: MessageEventSource,
    data: {
        id: string,
        action: TMessageAction,
        payload?: Record<string, unknown>,
        responseToId?: string,
        type: 'adv' | 'liveness' | 'error',
    }
}

type AvailableGAEvents = 'ad_request' | 'game_init' | 'navigation' | 'session_end' | 'ping'
type AvailablePGEvents = AvailableGAEvents | 'game_opened' | 'session_start'

export type AdType = 'interstitial' | 'rewarded'

export type PlaygamaLoggerEventType = {
    userId?: string,
    clid?: string,
    eventName: AvailablePGEvents,
    pageName?: string,
    gameHru?: string,
}

export type CustomEventForGa = {
    event: 'custom_event' | 'user_time',
    eventName: AvailableGAEvents,
    label: 'sticky' | AdType | number | '',
    action: 'empty' | 'show' | 'start' | 'rewarded' | 'close' | 'init' | string,
    clid?: 'direct' | string,
}

export type EventForLoggersType = {
    clid?: CustomEventForGa['clid'],
    event: CustomEventForGa['event'],
    eventName: AvailablePGEvents,
    label: CustomEventForGa['label'],
    action: CustomEventForGa['action'],
    pageName?: string,
    gameHru?: string,
}

export type PaginationType = {
    page: number
    pageSize: number,
}

export type PlatformType = 'ios' | 'android' | 'desktop'

export enum Platforms {
    MOBILE = 'mobile',
    DESKTOP = 'desktop',
}

export enum GameOrientation {
    LANDSCAPE = 'landscape',
    PORTRAIT = 'portrait',
}

export type GameType = {
    id: string;
    hru: string;
    title: string;
    icon: string;
    preview: string;
    description: string;
    instruction: string;
    is_android: boolean;
    is_ios: boolean;
    is_desktop: boolean;
    tags: string[];
    game_url: string;
    orientation: GameOrientation;
    screenshots: string[];
    videos?: {
        player_url: string;
        preview_url: string;
        thumbnail_url: string;
    }[];
    seoText: string;
}

export type CategoriesType = 'popular' | 'all_games' | 'recommended' | 'trending_now' | 'new' | 'random' | '2_player' | '3d' | 'action' | 'adventure' | 'arcade' | 'baby' | 'bejeweled' | 'boys' | 'clicker' | 'cooking' | 'farming' | 'girls' | 'hypercasual' | 'io' | 'multiplayer' | 'puzzle' | 'racing' | 'shooting' | 'sports' | 'stickman' | 'soccer' | 'social' | 'top_picks' | 'in_game_purchase' | 'cards' | 'kids' | 'educational' | 'quiz' | 'simulator' | 'strategy' | 'tabletop' | 'match3' | 'role' | 'casino' | 'balloons' | 'economic' | 'midcore' | 'horrors' | 'imitations' | 'applications' | 'tests'

export type SimplifiedGame = Omit<GameType, 'id'|'icon'|'description'|'instruction'|'platforms'|'game_url'|'orientation'|'screenshots'|'videos'|'tags'|'is_ios'|'is_desktop'|'is_android'|'seoText'> & {
    video?: {
        preview_url: string
    }
}

export type CategoryDataType = {
    categories: {
        id: CategoriesType;
        internal: boolean;
        games: SimplifiedGame[];
        totalCount: number;
    }[];
    pagination: {
        page: 0;
        pageSize: 0;
    };
}

export type APICategoryDataType = {
    categories: {
        id: CategoriesType;
        internal: boolean;
        games: GameType[];
        totalCount: number;
    }[];
    pagination: {
        page: 0;
        pageSize: 0;
    };
}

export type CategoryListType = {
    name: CategoriesType,
    href: string,
    icon: IconNameType,
    games?: SimplifiedGame[],
}

export type FormSubmitData = {
    message: string,
    email: string,
    page?: string,
}

export type UserSocialKeys = 'telegramAcc' | 'tonWallet'
export const SOCIALS = ['telegram', 'ton'] as const
export type SocialsName = typeof SOCIALS[number]
export type SocialType = 'account' | 'wallet'
export type SocialTag = 'telegram' | 'tonWallet'

export type UserSocialsType = {
    name: SocialsName,
    account?: string,
    type: SocialType,
    tag: string,
    key: UserSocialKeys,
    isSupport: boolean,
    validate: (value: string) => boolean,
}

export enum TaskStatus {
    ACTIVE = 'ACTIVE',
    PARTIAL = 'PARTIAL',
    FULFILLED = 'FULFILLED',
    ARCHIVED = 'ARCHIVED',
    FAILED = 'FAILED',
    CLAIMED = 'CLAIMED',
    CLAIMED_ARCHIVED = 'CLAIMED_ARCHIVED',
}

const TaskTags = ['daily', 'social', 'playGames'] as const
export type TasksTagType = typeof TaskTags[number]

export enum TaskType {
    'VISIT_EVERYDAY' = 'VISIT_EVERYDAY',
    'VISIT_N_DAYS_STREAK' = 'VISIT_N_DAYS_STREAK',
    'REGISTER' = 'REGISTER',
    'INIT_GAME' = 'INIT_GAME',
    'PLAY_GAME_N_MINS' = 'PLAY_GAME_N_MINS',
    'PLAY_GAMES' = 'PLAY_GAMES',
    'WATCH_AD' = 'WATCH_AD',
}

export type Task = {
    _id: string,
    title: string,
    type: TaskType,
    settings: string,
    reward: number,
    icon: string,
    status: TaskStatus,
    iconBgColor: string,
    games?: SimplifiedGame[],
    completed?: string
}

export type TasksByTag = {
    tag: TasksTagType,
    tasks: Task[],
}

export type User = {
    _id: string,
    firstName: string,
    lastName: string,
    avatar: string,
    fennecs: number,
    telegramAcc?: string,
    tonWallet?: string,
    gamesPlayed?: number,
    createdAt?: number,
    currentStreak?: number,
}

export type ShopItem = {
    _id: string,
    icon: string,
    image: string,
    title: string,
    description: string,
    boughtDescription: string,
    price: number,
    status: 'BOUGHT' | 'AVAILABLE' | 'SOON' | 'ENDED',
    startDate: string,
    maxItemsPerUser: number,
    boughtCount: number,
    requires?: 'ton_wallet'
}

export type TaskStats = {
    tasks: number,
    fulfilled: number,
    unclaimed: number,
}
