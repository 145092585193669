import { CategoriesType } from '@/types'

export const categoriesTranslation: Record<string, Record<CategoriesType, string>> = {
    en: {
        recommended: 'Recommended',
        all_games: 'All games',
        trending_now: 'Trending now',
        new: 'New games',
        random: 'Random',
        popular: 'Popular',
        '2_player': '2 Player',
        '3d': '3D',
        action: 'Action',
        adventure: 'Adventure',
        arcade: 'Arcade',
        baby: 'Baby',
        bejeweled: 'Bejeweled',
        boys: 'Boys',
        clicker: 'Clicker',
        cooking: 'Cooking',
        farming: 'Farming',
        girls: 'Girls',
        hypercasual: 'Hypercasual',
        io: '.io',
        multiplayer: 'Multiplayer',
        puzzle: 'Puzzle',
        racing: 'Racing',
        shooting: 'Shooting',
        sports: 'Sports',
        stickman: 'Stickman',
        soccer: 'Soccer',
        social: 'Social',
        top_picks: 'Top picks',
        in_game_purchase: 'InGame purchase',
        cards: 'Cards',
        kids: 'Kids',
        educational: 'Educational',
        quiz: 'Quiz',
        simulator: 'Simulator',
        strategy: 'Strategy',
        tabletop: 'Tabletop',
        match3: 'Match3',
        role: 'Role',
        casino: 'Casino',
        balloons: 'Balloons',
        economic: 'Economic',
        midcore: 'Midcore',
        horrors: 'Horrors',
        imitations: 'Imitations',
        applications: 'Applications',
        tests: 'Tests',
    },
}
function getTranslation(obj: Record<CategoriesType, string>) {
    const keys = Object.keys(obj) as CategoriesType[]
    return keys.reduce((acc, key) => {
        if (obj[key].includes('games')) {
            acc[key] = obj[key]
        } else {
            acc[key] = `${obj[key]} games`
        }
        return acc
    }, {} as Record<CategoriesType, string>)
}
export const categoriesNamesTranslation = {
    en: getTranslation(categoriesTranslation.en),
}
// TODO: вынести на бэк DEV-76
export const categoriesDescriptions: Record<CategoriesType, string> = {
    popular: '',
    all_games: '',
    recommended: '',
    trending_now: '',
    new: '',
    random: '',
    '2_player': 'Our free online action games include classic 2D platform games, cartoony adventures, and a range of strategy and 3D titles. Have fun playing hundreds of the best action games for free. These top-rated action games are designed to be challenging. Whether you\'re looking for an adrenaline rush or a casual arcade game, you\'ll find something that fits your style.',
    '3d': '',
    action: 'Dive into the thrilling world of .io games! These fast-paced, multiplayer games allow you to compete against players from around the globe. Whether you\'re aiming to conquer the leaderboard or just have some quick fun, .io games offer endless excitement and competition.',
    adventure: 'Embark on epic journeys with our adventure games! From exploring mysterious lands to solving intricate puzzles, our adventure games will take you on unforgettable quests. Get ready to dive into immersive storylines and challenging gameplay that will keep you on the edge of your seat.',
    arcade: 'Relive the glory days of gaming with our arcade games! Featuring classic and modern titles, our arcade games promise hours of fun. From high-speed chases to classic shooters, you\'ll find a wide variety of games that will bring back memories and create new ones.',
    baby: '',
    bejeweled: '',
    boys: 'Explore a wide range of games tailored for boys! From action-packed adventures to sports and racing, our boys\' games are designed to entertain and challenge. Find your favorite and dive into a world of fun and excitement.',
    clicker: '',
    cooking: '',
    farming: '',
    girls: 'Discover a world of fun with our games for girls! From fashion and makeup to adventure and puzzles, our games are designed to entertain and inspire. Find your favorite and let the fun begin!',
    hypercasual: 'Enjoy quick and addictive fun with our hypercasual games! Perfect for short breaks, these games are easy to pick up and hard to put down. Whether waiting in line or relaxing at home, our hypercasual games are the perfect way to pass the time.',
    io: 'Challenge a friend in our two player games! Our 2-player games include fierce sports games, calm board games, and everything in between. 2-player games are even more exciting if you join matches with other players online. You can compete with your friends or against others worldwide in epic 2-player multiplayer action.',
    multiplayer: '',
    puzzle: 'Put your mind to the test with our puzzle games! From classic jigsaw puzzles to brain-teasing riddles, our puzzle games offer hours of challenging fun. Perfect for players who love to think and solve problems.',
    racing: 'Rev up your engines with our racing games! From high-speed street races to off-road adventures, our racing games offer thrilling action and realistic graphics. Compete against the clock or other players in our exciting racing games.',
    shooting: '',
    sports: 'Get in the game with our sports games! From football to basketball, our sports games offer realistic action and intense competition. Whether you prefer solo play or multiplayer matches, our sports games have something for every fan.',
    stickman: '',
    soccer: '',
    social: '',
    top_picks: '',
    in_game_purchase: '',
    cards: 'Enjoy a collection of classic and modern card games! Whether you\'re a fan of solitaire, poker, or strategic card games, our selection has something for every card enthusiast. Play solo or compete against others in our engaging card games.',
    kids: 'Find the perfect games for kids of all ages! Our kids\' games are fun, safe, and educational. From simple puzzles to exciting adventures, our games are designed to entertain and teach in a fun and interactive way.',
    educational: 'Make learning fun with our educational games! Designed to engage and educate, our games cover a variety of subjects and skills. Perfect for kids and adults alike, our educational games make learning enjoyable and interactive.',
    quiz: 'Test your knowledge with our quiz games! From general knowledge to specific topics, our quiz games are perfect for trivia enthusiasts. Challenge yourself and your friends to see who knows the most.',
    simulator: 'Experience life in different roles with our simulator games! From flying planes to running a farm, our simulator games offer realistic and engaging experiences. Perfect for players who love to dive deep into detailed simulations.',
    strategy: 'Plan, strategize, and conquer with our strategy games! From building empires to managing resources, our strategy games require careful planning and tactical skills. Challenge yourself with games that test your strategic thinking.',
    tabletop: 'Enjoy classic and modern tabletop games online! From chess to board games, our tabletop games offer fun and challenging gameplay. Perfect for players who love to think ahead and plan their moves.',
    match3: 'Get hooked on our match3 games! Match colorful gems, candies, and more to clear levels and achieve high scores. With a variety of themes and challenges, our match3 games are perfect for puzzle lovers.',
    role: 'Immerse yourself in our role-playing games! Create your character, embark on epic quests, and explore vast worlds. Our role-playing games offer deep storylines and complex gameplay for an unforgettable experience.',
    casino: 'Experience the thrill of the casino from the comfort of your home! Our casino games offer a variety of slots, poker, blackjack, and more. Test your luck and strategy in a fun and safe environment with our top-rated casino games.',
    balloons: 'Have a blast with our balloons games! Pop, match, and strategize your way through colorful levels filled with fun and challenges. Perfect for players of all ages, our balloons games offer a delightful mix of puzzle-solving and action.',
    economic: 'Dive into the world of strategy and management with our economic games! Build your empire, manage resources, and make critical decisions to grow your wealth. Our economic games offer a challenging and rewarding experience for aspiring tycoons.',
    midcore: 'Take on a greater challenge with our midcore games! Combining elements of casual and hardcore gaming, our midcore games offer a balanced experience that is both engaging and rewarding. Perfect for gamers looking for a bit more depth and strategy.',
    horrors: 'Get ready for a spine-chilling experience with our horror games! From haunted houses to eerie adventures, our horror games will keep you on the edge of your seat. Enter if you dare and face your fears head-on.',
    imitations: 'Challenge yourself with our imitation games! Test your memory and mimicry skills in a variety of fun and engaging games. From matching sequences to copying patterns, our imitation games offer a delightful challenge for all ages.',
    applications: '',
    tests: 'Challenge your mind with our test games! From IQ tests to personality quizzes, our test games offer a variety of fun and interesting challenges. See how you stack up and learn something new with our engaging test games.',
}
