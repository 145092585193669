<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M12 0a.8.8 0 1 0 0 1.6.8.8 0 0 0 0-1.6M7.2.8a.8.8 0 1 0 0 1.6.8.8 0 0 0 0-1.6m9.6 0a.8.8 0 1 0 0 1.6.8.8 0 0 0 0-1.6M2.4 2.4a.8.8 0 1 0 0 1.6.8.8 0 0 0 0-1.6m19.2 0a.8.8 0 1 0 0 1.6.8.8 0 0 0 0-1.6m-4.008 1.577a1 1 0 0 0-.176.023H6.522a.8.8 0 0 0-.802.362l-4.66 5.44a.8.8 0 0 0 .046 1.236l10.31 10.309.008.01.01.009a1 1 0 0 0 .124.101l.006.003.06.036.012.005a.8.8 0 0 0 .448.084h.008l.014-.003a.8.8 0 0 0 .288-.095l.001-.002a.8.8 0 0 0 .17-.13l.007-.004.01-.013 10.313-10.312a.8.8 0 0 0 .046-1.234l-4.627-5.397a.8.8 0 0 0-.722-.428M12 6.178 14.444 9.6H9.556zm5.961.27 2.7 3.152h-3.6zM6.039 6.45l.9 3.15h-3.6zm3.127 4.75h5.668L12 18.57z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Bejeweled',
})
</script>
