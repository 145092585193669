export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play now"])},
        "playNoAuth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just Play"])},
        "playAndEarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play & Earn"])},
        "about": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["About ", _interpolate(_named("game"))])},
        "instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruction"])},
        "desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desktop"])},
        "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
        "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android"])},
        "ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iOS"])},
        "landscape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landscape"])},
        "portrait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portrait"])},
        "platforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platforms"])},
        "orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orientation"])},
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
        "specs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specs"])}
      }
    }
  })
}
