<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M4.32.48a2.403 2.403 0 0 0-2.4 2.4v6.438c0 .913.322 1.803.924 2.524L7.2 16.51v5.571c0 .265.214.48.48.48h9.12v-4.32a.48.48 0 1 1 .96 0v4.32h2.4a.48.48 0 0 0 .48-.48v-4.545c0-2.694-1.684-4.575-4.095-4.575h-5.786l-4.04-4.039V2.88c0-1.323-1.076-2.4-2.4-2.4m9.128 1.92a4.8 4.8 0 1 0 0 9.6 4.8 4.8 0 0 0 0-9.6"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Quiz',
})
</script>
