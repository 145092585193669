<template>
    <component
        :is="link ? 'RouterLink' : 'button'"
        :to="link"
        :class="[
            $style.button,
            $style[size],
            plain ? $style.plain : '',
            active ? $style.current : '',
            disabled ? $style.disabled : '',
            text ? $style.text : '',
            circle ? $style.circle : '',
            transparent ? $style.transparent : '',
            (!transparent && !text && !plain) ? $style[backgroundColorName] : ''
        ]"
        :type="type"
        :disabled="disabled"
        @click="emit('click')"
    >
        <Icon v-if="icon" :name="icon" :class="$style.icon" />
        <span :class="textClass">
            {{ title }}
        </span>
    </component>
</template>

<script lang="ts" setup>
import {
    defineProps,
    computed,
    defineEmits,
    withDefaults,
} from 'vue'
import { RouteLocationRaw } from 'vue-router'
import Icon, { type IconProps } from '@/components/Icon/index.vue'

interface ButtonProps {
    link?: string | RouteLocationRaw,
    icon?: IconProps['name'],
    title: string,
    plain?: boolean,
    active?: boolean, // как альтернатива exact-active-class, который игнорирует пагинацию
    size: 's' | 'm' | 'l',
    disabled?: boolean,
    type?: 'button' | 'submit',
    text?: boolean,
    circle?: boolean,
    transparent?: boolean,
    backgroundColorName: string,
}

const props = withDefaults(defineProps<ButtonProps>(), {
    disabled: false,
    type: 'button',
    backgroundColorName: 'purple',
})
/* eslint-disable-next-line no-unused-vars */
const emit = defineEmits<{(e: 'click'): void}>()

const textClass = computed(() => ({
    l: 'headerOne',
    m: 'accent',
    s: 'subheader',
    xs: 'captionLight',
})[props.size])
</script>

<style module>
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--radius-regular);
    background-color: var(--button);
    cursor: pointer;
}

.button.purple {
    background-color: var(--button);
}
.button.black {
    background-color: var(--black);
}

.button.l {
    padding: var(--gap-small) var(--gap-xlarge);
}

.button.m {
    padding: var(--gap-xsmall) var(--gap-medium);
}

.button.s {
    padding: var(--gap-xsmall) var(--gap-small);
}

.button.plain {
    background-color: transparent;
    border: 1px solid var(--button-light);
    color: var(--button-light);
}

.button.plain:hover {
    border: 1px solid var(--white);
    color: var(--white);
}

.button.plain.current {
    background-color: var(--accent-purple);
    color: var(--white);
    border: 1px solid var(--accent-purple);
}

.button:not(.plain):hover {
    box-shadow: 0 2.8px 24px var(--shadow);
}

.button:not(.plain):disabled {
    color: var(--white-50);
    background-color: var(--dark-purple-50);
}

.button:not(.plain):disabled:hover {
    box-shadow: none;
    cursor: not-allowed;
    color: var(--white-50);
}

.icon {
    margin-right: var(--gap-small);
}

.button.plain.disabled,
.button.plain.disabled:hover {
    cursor: not-allowed;
    background-color: transparent;
    border: 1px solid var(--dark-purple);
    color: var(--dark-purple);
}

.button.plain.current.disabled,
.button.plain.current.disabled:hover {
    cursor: not-allowed;
    background-color: var(--accent-purple);
    color: var(--white);
    border: 1px solid var(--accent-purple);
}

.button.text {
    background-color: transparent;
    border: none;
    color: inherit;
}

.button.circle {
    border-radius: 50%;
}

.button.circle .icon {
    margin-right: 0;
}

.button.transparent {
    background-color: transparent;
    border: none;
}
</style>
