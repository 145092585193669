<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M2.4 2.88v5.28a.95.95 0 0 0 .476.842c.298.176.67.176.967 0a.95.95 0 0 0 .477-.842V6.158l3.16 3.16a.959.959 0 0 0 1.623-.421.96.96 0 0 0-.265-.936L5.677 4.8H7.68a.95.95 0 0 0 .841-.476.95.95 0 0 0 0-.968.95.95 0 0 0-.841-.476zm12.96 0a.95.95 0 0 0-.842.476.95.95 0 0 0 0 .968c.174.3.495.482.841.476h2.003l-3.161 3.161a.959.959 0 0 0 .422 1.622.96.96 0 0 0 .935-.264l3.162-3.161V8.16a.96.96 0 0 0 .476.842c.298.176.67.176.967 0a.95.95 0 0 0 .477-.842V2.88zM8.14 14.39a.97.97 0 0 0-.66.291l-3.16 3.162V15.84a.96.96 0 0 0-.975-.973.96.96 0 0 0-.945.973v5.28h5.28a.95.95 0 0 0 .841-.476.95.95 0 0 0 0-.968.96.96 0 0 0-.841-.476H5.677l3.161-3.161a.96.96 0 0 0 .216-1.063.96.96 0 0 0-.913-.585m6.73 0a.96.96 0 0 0-.889.6.96.96 0 0 0 .22 1.049l3.161 3.161H15.36a.95.95 0 0 0-.842.476.95.95 0 0 0 0 .968c.174.3.495.482.841.476h5.28v-5.28a.96.96 0 0 0-.974-.973.96.96 0 0 0-.945.973v2.003l-3.162-3.162a.96.96 0 0 0-.688-.29"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Fullscreen',
})
</script>
