<template>
    <dialog id="modal-base-content" :open="props.isOpen">
        <slot />
    </dialog>
</template>

<script setup lang="ts">
import {
    defineEmits,
    defineProps,
    onMounted,
    onBeforeUnmount,
} from 'vue'

type ModalBaseProps = {
    isOpen: boolean
}

const props = defineProps<ModalBaseProps>()

/* eslint-disable-next-line no-unused-vars */
const emit = defineEmits<{(e: 'closeModal'): void}>()

function closeModal() {
    emit('closeModal')
}

function handleKeyDown(e: KeyboardEvent) {
    if (props.isOpen && e.key === 'Escape') {
        closeModal()
    }
}

function handleClick(e: MouseEvent) {
    const isElement = e.target instanceof HTMLElement || e.target instanceof SVGElement
    const isInContent = isElement && Boolean(e.target.closest('#modal-base-content'))
    if (props.isOpen && !isInContent) {
        closeModal()
    }
}

onMounted(() => {
    window.addEventListener('keydown', handleKeyDown, {
        capture: true,
    })
    window.addEventListener('click', handleClick, {
        capture: true,
    })
})

onBeforeUnmount(() => {
    window.removeEventListener('keydown', handleKeyDown, {
        capture: true,
    })
    window.removeEventListener('click', handleClick, {
        capture: true,
    })
})

</script>
