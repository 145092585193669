<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" fill-rule="evenodd" d="M4.707 3.707a1 1 0 0 0-1.414 1.414l7.293 7.293-6.879 6.879a1 1 0 1 0 1.414 1.414L12 13.828l6.85 6.85a1 1 0 0 0 1.413-1.415l-6.849-6.849 7.264-7.263a1 1 0 0 0-1.415-1.414L12 11z" clip-rule="evenodd"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'CrossSlim',
})
</script>
