<template>
    <DashboardCard>
        <span :class="$style.balance">
            <img
                src="/img/fennec-coin.png"
                alt="fennec coin"
                :class="$style.fennecCoin"
            />
            <div :class="$style.balanceAmount">
                <span class="headerOne">{{ t('balance') }}</span>
                <span :class="$style.fennecsAmount">{{ fennecs }}</span>
            </div>
        </span>
    </DashboardCard>
</template>

<script lang="ts" setup>
import { defineProps } from 'vue'
import { useI18n } from 'vue-i18n'
import DashboardCard from './DashboardCard.vue'

type BalanceProps = {
    fennecs: string
}

defineProps<BalanceProps>()
const { t } = useI18n()
</script>

<style module>
.balance {
    display: flex;
    align-items: center;
    width: 100%;
    gap: var(--gap-small);
}

.fennecCoin {
    width: 72px;
    height: 72px;
}

.balanceAmount {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.fennecsAmount {
    font-family: var(--font-primary);
    font-size: 32px;
    font-weight: var(--font-weight-medium);
    line-height: 36px;
}
</style>

<i18n lang="json">
{
    "en": {
        "balance": "Balance"
    }
}
</i18n>
