import { CategoriesType } from '@/types'

export const categoriesImages: Record<CategoriesType, string> = {
    popular: '',
    all_games: '',
    recommended: '',
    trending_now: '',
    new: '',
    random: '',
    '2_player': '/categories-imgs/2_player.png',
    '3d': '',
    action: '/categories-imgs/action.png',
    adventure: '/categories-imgs/adventure.png',
    arcade: '/categories-imgs/arcade.png',
    baby: '',
    bejeweled: '',
    boys: '/categories-imgs/boys.png',
    clicker: '',
    cooking: '',
    farming: '',
    girls: '/categories-imgs/girls.png',
    hypercasual: '/categories-imgs/hypercasual.png',
    io: '/categories-imgs/io.png',
    multiplayer: '',
    puzzle: '/categories-imgs/puzzle.png',
    racing: '/categories-imgs/racing.png',
    shooting: '',
    sports: '/categories-imgs/sports.png',
    stickman: '',
    soccer: '',
    social: '',
    top_picks: '',
    in_game_purchase: '',
    cards: '/categories-imgs/cards.png',
    kids: '/categories-imgs/kids.png',
    educational: '/categories-imgs/educational.png',
    quiz: '/categories-imgs/quiz.png',
    simulator: '/categories-imgs/simulator.png',
    strategy: '/categories-imgs/strategy.png',
    tabletop: '/categories-imgs/tabletop.png',
    match3: '/categories-imgs/match3.png',
    role: '/categories-imgs/role.png',
    casino: '/categories-imgs/casino.png',
    balloons: '/categories-imgs/balloons.png',
    economic: '/categories-imgs/economic.png',
    midcore: '/categories-imgs/midcore.png',
    horrors: '/categories-imgs/horrors.png',
    imitations: '/categories-imgs/imitations.png',
    applications: '/categories-imgs/applications.png',
    tests: '/categories-imgs/tests.png',
}
