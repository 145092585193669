<template>
    <div :class="$style.twoColContainer">
        <div :class="$style.content">
            <PlaygamaHeader />
            <slot />
        </div>
        <div :class="$style.rightSide">
            <slot name="rightSide" />
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import PlaygamaHeader from '@/components/PlaygamaHeader/PlaygamaHeader.vue'

export default defineComponent({
    name: 'TwoColumnsLayout',
    components: {
        PlaygamaHeader,
    },
})
</script>

<style module>
.twoColContainer {
    width: 100%;
    min-height: 100%;
    display: flex;
}

.content {
    flex-grow: 1;
    max-width: calc(100% - 368px);
    border-right: 1px solid var(--white-25);
    padding: var(--gap-large);
    box-sizing: border-box;
}

.rightSide {
    width: 368px;
    padding: var(--gap-large);
    box-sizing: border-box;
}

@media (--desktop-small) {
    .rightSide {
        width: 352px;
        padding-left: var(--gap-regular);
        padding-right: var(--gap-regular);
    }

    .content {
        max-width: calc(100% - 352px);
        padding-left: var(--gap-regular);
        padding-right: var(--gap-regular);
    }
}

@media (--tablet) {
    .content {
        max-width: 100%;
        border-right: none;
        padding: var(--gap-regular);
    }

    .rightSide {
        display: none;
    }
}

@media (--mobile) {
    .twoColContainer {
        flex-direction: column;
    }

    .content {
        width: 100%;
        max-width: 100%;
        border-right: none;
        padding: 20px var(--gap-regular);
    }

    .rightSide {
        display: none;
    }
}
</style>
