import type { PlaygamaLoggerEventType } from '@/types'

const { VUE_APP_ANALYTICS_ENDPOINT } = process.env
const ANALYTICS_PATH = `${VUE_APP_ANALYTICS_ENDPOINT}/api/v1/events`

export async function sendEvent(event: PlaygamaLoggerEventType) {
    try {
        await fetch(ANALYTICS_PATH, {
            method: 'post',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(event),
            credentials: 'include',
        })
    } catch (e) {
        // 🤷‍♂️
    }
}
