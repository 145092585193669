export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "spend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop! Spend"])},
        "coins": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("total")), " coins"])}
      }
    }
  })
}
