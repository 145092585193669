<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M14.255 22.56a.49.49 0 0 1-.44-.289l-2.801-6.476-3.486 3.276a.481.481 0 0 1-.808-.351V2.88a.48.48 0 0 1 .808-.35l11.52 10.75a.481.481 0 0 1-.287.831l-4.888.42 2.93 6.39a.48.48 0 0 1-.236.636l-2.113.96a.5.5 0 0 1-.199.043"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Clicker',
})
</script>
