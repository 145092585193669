/* eslint-disable max-len */
export const midcore = `
    <section class="textRegular">
        <h2 class="headerOne">Midcore Games — Play Online</h2>
        <p>Midcore games are the intersection of casual and hardcore games, providing an exciting balance of accessibility and depth. They arose in the modern era of gaming as a solution to appeal to the broad audience that seeks enjoyable gameplay without the commitment of significant time or the necessity of advanced skills. Originating from the mobile gaming revolution, midcore games quickly earned their popularity by providing the perfect blend of engaging gameplay mechanics with ease of play. They incorporate elements that are palatable to a large demographic, such as straightforward controls, appealing graphics, and enticing storylines that aren't too intrusive. Furthermore, they often allow for both quick gaming sessions and extended play, catering to diverse gamer needs. Casual gamers appreciate the depth and engagement they provide, while hardcore gamers value their simplicity and easiness as a refreshing break from intense gaming sessions.</p>
    </section>
    <section class="textRegular">
        <h2 class="headerOne">How to play Midcore Games</h2>
        <p>Playing midcore games is an enjoyable experience that falls somewhere in between casual and hardcore gaming. While the games in this category may vary in their specifics, several gameplay mechanics are commonly present. First off, midcore games often focus on strategy, requiring players to think ahead and plan their actions. Secondly, they offer progression systems that reward continuous play, making each session feel meaningful and productive. Thirdly, they often involve a multiplayer component, encouraging interactions among players. However, they usually have simpler controls than hardcore games, making them accessible even to beginners. Furthermore, while they might feature in-game purchases, they ensure that players can enjoy the full extent of the game without necessarily having to pay.</p>
        <ul>
            <li>Take a balanced approach: Don't rush, but don't be too relaxed either. The key to succeeding in midcore games is to strike the perfect balance between casual and hardcore gaming styles.</li>
            <li>Make use of progression systems: Regularly engage with the game to take full advantage of the progression systems that reward continuous play.</li>
            <li>Plan your strategy: Most midcore games require a thoughtful approach. Effectively plan your actions to gain an edge.</li>
            <li>Interact with other players: Use the multiplayer component of these games to learn from other players and enjoy cooperative or competitive gameplay.</li>
            <li>Enjoy the game: Lastly, remember that the core essence of midcore games is to provide an enjoyable gaming experience. So have fun playing!</li>
        </ul>
        <p>Dive in to discover this broad range of games that finely bridges the gap between casual and hardcore gaming, and appreciate the unique balance of depth and accessibility they provide.</p>
    </section>
`
