<template>
    <Modal @closeModal="handleClose" :isOpen="isOpen">
        <div class="title">{{ t('title') }}</div>
        <ContactForm
            :page="page"
            @submitted="handleClose"
        />
        <Button
            text
            :title="t('close')"
            size="s"
            :class="$style.closeBtn"
            @click="handleClose"
        />
    </Modal>
</template>

<script lang="ts" setup>
import { defineEmits, defineProps } from 'vue'
import { useI18n } from 'vue-i18n'
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import ContactForm from '@/components/ContactForm/index.vue'

type ReportProblemModalProps = {
    isOpen: boolean,
    page: string,
}

defineProps<ReportProblemModalProps>()
/* eslint-disable-next-line no-unused-vars */
const emit = defineEmits<{(e: 'closeReportModal'): void}>()

const { t } = useI18n()
function handleClose() {
    emit('closeReportModal')
}
</script>

<style module>
.modalContent {
    width: 100%;
    max-width: 728px;
}

.closeBtn {
    composes: accent from global;
    margin-top: var(--gap-regular);
    width: 100%;
}
</style>

<i18n lang="json">
{
    "en": {
        "title": "Report a problem",
        "close": "Close"
    }
}
</i18n>
