<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path fill="#fff" d="M10 .4C4.708.4.4 4.707.4 10s4.308 9.6 9.6 9.6c5.293 0 9.6-4.308 9.6-9.6 0-5.293-4.307-9.6-9.6-9.6M7.335 2.462 10 4.4l2.666-1.938a8 8 0 0 1 3.68 2.67L15.327 8.27l2.662 1.935a7.96 7.96 0 0 1-1.392 4.325h-3.306l-1.019 3.139a8 8 0 0 1-2.272.33c-.79 0-1.551-.118-2.271-.33l-1.02-3.138H3.404a7.96 7.96 0 0 1-1.394-4.326l2.664-1.936-1.02-3.136a8 8 0 0 1 3.68-2.67M10 6 6.214 8.75l1.447 4.45h4.679l1.446-4.45z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Soccer',
})
</script>
