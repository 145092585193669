/* eslint-disable max-len */
export const horrors = `
    <section class="textRegular">
        <h2 class="headerOne">Horror Games — Play Online</h2>
        <p>Delve into the heart-stopping world of online Horror Games where you can immerse yourself in dark, terrifying, and thrilling experiences. This genre encapsulates everything from supernatural suspense to psychological horror, creating intense and challenging adventures. Horror has been a popular game genre since the 1980s, with seminal titles like the Resident Evil series and the chilling Alone in the Dark setting high standards for atmosphere, story, and gameplay. These games bring your deepest fears to life and challenge you to navigate and survive in eerie, spooky, and often unpredictable environments populated by various monsters, spirits, and frightening situations. Players around the world have braved their way through these games, adding to the rich and varied landscape of the genre. Whether you're seeking pulse-pounding action or a chill down your spine, our collection of online Horror Games will have you avidly clicking and gasping in equal measure.</p>
    </section>
    <section class="textRegular">
        <h2 class="headerOne">How to Play Horror Games</h2>
        <p>The mechanics of playing horror games can vary widely based on the game itself. Some might involve puzzle-solving under pressure, navigating labyrinthine mazes, or even combating or evading terrifying creatures. You might play from a first-person perspective to add to the immersion or from a third-person view to add a strategic element. You could be armed with an array of weapons, magical abilities, or be left defenseless but for your cunning and quick reflexes. The single unifying mechanic is fear – using audios, visuals, and mechanics to induce fear and then challenging you to overcome it.</p>
        <ul>
            <li>Keep Calm: Your success in these games often relies on your ability to stay calm under pressure.</li>
            <li>Be Perceptive: Paying attention to your surroundings can help you avoid deadly surprises.</li>
            <li>Conserve Resources: Many horror games give you limited resources, so use them wisely.</li>
            <li>Plan Ahead: Try and anticipate what frights might be around the next corner and prepare accordingly.</li>
            <li>Immerse Yourself: Turn off the lights, put on your headphones and fully immerse yourself in the terrifying world of horror games.</li>
        </ul>
    </section>
`
