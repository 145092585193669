/* eslint-disable max-len */
export const quiz = `
    <section class="textRegular">
        <h2 class="headerOne">Quiz Games — Play Online</h2>
        <p>Enter the fascinating world of Quiz Games. Offering a wide variety of trivia and puzzles, quiz games have long been fostering curiosity and learning. These types of games have been popular since the inception of gaming itself. Trivia-based video games were some of the first to be developed in the early 1970s, such as "Who Wants to Be a Millionaire", and they have continued to evolve and diversify over the decades. There's a quiz game out there for nearly every subject you can think of, from geography and history to pop culture and sports. You can test your knowledge on almost anything or learn and discover something new. The games challenge accuracy, speed, and recall in a fun, interactive way. This genre is perfect for anyone who loves to test their intellect while enjoying some quality gaming time. Whether you're a competitive trivia lover or just someone who enjoys learning new facts, the quiz genre offers a unique blend of entertainment and educational value.</p>
    </section>
    <section class="textRegular">
        <h2 class="headerOne">How to Play Quiz Games</h2>
        <p>Quiz games are quite straightforward to play, making them accessible to players of all ages and skill levels. Typically, each game presents a series of questions within a specific theme or category. You might be tasked with identifying a geographical location, completing a famous quote or answering multiple-choice questions on any number of subjects. Some games might implement a time limit, adding a challenging element and testing both your recall speed and accuracy. The exciting thing about quiz games is that your score can be compared with others from around the world, urging you to improve and learn more. While the basic mechanics of quiz games are simple, they are varied enough to provide a new experience with every game.</p>
        <ul>
            <li>Read the question thoroughly before selecting your answer.</li>
            <li>Time is essential in some quiz games. Learn to think fast.</li>
            <li>Don’t worry about failing. Sometimes, incorrect answers are the best way to learn new facts.</li>
            <li>Regularly playing quiz games can significantly improve your knowledge in various fields. Make it a habit!</li>
            <li>Check worldwide leaderboards to see your progress and get inspired.</li>
        </ul>
    </section>
`
