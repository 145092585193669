/* eslint-disable max-len */
export const cards = `
    <section class="textRegular">
        <h2 class="headerOne">Card Games — Play Online</h2>
        <p>Step into the captivating world of online Card Games, a genre filled with strategy, skill, and a dash of luck. Card games have been a cornerstone of gaming since ancient times, dating back to the 9th-century Tang Dynasty, when they were primarily played with decorated paper cards. In the modern digital age, the breadth and variety of card games available online have skyrocketed, making it a beloved genre for millions globally. From traditional games like Poker, Solitaire, and Rummy to innovative spin-offs and collectible card-game sensations like Hearthstone, online card games offer endless entertainment for both casual gamers and hardcore strategists. These games not only challenge your mental agility but also foster a dynamic online community of players. So whether you're a fan of trick-taking games or strategic battles, there will certainly be a card game that fits your taste.</p>
    </section>
    <section class="textRegular">
        <h2 class="headerOne">How to Play Card Games Online</h2>
        <p>Playing card games online can vary significantly based on the game's rules and objectives, but the thrill of competing against others or trying to best your score remains constant. Most card games revolve around managing your hand of cards effectively to achieve specific goals. Online, these games often incorporate elements of strategy, timing, knowledge, and occasionally elements of RPGs or adventure games. Some card games may require you to eliminate your opponents, create specific card combinations, or deplete your hand before your opponents. Online card games may be turn-based, where you take turns with your opponent, or real-time, where actions happen continuously. The beauty of online card games is their diversity and the depth of their gameplay mechanics, promising something different with each game.</p>
        <ul>
            <li>Tip 1: Master the Rules — Each game has a unique set of rules. Spend time understanding these guidelines to improve your game-playing strategy and increase your chances of winning.</li>
            <li>Tip 2: Strategy is Key — Even in games where luck plays a part, a well-thought-out strategy can tip the scales in your favor.</li>
            <li>Tip 3: Practice — The more you play, the better you get. Use each game as a learning experience to hone your skills.</li>
            <li>Tip 4: Stay Calm — Card games can sometimes become intense. Keeping a cool mind can help you make better decisions and enjoy the game more.</li>
            <li>Tip 5: Enjoy the Game — Winning is enjoyable, but remember, the ultimate goal of playing is to have fun!</li>
        </ul>
    </section>
`
