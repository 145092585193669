<template>
    <div :class="$style.container" v-if="isAuthBannerOpen && isUserInfoLoaded && !isAuthorized">
        <Icon
            :class="$style.buttonClose"
            name="crossSlim"
            @click="handleClose"
        />
        <div :class="$style.content">
            <img :class="$style.imgCoins" alt="coins" src="/img/coins.png"/>
            <h2 class="headerOne">{{ t('header') }}</h2>
            <div class="captionLight">{{ t('paragraph') }}</div>
            <Button size="s" :title="t('buttonText')" @click="handleOpenModal"/>
        </div>
        <img :class="$style.imgFennec" alt="coins" src="/img/fennec-with-coin.png"/>
    </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon/index.vue'
import { useAppStore } from '@/store/app-store'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/user-store'

const appStore = useAppStore()
const userStore = useUserStore()
const { setIsAuthBannerOpen, setIsAuthUserModalOpen } = appStore
const { isAuthorized, isUserInfoLoaded } = storeToRefs(userStore)
const {
    isAuthBannerOpen,
} = storeToRefs(appStore)

const { t } = useI18n()
function handleClose() {
    setIsAuthBannerOpen(false)
}
function handleOpenModal() {
    setIsAuthUserModalOpen(true)
}
</script>
<style module>
.container {
   width: 360px;
   border-radius: var(--radius-large);
   background: linear-gradient(0deg, rgba(3, 0, 20) 0%, rgba(50, 41, 95) 100%);
   padding: var(--gap-large);
   position: fixed;
   bottom: 24px;
   right: 24px;
   z-index: 3;
}
.content {
    display: flex;
    flex-direction: column;
    gap: var(--gap-regular);
    width: 215px;
    position: relative;
    z-index: 1;
}
.imgCoins {
    height: 53px;
    width: 56px;
}
.imgFennec {
    height: 285px;
    top: -7px;
    right: 0;
    position: absolute;
    border-bottom-right-radius: var(--radius-large)
}
.buttonClose {
   position: absolute;
   z-index: 1;
   top: 14px;
   right: 14px;
   opacity: 0.5;
}
@media (--mobile) {
    .container {
        width: auto;
        position: relative;
        bottom: 0;
        right: 0;
    }
    .imgFennec {
        height: 284px;
    }
}
</style>
<i18n lang="json">
{
    "en": {
        "header": "Play, Earn, and Get Super Drops on Playgama!",
        "paragraph": "Play games, tackle tasks, earn coins, and spend them on real rewards",
        "buttonText": "Sign Up Now & Get 999 Coins",
        "closeButtonText": "× Close"
    }
}
</i18n>
