<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="21" fill="none"><path fill="#fff" d="M7 .6A2.41 2.41 0 0 0 4.6 3v.949c-.723.569-1.287 1.338-1.502 2.251H3a1.6 1.6 0 0 0-1.6 1.6v.222A1.59 1.59 0 0 0 .6 9.4v.8a.8.8 0 0 0 .8.8v7.2c0 1.316 1.084 2.4 2.4 2.4h6.4c1.316 0 2.4-1.084 2.4-2.4v-1.465a.8.8 0 0 0 0-.261v-2.94a.8.8 0 0 0 0-.26V11a.8.8 0 0 0 .8-.8v-.8c0-.591-.324-1.101-.8-1.378V7.8A1.6 1.6 0 0 0 11 6.2h-.1c-.215-.913-.779-1.682-1.501-2.251V3A2.41 2.41 0 0 0 7 .6m0 1.6c.452 0 .8.348.8.8v1.244a.8.8 0 0 0 .398.692c.49.286.866.73 1.058 1.264H4.743a2.4 2.4 0 0 1 1.058-1.264.8.8 0 0 0 .399-.692V3c0-.452.348-.8.8-.8M3 11h8v1.6h-.8a.8.8 0 1 0 0 1.6h.8v1.6h-.8a.8.8 0 1 0 0 1.6h.8v.8c0 .452-.348.8-.8.8H3.8a.787.787 0 0 1-.8-.8z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Baby',
})
</script>
