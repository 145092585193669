/* eslint-disable max-len */
export const match3 = `
    <section class="textRegular">
        <h2 class="headerOne">Match3 Games — Play Online</h2>
        <p>Experience the thrill and excitement with our vast collection of match3 games available for online play. This genre has managed to captivate players' hearts for decades, serving as a staple in the gaming world. The roots of match3 can be traced back to 1982, with the release of Tetris. Tetris set a foundation that many future games would base their mechanics and gameplay around. However, the match3 genre truly came to life in the late '90s, reaching mainstream success with games like Bejeweled and Candy Crush saga, which still maintain a significant player base today. Our selection of match3 games offers an experience for every type of gamer, whether you enjoy a challenge, prefer relaxed gameplay, or fancy colourful artsy game design. Delve into this entrancing genre and test your tactical ability and speed today.</p>
    </section>
    <section class="textRegular">
        <h2 class="headerOne">How to play Match3 Games</h2>
        <p>Engaging in match3 games requires strategic thinking and quick decision-making. Regardless of the match3 game you choose, they share similar mechanics. Primarily, you're presented with a grid filled with a variety of items. The main goal is to align three or more identical items, either horizontally or vertically, to earn points and progress through levels. Achieving this usually results in the matched items disappearing, and new ones dropping in to take their place. However, each match3 game presents its own unique twist on this core mechanic. Some games introduce special items with power-ups, while others add time limit, launch new challenges, and more. Mastering these games requires understanding the game's core mechanics and exercising your foresight, critical thinking and adaptability.</p>
        <ul>
            <li>Always try to make matches at the bottom of the board. This increases the potential for chain reactions, scoring you extra points.</li>
            <li>Look out for potential four or five-item matches. These usually create powerful special pieces that can clear large portions of the board.</li>
            <li>Each game has different power-ups. Be sure to understand how each one works to maximize their effectiveness.</li>
            <li>Don't rush — even in timed levels, it's better to take a moment and look for the best possible move.</li>
            <li>Understand the objective of the level – some require you to reach a certain score, others to match specific types of items, etc. Play accordingly.</li>
        </ul>
    </section>
`
