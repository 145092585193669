<template>
    <li :class="$style.loginBanner" v-if="isLoginBanner">
        <LoginBanner/>
    </li>
    <GameCardsList
        v-if="category.games"
        component="li"
        :appearance="cardAppearance"
        :title="t(category.name)"
        :icon="category.icon"
        :link="category.href"
        :class="$style.category"
        :games="category.games"
        isMore
    />
    <li v-if="isAdAllowed && isAd">
        <h2 class="adHeader" :class="$style.adHeader">Ads</h2>
        <AdBlock :class="$style.adBlock" />
    </li>
    <About v-if="isAbout" :class="$style.about" />
    <End v-if="isEnd" :class="$style.end" />
</template>

<script setup lang="ts">
import {
    defineProps,
    ref,
} from 'vue'
import { useI18n } from 'vue-i18n'
import type { CategoryListType } from '@/types'
import { categoriesTranslation } from '@/utils/translations/categories'
import GameCardsList from '@/components/GameCardsList.vue'
import AdBlock from '@/components/AdBlock.vue'
import About from '@/components/About/index.vue'
import End from '@/components/End.vue'
import LoginBanner from '@/components/LoginBanner/LoginBanner.vue'

interface ContentItemProps {
    isAd?: boolean,
    isAbout?: boolean,
    isEnd?: boolean,
    isLoginBanner?: boolean,
    category: CategoryListType,
    cardAppearance: 'big' | 'small' | 'medium' | 'highlighted',
}
defineProps<ContentItemProps>()

const { t } = useI18n({
    messages: {
        ...categoriesTranslation,
    },
})

const isAdAllowed = ref(false)

</script>
<style module>
.about, .end {
    margin-top: var(--gap-large);
}

.category {
    margin-top: var(--gap-large);
    margin-right: var(--gap-negative-large);
}

.category:first-child {
    margin-top: 0;
}

.adHeader {
    margin-top: var(--gap-large);
}

.adBlock {
    margin-top: var(--gap-regular);
    width: 728px;
    height: 90px;
    max-width: 100%;
}

@media (--desktop-small) {
    .category {
        margin-right: var(--gap-negative-regular);
    }
}
@media (--mobile) {
    .loginBanner {
        margin-top: var(--gap-large);
    }
}
</style>
