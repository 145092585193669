<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 151 150"><path fill="inherit" fill-opacity=".2" d="M47.524 50.497C51.51 35.619 66.803 26.79 81.68 30.777c7.802 2.09 13.927 7.297 17.426 13.917l4.523-3.518.547 13.396-12.848-3.83 4.323-3.363c-2.894-5.925-8.227-10.621-15.082-12.458-12.568-3.368-25.533 4.117-28.9 16.686a23.4 23.4 0 0 0-.704 4.104l-4.141-1.648a28 28 0 0 1 .7-3.567m77.976 64.489v-45.03c0-2.87-2.316-5.196-5.172-5.196H30.672c-2.857 0-5.172 2.326-5.172 5.196v45.03c0 2.87 2.316 5.196 5.172 5.196h89.656c2.856 0 5.172-2.326 5.172-5.196m-11.207-16.453V86.408a2.593 2.593 0 0 1 2.586-2.598 2.593 2.593 0 0 1 2.586 2.598v12.124a2.593 2.593 0 0 1-2.586 2.598 2.594 2.594 0 0 1-2.586-2.598"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Rotate',
})
</script>
