<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" fill="none"><path fill="#fff" d="M6 .4C2.908.4.4 2.856.4 5.886c0 2.763 2.088 6.558 4.8 7.208v.106a.8.8 0 0 0 1.6 0v-.106c2.713-.65 4.8-4.445 4.8-7.208C11.6 2.856 9.093.4 6 .4m8.8 3.2c-.664 0-1.297.136-1.873.38.174.608.273 1.245.273 1.906 0 1.868-.801 4.234-2.187 6.062.722 1.302 1.778 2.378 2.987 2.724v.128a.8.8 0 0 0 1.6 0v-.122c2.27-.647 4-3.9 4-6.278a4.8 4.8 0 0 0-4.8-4.8M5.2 14.8a.8.8 0 1 0 0 1.6.8.8 0 0 0 0-1.6m8.8 1.6a.8.8 0 1 0 0 1.6.8.8 0 0 0 0-1.6m-8 .8a.8.8 0 1 0 0 1.6.8.8 0 0 0 0-1.6m8.8 1.6a.8.8 0 1 0 0 1.6.8.8 0 0 0 0-1.6m-9.6.8a.8.8 0 1 0 0 1.6.8.8 0 0 0 0-1.6"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Balloons',
})
</script>
