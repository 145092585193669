/* eslint-disable max-len */
export const educational = `
    <section class="textRegular">
        <h2 class="headerOne">Educational Games — Play Online</h2>
        <p>If you want to learn something new while having fun, educational games are the way to go. This genre integrates learning concepts into traditional gameplay, providing a platform for both children and adults to boost their knowledge and skills. From science to programming, language skills to mathematics, educational games have a broad spectrum. Dating back to the 70s, classics like The Oregon Trail and Reader Rabbit gave birth to this genre. Now, with advancements in technology, they have evolved into more sophisticated forms, ranging from interactive puzzle games to life simulations. These high-quality, HTML5 games provide a compelling way for people to learn new things in an engaging and relaxed environment.</p>
    </section>
    <section class="textRegular">
        <h2 class="headerOne">How to Play Educational Games</h2>
        <p>The core mechanics of educational games involve problem-solving and critical thinking. Guided by instructional design principles, they are intended to assess and enhance a player's knowledge while offering plenty of fun. Most games are created around a learning goal, often aligned with academic standards. Players navigate through a series of challenges or tasks that require the application of learned concepts, often in innovative ways. Successfully completing tasks rewards players, thus providing motivation and promoting a sense of achievement. Educational games take on numerous forms, from quizzes and puzzles to adventure quests and simulations, each designed to provide a unique learning experience.</p>
        <ul>
            <li>Understand the objectives: Every educational game is designed with certain learning objectives. Before you start playing, read what these are. This will help you understand what you should focus on while playing.</li>
            <li>Take your time: Don’t rush through the levels. Take your time to understand and absorb the learning material. The aim of educational games is not just to win, but to learn.</li>
            <li>Make notes: If there are complex concepts that you need to grasp, don’t hesitate to make notes. This will reinforce your learning and you can refer to them anytime.</li>
            <li>Apply knowledge: Educational games are most effective when you apply the knowledge gained in real-world scenarios. This not only strengthens your learning but also makes it more relevant.</li>
            <li>Repetition: Revisit the games again and again to reinforce the concepts. Like any learning method, repetition is key for retaining information.</li>
        </ul>
    </section>
`
