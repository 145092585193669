<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M9.134 9.614v-5.28a.95.95 0 0 0-.476-.842.95.95 0 0 0-.968 0 .95.95 0 0 0-.476.842v2.003L4.053 3.176a.959.959 0 0 0-1.622.421.96.96 0 0 0 .264.936l3.161 3.161H3.854a.95.95 0 0 0-.842.477.95.95 0 0 0 0 .967c.174.3.495.482.842.476zm10.051 0a.95.95 0 0 0 .842-.476.95.95 0 0 0 0-.967.95.95 0 0 0-.842-.477h-2.002l3.161-3.161a.959.959 0 0 0-.422-1.622.96.96 0 0 0-.936.265l-3.16 3.16V4.335a.96.96 0 0 0-.477-.842.95.95 0 0 0-.967 0 .95.95 0 0 0-.477.842v5.28zM3.388 21.12a.97.97 0 0 0 .66-.29l3.161-3.162v2.002a.96.96 0 0 0 .975.973.96.96 0 0 0 .945-.973v-5.28H3.85a.95.95 0 0 0-.842.477.95.95 0 0 0 0 .967c.175.3.495.482.842.476h2.003L2.69 19.472a.96.96 0 0 0-.215 1.063.96.96 0 0 0 .913.585m16.292 0a.96.96 0 0 0 .889-.6.96.96 0 0 0-.22-1.048l-3.161-3.161h2.002a.96.96 0 0 0 .842-.476.95.95 0 0 0 0-.968.95.95 0 0 0-.842-.476h-5.28v5.28a.96.96 0 0 0 .975.973.96.96 0 0 0 .945-.973v-2.003l3.162 3.162c.181.185.429.29.688.29"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ExitFullscreen',
})
</script>
