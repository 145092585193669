<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" fill="none"><rect width="24" height="5" fill="#fff" rx="1"/><rect width="24" height="5" y="16" fill="#fff" rx="1"/><rect width="24" height="5" y="8" fill="#fff" rx="1"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Burger',
})
</script>
