<template>
    <section :class="$style.profile">
        <div :class="$style.header">
            <div class="headerOne">{{ t('welcome', { name: user!.firstName }) }}</div>
            <Button
                :class="$style.logoutBtn"
                :title="t('logout')"
                text
                size="m"
                icon="arrowOut"
                @click="logoutUser"
            />
        </div>
        <div :class="$style.dashboard">
            <div :class="$style.leftSideDashboard">
                <div :class="$style.leftSideContent">
                    <Balance
                        :fennecs="fennecsLabel"
                        :class="$style.desktopOnly"
                    />
                    <ProfileData
                        v-if="userSocials"
                        :avatar="user!.avatar"
                        :firstName="user!.firstName"
                        :lastName="user!.lastName"
                        :socials="userSocials"
                    />
                    <UserStatistics
                        v-if="user!.currentStreak !== undefined"
                        :gamesPlayed="user!.gamesPlayed || 0"
                        :currentStreak="user!.currentStreak"
                        :createdAt="user!.createdAt"
                    />
                </div>
            </div>
            <div v-if="tasksByTags?.length || shopItems?.length" :class="$style.rightSideDashboard">
                <UserTasks
                    v-if="tasksByTags?.length"
                    :tasksByTags="tasksByTags"
                    @openClaimModal="openClaimModal"
                />
                <Shopwindow
                    v-if="shopItems?.length"
                    :fennecs="user!.fennecs"
                    :fennecsLabel="fennecsLabel"
                    :shopItems="shopItems"
                    :isBuyingDisabled="isBuyingDisabled"
                    @buyItem="buyItem"
                />
            </div>
        </div>
        <div v-if="popularGames?.length" :class="$style.recentGames">
            <GameCardsList
                appearance="small"
                :title="gamesTitle"
                :games="games"
            />
        </div>
    </section>
</template>
<script lang="ts" setup>
import {
    computed,
    defineEmits,
    defineProps,
} from 'vue'
import { useI18n } from 'vue-i18n'
import type {
    SimplifiedGame,
    ShopItem,
    Task,
    TasksByTag,
    User,
    UserSocialKeys,
    SocialType,
    SocialTag,
    SocialsName,
} from '@/types'
import { SOCIALS } from '@/types'
import Button from '@/components/Button.vue'
import GameCardsList from '@/components/GameCardsList.vue'
import Shopwindow from './components/Shopwindow.vue'
import Balance from './components/Balance.vue'
import ProfileData from './components/ProfileData.vue'
import UserStatistics from './components/UserStatistics.vue'
import UserTasks from './components/UserTasks.vue'

const MAX_VIEWED_GAMES = 12

type SocialSerializeType = {
    key: UserSocialKeys,
    type: SocialType,
    tag: SocialTag,
    isSupport: boolean,
    name: SocialsName,
    // eslint-disable-next-line no-unused-vars
    validate: (value: string) => boolean,
}
const userSocialsConfig: Record<SocialsName, SocialSerializeType> = {
    telegram: {
        key: 'telegramAcc',
        type: 'account',
        tag: 'telegram',
        isSupport: false,
        name: 'telegram',
        validate: (value: string) => Boolean(value.match(/^[a-z0-9_]{5,32}$/i)),
    },
    ton: {
        key: 'tonWallet',
        type: 'wallet',
        tag: 'tonWallet',
        isSupport: true,
        name: 'ton',
        validate: (value: string) => Boolean(value.match(/^(\S{48})((\S){18,19})?$/i)),
    },
}

type UserCabinetProps = {
    user: User,
    fennecsLabel: string,
    shopItems: ShopItem[] | null,
    tasksByTags: TasksByTag[] | null,
    popularGames: SimplifiedGame[],
    viewedGames: SimplifiedGame[],
    isBuyingDisabled: boolean,
}

const props = defineProps<UserCabinetProps>()

/* eslint-disable no-unused-vars */
const emit = defineEmits<{(e: 'buyItem', item: ShopItem): void
    (e: 'openClaimModal', task: Task): void
    (e: 'logoutUser'): void
}>()

const { t } = useI18n()

const gamesTitle = computed(() => {
    if (props.viewedGames.length > 0) {
        return t('recentGames')
    }
    return t('popular')
})

const games = computed(() => {
    if (props.viewedGames.length > 0) {
        const viewedGamesCopy: SimplifiedGame[] = [...props.viewedGames]
        viewedGamesCopy.reverse()
        if (viewedGamesCopy.length > MAX_VIEWED_GAMES) {
            return viewedGamesCopy.slice(0, MAX_VIEWED_GAMES)
        }
        return viewedGamesCopy
    }
    return props.popularGames
})

const userSocials = computed(() => SOCIALS.map((social) => {
    const curr = userSocialsConfig[social]
    return {
        name: social,
        account: props.user[curr.key],
        type: curr.type,
        tag: t(curr.tag),
        key: curr.key,
        isSupport: curr.isSupport,
        validate: curr.validate,
    }
}))

async function logoutUser() {
    emit('logoutUser')
}

function buyItem(item: ShopItem) {
    emit('buyItem', item)
}

function openClaimModal(task: Task) {
    emit('openClaimModal', task)
}

</script>
<style module>
.dashboard {
    margin-top: var(--gap-large);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoutBtn {
    display: inline-flex;
}

.recentGames {
    margin-top: var(--gap-regular);
}

.dashboard {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: var(--gap-regular);
}

.leftSideDashboard {
    flex: 0 0 240px;
    width: 240px;
}

.leftSideContent {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    gap: var(--gap-regular);
}

.rightSideDashboard {
    display: flex;
    flex-direction: column;
    gap: var(--gap-regular);
    flex-grow: 2;
}

.dashboardCard {
    width: 100%;
    box-sizing: border-box;
    padding: var(--gap-large);
    border: 1px solid var(--white-25);
    border-radius: var(--radius-large);
}

.recentGames {
    width: 100%;
    box-sizing: border-box;
    padding: var(--gap-large);
    border: 1px solid var(--white-25);
    border-radius: var(--radius-large);
}

@media (--mobile) {
    .dashboard {
        flex-direction: column;
    }

    .leftSideDashboard,
    .rightSideDashboard {
        width: 100%;
    }

    .desktopOnly {
        display: none;
    }
}
</style>
<i18n lang="json">
{
    "en": {
        "welcome": "Welcome, {name}!",
        "recentGames": "Games Recently Played",
        "popular": "Popular Games",
        "logout": "Log out",
        "telegram": "Telegram",
        "tonWallet": "Wallet"
    }
}
</i18n>
