<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="m14.62 2.4-9.063 9.063s1.281-.116 1.759.362.084 3.603.67 4.19c.587.585 3.692.172 4.138.618.445.445.414 1.81.414 1.81L21.6 9.382zM3.795 16.014l-1.317 3.731.001.002a1.4 1.4 0 0 0-.08.458 1.396 1.396 0 0 0 1.854 1.315l.001.002.047-.017 3.684-1.3-2.095-2.096z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Kids',
})
</script>
