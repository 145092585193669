<template>
    <span />
</template>
<script lang="ts" setup>
import {
    onBeforeUnmount, onMounted, ref,
} from 'vue'
import { useAdsStore } from '@/store/ads-store'
import { useAppStore } from '@/store/app-store'
import type { Banner } from '@/modules/adv/banner'

const STICKY_REFRESH_TIMEOUT = 2 * 60 // in seconds

const currentStickyBanner = ref<Banner>()
const adsStore = useAdsStore()
const appStore = useAppStore()

function stickyRecalculateBanner(orientation: ScreenOrientation) {
    if (orientation.type === 'portrait-primary' || orientation.type === 'portrait-secondary') {
        // we have to wait before requesting, because an event triggers earlier then changes take an effect
        setTimeout(async () => {
            try {
                if (!adsStore.adMediator) return
                currentStickyBanner.value = await adsStore.adMediator.preparePageAd({
                    type: 'sticky_portrait',
                    refresh: STICKY_REFRESH_TIMEOUT,
                })
            } catch (e) {
                if (!e) {
                    // everything is fine. Banner is empty
                } else {
                    console.info('Error in sticky banner', e)
                }
            }
        }, 50)
    } else {
        currentStickyBanner.value?.destroy()
        currentStickyBanner.value = undefined
    }
}

function recalculateStickyBanner(event: ScreenOrientationEventMap['change']) {
    stickyRecalculateBanner(event.target as ScreenOrientation)
}

async function initStickyAds() {
    if (appStore.platform !== 'desktop') {
        window.screen.orientation.addEventListener('change', recalculateStickyBanner)
        stickyRecalculateBanner(window.screen.orientation)
    }
}
onMounted(() => {
    initStickyAds()
})
onBeforeUnmount(() => {
    window.screen.orientation.removeEventListener('change', recalculateStickyBanner)
    currentStickyBanner.value?.destroy()
    currentStickyBanner.value = undefined
})
</script>
