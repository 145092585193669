<template>
    <div :class="styles.gameDetails">
        <template v-if="game">
            <h1 itemprop="name">{{ game.title }}</h1>
            <img
                :alt="game.title"
                :src="game.preview"
                :class="styles.img"
                :srcset="game.preview"
                width="240"
                height="180"
                itemprop="image"
            />
            <div :class="$style.buttonsContainer">
                <template v-if="isUserInfoLoaded && !isAuthorized">
                    <Button
                        :title="t('playNoAuth')"
                        size="l"
                        backgroundColorName="black"
                        @click="handlePlay"
                    />
                    <Button
                        :title="t('playAndEarn')"
                        size="l"
                        @click="handlePlayAndEarn"
                    />
                </template>
                <template v-else>
                    <Button
                        :title="t('play')"
                        size="l"
                        @click="handlePlay"
                    />
                </template>
            </div>

            <div :class="styles.desc">
                <div :class="styles.mainDetails">
                    <p class="headerTwo">{{ t('about', { game: game.title }) }}</p>
                    <p :class="styles.text" itemprop="description">{{ game.description }}</p>
                    <template v-if="game.instruction">
                        <p class="subheader">{{ t('instruction') }}</p>
                        <p :class="styles.text" itemprop="gameTip">{{ game.instruction }}</p>
                    </template>
                </div>
                <p :class="[styles.specs, 'subheader']">{{ t('specs') }}</p>
                <ul :class="[styles.techDetails, styles.text]">
                    <li v-if="platforms" :class="styles.specItem" itemprop="gamePlatform">
                        <span :class="styles.detailGroup">
                            {{ t('platforms') }}
                        </span>
                        {{ platforms }}
                    </li>
                    <li v-if="game?.tags?.length"  :class="styles.specItem" itemprop="keywords">
                        <span :class="styles.detailGroup">{{ t('tags') }}</span>{{ game.tags.join(', ') }}
                    </li>
                    <li  :class="styles.specItem">
                        <span :class="styles.detailGroup">{{ t('orientation') }}</span>{{ t(game.orientation) }}
                    </li>
                </ul>
            </div>
        </template>
    </div>
</template>

<script lang="ts" setup>
import {
    defineEmits,
    useCssModule,
    defineProps,
    computed,
} from 'vue'
import { useI18n } from 'vue-i18n'
import Button from '@/components/Button.vue'
import { GameType } from '@/types'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/user-store'
import { useAppStore } from '@/store/app-store'

const { t } = useI18n()
const styles = useCssModule()
const userStore = useUserStore()
const { setIsAuthUserModalOpen } = useAppStore()

const { isAuthorized, isUserInfoLoaded } = storeToRefs(userStore)

interface GameDetailsModalProps {
    game?: GameType
}

const props = defineProps<GameDetailsModalProps>()

/* eslint-disable no-unused-vars */
/* eslint-disable no-spaced-func */
/* eslint-disable func-call-spacing */
const emit = defineEmits<{
    (e: 'startPlayingGame'): void
}>()

function handlePlay() {
    emit('startPlayingGame')
}

function handlePlayAndEarn() {
    setIsAuthUserModalOpen(true)
}

const platforms = computed(() => {
    /* eslint-disable-next-line camelcase  */
    const { is_ios, is_android, is_desktop } = props.game || {}
    /* eslint-disable-next-line camelcase  */
    const platformsValues = { ios: is_ios, android: is_android, desktop: is_desktop }
    const res = Object.entries(platformsValues)
        .filter(([, value]) => value)
        .map(([key]) => t(key))
        .join(', ')
    return res
})
</script>

<style module>
.gameDetails {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    max-width: 728px;
    gap: var(--gap-regular);
    align-items: center;
    padding: var(--gap-xlarge);
    box-sizing: border-box;
    background-color: var(--black-background);
    border-radius: var(--radius-regular);
    box-shadow: 0px 0px 48px 0px var(--shadow);
    overflow: hidden;
}

.desc {
    display: flex;
    gap: var(--gap-regular);
    width: calc(100% + var(--gap-xlarge) + var(--gap-xlarge));
    margin: 0 var(--gap-negative-xlarge) var(--gap-negative-xlarge);
    padding: var(--gap-large) var(--gap-regular);
    box-sizing: border-box;
    background-color: var(--black);
}

.mainDetails {
    width: 70%;
}

.mainDetails :global .headerTwo,
.mainDetails :global .subheader {
    margin-bottom: var(--gap-regular);
}

.mainDetails :global .textRegular {
    color: var(--white-50);
    margin-bottom: var(--gap-small);
}

.mainDetails :global .textRegular:last-child {
    margin-bottom: 0;
}

.specs {
    display: none;
}

.techDetails {
    width:30%;
}

.buttonsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-regular);
  justify-content: center;
}

.specItem {
    display: flex;
    gap: var(--gap-small);
    justify-content: space-between;
    align-items: baseline;
    padding: var(--gap-small) 0;
    border-bottom: 1px solid var(--white-25);
    text-align: right;
    white-space: break-spaces;
}

.specItem:first-child {
    padding-top: 0;
}

.specItem:last-child {
    padding-bottom: 0;
    border-bottom: none;
}

.detailGroup {
    color: var(--white-50);
}

.img {
    object-fit: cover;
    border-radius: var(--radius-regular);
}

/*
*   нельзя корректно настраивать разные глобальные классы css modules
*   длы разных media query, поэтому тут вручную определяем стили текста
*/
.text {
    /* textRegular */
    font-family: var(--font-secondary);
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-regular);
    white-space: break-spaces;
}

@media (--mobile) {
    .desc {
        display: block;
    }

    .specs {
        display: block;
        margin: var(--gap-regular) 0 var(--gap-small);
    }

    .mainDetails, .techDetails {
        width: 100%;
    }

    .text {
        /* captionLight */
        font-family: var(--font-secondary);
        font-size: var(--font-size-small);
        font-weight: var(--font-weight-light);
        line-height: var(--line-height-small);
    }
}
</style>
<i18n lang="json">
{
    "en": {
        "play": "Play now",
        "playNoAuth": "Just Play",
        "playAndEarn": "Play & Earn",
        "about": "About {game}",
        "instruction": "Instruction",
        "desktop": "Desktop",
        "mobile": "Mobile",
        "android": "Android",
        "ios": "iOS",
        "landscape": "Landscape",
        "portrait": "Portrait",
        "platforms": "Platforms",
        "orientation": "Orientation",
        "tags": "Tags",
        "specs": "Specs"
    }
}
</i18n>
