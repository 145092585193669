<template>
    <DashboardCard>
        <ul :class="$style.tasks">
            <li v-for="tagData in tasksByTags" :key="tagData.tag">
                <DashboardTitle>{{ t(tagData.tag) }}</DashboardTitle>
                <UserTask
                    v-for="task in tagData.tasks"
                    :key="task._id"
                    :task="task"
                    @openClaim="openClaimModal(task)"
                />
            </li>
        </ul>
    </DashboardCard>
</template>
<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import { useI18n } from 'vue-i18n'
import type { Task, TasksByTag } from '@/types'
import UserTask from '@/components/UserCabinet/components/UserTask.vue'
import DashboardCard from './DashboardCard.vue'
import DashboardTitle from './DashboardTitle.vue'

type UserTasksProps = {
    tasksByTags: TasksByTag[]
}

defineProps<UserTasksProps>()
/* eslint-disable no-unused-vars */
const emit = defineEmits<{(e: 'openClaimModal', task: Task): void}>()

const { t } = useI18n()

function openClaimModal(task: Task) {
    emit('openClaimModal', task)
}
</script>

<style module>

.tasks {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xlarge);
}
</style>

<i18n lang="json">
{
    "en": {
        "daily": "Daily",
        "social": "Social",
        "playGames": "Play Games"
    }
}
</i18n>
