<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M12 1.6a2.4 2.4 0 1 0 0 4.8 2.4 2.4 0 0 0 0-4.8m-.642 1.916h1.284a.642.642 0 1 1 0 1.284h-1.284a.642.642 0 1 1 0-1.284M16.8 4.8a.8.8 0 0 0-.647.33l-.003.004-.012.018-1.222 1.68 1.295.94 1.236-1.702A.8.8 0 0 0 16.8 4.8M10.59 8c-.908 0-1.742.515-2.148 1.327l-1.157 2.315A.8.8 0 0 0 7.2 12v3.2a.8.8 0 1 0 1.6 0v-3.011l.8-1.6v10.184a.827.827 0 0 0 1.652.055l.35-5.256a.4.4 0 0 1 .797.001l.35 5.255a.827.827 0 0 0 1.651-.055V10.59l.8 1.6v3.01a.8.8 0 1 0 1.6 0V12a.8.8 0 0 0-.084-.358l-1.158-2.315A2.4 2.4 0 0 0 13.411 8z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Action',
})
</script>
