<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M12 .056a.49.49 0 0 0-.39.188l-2.128 2.94L6.987 1.52a.48.48 0 0 0-.732.515l.863 3.418.096.786h9.661l.111-.784.763-3.431a.48.48 0 0 0-.735-.504L14.52 3.184 12.39.244A.48.48 0 0 0 12 .056M7.2 7.2c-.48.96-.48 6.24-1.423 6.703 0 .735 1.798 1.243 3.823 1.457-.841 2.21-6.937 2.456-7.2 6.72h19.2c-.262-4.264-6.358-4.51-7.2-6.72 2.085-.21 3.962-.938 3.962-1.49-1.082-.43-1.082-5.71-1.562-6.67z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Strategy',
})
</script>
