<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M11.99 1.6A2.4 2.4 0 0 0 9.6 4c0 .748.345 1.323.714 1.886A.732.732 0 0 1 9.61 7.01a67 67 0 0 1-3.668-.575l-.002.002A1.6 1.6 0 0 0 4 8v3.86c.001.728.806 1.18 1.418.786.587-.379 1.197-.71 2.01-.636 1.004.091 1.883.84 2.109 1.822A2.405 2.405 0 0 1 7.2 16.8c-.696 0-1.242-.3-1.769-.64A.926.926 0 0 0 4 16.937v1.917c0 1.234.716 1.947 1.6 1.947h12.8a1.6 1.6 0 0 0 1.6-1.6V8a1.6 1.6 0 0 0-1.937-1.562l-.004-.002s-1.845.35-3.668.575a.733.733 0 0 1-.702-1.13c.406-.619.782-1.255.7-2.117-.094-1-.843-1.876-1.822-2.1a2.5 2.5 0 0 0-.578-.064"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Puzzle',
})
</script>
