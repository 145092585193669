<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" fill-opacity="inherit" d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20m0 18a8 8 0 1 1 8-8 8.01 8.01 0 0 1-8 8m.8-11.447h-1.6V11.2H8.553v1.6H11.2v2.647h1.6V12.8h2.647v-1.6H12.8z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Add',
})
</script>
