<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M12 2.4c-5.293 0-9.6 4.306-9.6 9.6s4.307 9.6 9.6 9.6c5.294 0 9.6-4.306 9.6-9.6S17.294 2.4 12 2.4m0 1.614v.002c1.155 0 2.253.235 3.244.678.726.53 1.003 1.913 1.766 2.623.779.726 1.611.95 2.415 1.731l.003-.003q.229.57.37 1.182a.61.61 0 0 1-.683.732l-3.42-.487A4.005 4.005 0 0 0 12 8a4.005 4.005 0 0 0-3.693 2.47l-3.42.49a.61.61 0 0 1-.683-.735 8 8 0 0 1 .368-1.18l.003.003c.804-.78 1.637-1.004 2.416-1.73.763-.711 1.04-2.095 1.766-2.624A7.95 7.95 0 0 1 12 4.014M12 9.6c1.324 0 2.4 1.077 2.4 2.4s-1.076 2.4-2.4 2.4A2.403 2.403 0 0 1 9.6 12c0-1.323 1.077-2.4 2.4-2.4m0 1.6a.8.8 0 1 0 .001 1.6.8.8 0 0 0 0-1.6m6.28 2.714c.746-.093 1.326.665 1.019 1.35a8.04 8.04 0 0 1-4.055 4.044c-.813.362-1.703-.32-1.539-1.195l.522-2.793a4 4 0 0 0 1.042-1.03zm-12.56.002 3.012.376c.283.403.634.753 1.042 1.028l.522 2.793c.164.875-.727 1.559-1.54 1.196a8.04 8.04 0 0 1-4.054-4.043c-.307-.687.273-1.444 1.019-1.35"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Racing',
})
</script>
