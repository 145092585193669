<template>
    <Modal @closeModal="handleAction('close')" :isOpen="isOpen">
        <div :class="$style.content">
            <img
                v-if="options.imgSrc"
                :class="$style.coinsImg"
                :src="options.imgSrc"
                :alt="options.imgAlt"
            >
            <div v-if="options.header" :class="$style.bonusAmount">{{ options.header }}</div>
            <div :class="['headerOne', $style.title]">{{ options.title }}</div>
            <div :class="['subheader', $style.supportingText]">{{ options.subtitle }}</div>
            <Button
                :title="options.buttonText"
                size="m"
                :class="$style.closeBtn"
                @click="handleAction(options.buttonAction)"
            />
        </div>
    </Modal>
</template>

<script lang="ts" setup>
import { defineEmits, defineProps } from 'vue'
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'

export type BtnActions = 'close'

export type NotificationModalOptions = {
    imgSrc?: string,
    imgAlt?: string,
    header?: string,
    title: string,
    subtitle: string,
    buttonText: string,
    buttonAction: BtnActions,
}

type NotificationModalProps = {
    isOpen: boolean,
    options: NotificationModalOptions,
}

defineProps<NotificationModalProps>()
/* eslint-disable no-unused-vars */
const emit = defineEmits<{(e: 'handleAction', action: BtnActions): void
    (e: 'closeClaimModal'): void
}>()

function handleAction(action: BtnActions) {
    emit('handleAction', action)
}
</script>

<style module>
.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: var(--gap-negative-regular);
    padding: var(--gap-xxlarge);
    box-sizing: border-box;
    background: radial-gradient(circle at 50% 35%, var(--purple-16) 0 , transparent 45%, transparent)
}

.coinsImg {
    height: 120px;
}

.bonusAmount {
    font-size: 40px;
    line-height: 49px;
    font-weight: var(--font-weight-medium);
}

.title {
    margin-top: var(--gap-small);
}

.supportingText {
    margin-top: var(--gap-large);
    color: var(--white-50);
    font-weight: var(--font-weight-regular);
    text-align: center;
}

.closeBtn {
    margin-top: var(--gap-regular);
    width: 100%;
    height: 34px;
}
</style>
