/* eslint-disable max-len */
export const simulator = `
    <section class="textRegular">
        <h2 class="headerOne">Simulator Games — Play Online</h2>
        <p>Simulator games are the type of video games genre that aims to simulate real-activities, making the gameplay experience as close to reality as possible. The genre's roots can be traced back to the emergence of flight simulators in the early 1980s, such as 'Microsoft Flight Simulator' which is considered to be one of the longest-running, best-known, and most comprehensive flight simulators available. Simulator games can cover a variety of sub-genres including but not limited to racing simulators, flight simulators, city-building simulators, and even life simulators! This genre provides players with a vast number of gameplay experiences, ranging from commanding gigantic spaceships in a space simulator, managing cities in a city-building simulator, to experiencing life in different roles in life simulators. Simulator games are respected for their attention to detail, realism, and the vastness of the game mechanics they provide to a player.</p>
    </section>
    <section class="textRegular">
        <h2 class="headerOne">How to Play Simulator Games</h2>
        <p>The mechanics of simulator games usually revolve around controlling a particular object, entity, or a system. Some simulators require you to manage and operate complex machinery, like an aircraft in a flight simulator game or a vehicle in a racing simulator. Others might have you managing an ecosystem, like a city-building or business simulation game. Simulator games are usually open-ended, offering an in-depth experience that gives you the freedom to control and manage as per your own strategy and plan. So, you are allowed to experiment and improvise, gradually learning the in-depth game mechanics and improving your skills. With simulator games, you can experience various walks of life and professions which you would not otherwise get a chance to experience.</p>
        <ul>
            <li>Understanding the game mechanics is key to mastering simulator games. Make sure to go through any tutorials or guides the game provides.</li>
            <li>Experiment! Simulator games often allow for different approaches and strategies.</li>
            <li>Pacing is often crucial in simulation games. Don't rush, take your time to understand the impact of your actions in the game.</li>
            <li>Always be ready to learn and adapt. Part of the appeal of simulation games is the depth and complexity they offer.</li>
            <li>Enjoy the experience. Whether you're piloting a plane or building a city, the goal is to make the experience as fun and engaging as possible.</li>
        </ul>
    </section>
`
