<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M12 2.4a1.6 1.6 0 0 0-.962.323v-.001C8.338 4.839 2.4 7.292 2.4 12.364A5.236 5.236 0 0 0 7.636 17.6c1.819 0 3.12-1.031 3.807-1.742-.475 1.435-1.352 3.075-2.994 4.222v.003A.8.8 0 0 0 8.8 21.6h6.4a.8.8 0 0 0 .352-1.517v-.003c-1.642-1.147-2.52-2.787-2.994-4.222.687.71 1.988 1.742 3.807 1.742a5.236 5.236 0 0 0 5.235-5.236c0-5.073-5.937-7.525-8.637-9.642l-.002.001a1.6 1.6 0 0 0-.96-.323"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Cards',
})
</script>
