<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><g clip-path="url(#a)"><path fill="#fff" d="M12 2.4a.8.8 0 0 0-.378.095L.43 8.091v.001a.8.8 0 0 0 0 1.416v.001l11.187 5.593a.8.8 0 0 0 .761.003l11.185-5.592.007-.004v-.001a.8.8 0 0 0 .314-1.122.8.8 0 0 0-.314-.294v-.001L12.383 2.498A.8.8 0 0 0 12 2.4m11.2 9.083-1.6.8v7.139a1.59 1.59 0 0 0-.8 1.378c0 .884 1.6 3.2 1.6 3.2s1.6-2.316 1.6-3.2a1.59 1.59 0 0 0-.8-1.378zM4.8 13.48v3.32c0 2.209 3.223 4 7.2 4s7.2-1.791 7.2-4v-3.319l-6.108 3.055a2 2 0 0 1-.203.084q-.435.18-.889.18-.452 0-.886-.18a2 2 0 0 1-.206-.086z"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Educational',
})
</script>
