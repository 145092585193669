<template>
    <ul v-if="buttons.length" :class="$style.list">
        <li
            v-for="button in buttons"
            :key="button.text"
        >
            <Button
                plain
                size="m"
                :title="button.text"
                :link="button.to"
                :active="button.page === activePage"
                :disabled="button.isDisabled || activePage === button.page"
            />
        </li>
    </ul>
</template>
<script lang="ts" setup>
import { computed, defineProps } from 'vue'
import { RouteLocationRaw } from 'vue-router'
import Button from '@/components/Button.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

type Props = {
    activePage: number,
    pages: number,
    maxIndexButtons: number
}

const props = defineProps<Props>()

type PaginationElement = {
    to: string | RouteLocationRaw,
    text: string,
    page: number | null,
    isDisabled?: boolean,
}

function getRouteByPage(page: number): RouteLocationRaw {
    return {
        query: {
            page: page === 1 ? undefined : page,
        },
    }
}

const buttons = computed<PaginationElement[]>(() => {
    if (props.pages === 1) {
        return []
    }
    const result: PaginationElement[] = []
    if (props.activePage > 1) {
        result.push({
            text: t('prev'),
            to: getRouteByPage(props.activePage - 1),
            page: props.activePage - 1,
        })
    } else {
        result.push({
            text: t('prev'),
            to: '',
            page: null,
            isDisabled: true,
        })
    }

    // если мы в начале списка
    let startPage = 1

    const median = props.maxIndexButtons / 2

    if (props.maxIndexButtons >= props.pages) {
        // если список меньше, чем мы можем отобразить
        startPage = 1
    } else if (props.activePage > Math.ceil(median) && props.activePage < props.pages - Math.floor(median)) {
        // если мы в середине списка с каждого конца у нас достаточно элементов для отрисовки активной кнопки по центру
        startPage = props.activePage - Math.floor(median)
    } else if (props.activePage >= props.pages - Math.floor(median)) {
        // если мы в конце списка
        startPage = props.pages - props.maxIndexButtons + 1
    }

    for (let i = 0; i < Math.min(props.maxIndexButtons, props.pages); i += 1) {
        result.push({
            text: String(startPage + i),
            to: getRouteByPage(startPage + i),
            page: startPage + i,
        })
    }

    if (props.activePage < props.pages) {
        result.push({
            text: t('next'),
            to: getRouteByPage(props.activePage + 1),
            page: props.activePage + 1,
        })
    } else {
        result.push({
            text: t('next'),
            to: '',
            page: null,
            isDisabled: true,
        })
    }

    return result
})
</script>
<style module>
.list {
    display: flex;
    gap: var(--gap-small)
}
</style>
<i18n>
{
    "en": {
        "prev": "Prev",
        "next": "Next"
    }
}
</i18n>
