import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import type {
    CategoryListType,
    GameType,
    PaginationType,
    SimplifiedGame,
} from '@/types'
import { ICONS_FOR_CATEGORIES } from '@/constants/general'
import { getGamesByCategories, getManyGamesData } from '@/utils/requests/games'
import { pickManyRandomElements } from '@/utils/helpers'
import { useAppStore } from '@/store/app-store'

const VIEWED_GAMES_KEY = 'playgama:viewed_games'

export const POPULAR_GAMES = ['roblox-obby-tower-of-hell', 'magic-tiles-4', 'obby-but-youre-on-a-bike', 'brawl-box-stars-simulator', 'draw-joust', 'starr-drops', 'faster-but-surely', 'basket-random', 'tiles-hop', 'brawl-stars-mega-simulator', 'bubble-pop-classic', 'brawl-stars-lucky-box-simulator', 'ball-sort-puzzle--color-sort', 'granny-original', 'ball-sort-puzzle', 'capybara-evolution-clicker', 'tap-wood-blocks-away', 'wood-block-classic', 'obby-tower-of-hell', 'save-the-pets', 'imposter-3d-online-horror', 'geometry-dash-super-wave', 'cs-go-parkour--bhop-and-surf-in-3d', 'brick-breaker-chipi-chipi-chapa-chapa-cat', 'table-tennis-world-tour', 'noob-miner-2-escape-from-prison', 'attack-hole', 'noob-obby-on-a-bike', 'noob-lumberjack', 'geometry-dash-hardcore', 'words-of-wonders#goog_game_inter']

export const useCategoriesStore = defineStore('categories', () => {
    const menuHighlightCategoriesList = ref<CategoryListType[]>([])
    const contentCategoriesList = ref<CategoryListType[]>([])
    const menuCategoriesList = ref<CategoryListType[]>([])
    const randomGame = ref<CategoryListType | null>(null)
    const popularGames = ref<SimplifiedGame[]>([])
    const randomGames = ref<SimplifiedGame[]>([])
    const viewedGamesCollection = ref<Map<string, SimplifiedGame>>(new Map())
    const viewedGames = computed(() => Array.from(viewedGamesCollection.value.values()))
    const recommendedGames = ref<SimplifiedGame[] | null>(null)

    const appStore = useAppStore()

    async function setCategories(pagination: PaginationType): Promise<void> {
        const currentPlatform = appStore.platform
        const data = await getGamesByCategories({ platform: currentPlatform, pagination })
        const notInternal: CategoryListType[] = []
        const internalCategories: CategoryListType[] = []
        let random: CategoryListType | null = null
        const { categories } = data
        categories.forEach(({ id, internal, games }) => {
            if (!games.length) {
                return
            }
            if (internal) {
                if (id !== 'random') {
                    internalCategories.push({
                        name: id,
                        href: `/category/${id}`,
                        icon: ICONS_FOR_CATEGORIES[id],
                        games,
                    })
                } else {
                    randomGames.value = games.filter(Boolean)
                    if (games[0]?.hru) {
                        random = {
                            name: 'random',
                            href: `/game/${games[0].hru}`,
                            icon: ICONS_FOR_CATEGORIES[id],
                        }
                    }
                }
            } else {
                notInternal.push({
                    name: id,
                    href: `/category/${id}`,
                    icon: ICONS_FOR_CATEGORIES[id],
                    games,
                })
            }
        })

        contentCategoriesList.value = [
            ...internalCategories,
            ...notInternal,
        ]

        menuCategoriesList.value = notInternal

        menuHighlightCategoriesList.value = [
            {
                name: 'all_games',
                href: '/',
                icon: ICONS_FOR_CATEGORIES.all_games,
            },
            ...internalCategories,
            random,
        ].filter(Boolean) as CategoryListType[]

        randomGame.value = random
    }

    async function setPopularGames() {
        const popularGamesHru = pickManyRandomElements(POPULAR_GAMES, 9)
        const selectedPopularGames = await getManyGamesData(popularGamesHru)
        const selectedRandomGames = pickManyRandomElements(randomGames.value, 3)
        popularGames.value = [...selectedPopularGames, ...selectedRandomGames]
    }

    function getRandomCategories(num: number): false | CategoryListType[] {
        return contentCategoriesList.value?.length > 0
            && pickManyRandomElements(contentCategoriesList.value, num)
                .filter(({ games }) => games && games.length > 0)
    }

    function updateViewedGame(gameId: string, gameData: GameType): void {
        if (viewedGamesCollection.value.has(gameId)) {
            return
        }
        const games: string[] = []
        viewedGamesCollection.value.forEach((game) => {
            games.push(game.hru)
        })
        window.localStorage.setItem(
            VIEWED_GAMES_KEY,
            JSON.stringify([...games, gameId]),
        )
        viewedGamesCollection.value.set(gameId, {
            hru: gameData.hru,
            title: gameData.title,
            preview: gameData.preview,
            video: gameData.videos?.[0] ? {
                preview_url: gameData.videos[0].preview_url,
            } : undefined,
        })
    }

    async function setRecommendedGames(): Promise<void> {
        try {
            const data = window.localStorage.getItem(VIEWED_GAMES_KEY)
            const gameIds = JSON.parse(data as string)
            const gamesData = await getManyGamesData(gameIds)
            gamesData.forEach((game) => {
                if (!game || viewedGamesCollection.value.has(game.hru)) {
                    return
                }
                viewedGamesCollection.value.set(game.hru, game)
            })
        } catch (err) {
            viewedGamesCollection.value = new Map()
        } finally {
            const games = pickManyRandomElements(Array.from(viewedGamesCollection.value.values()), 4)
            if (games.length < 4) {
                const additionalGamesNumber = 4 - games.length
                const randomGamesNames = pickManyRandomElements(POPULAR_GAMES, additionalGamesNumber)
                const recommendedGamesData = await getManyGamesData(randomGamesNames)
                recommendedGames.value = [
                    ...games,
                    ...recommendedGamesData,
                ]
            } else {
                recommendedGames.value = games
            }
        }
    }

    async function setMainData() {
        await setCategories({
            page: 1,
            pageSize: 12,
        })
        await setPopularGames()
    }

    return {
        menuHighlightCategoriesList,
        menuCategoriesList,
        contentCategoriesList,
        randomGame,
        updateViewedGame,
        setRecommendedGames,
        popularGames,
        randomGames,
        recommendedGames,
        setMainData,
        getRandomCategories,
        viewedGames,
    }
})
