<template>
    <DashboardCard>
        <template #title>
            {{ t('statistics') }}
        </template>
        <ul :class="$style.statisticsList">
            <li :class="$style.statisticsItem">
                <span :class="$style.statisticsField">{{ t('gamesPlayed') }}</span>
                <span>{{ gamesPlayed }}</span>
            </li>
            <li v-if="daysOfMembership" :class="$style.statisticsItem">
                <span :class="$style.statisticsField">{{ t('memberFor') }}</span>
                <span>
                    {{ t('membershipDays', daysOfMembership) }}
                </span>
            </li>
            <li v-if="currentStreak > 1" :class="$style.statisticsItem">
                <span :class="$style.statisticsField">{{ t('playstreak') }}</span>
                <span>
                    {{ t('playstreakDays', { days: currentStreak }) }}
                </span>
            </li>
        </ul>
    </DashboardCard>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { calculateDifferenceInDays } from '@/utils/helpers'
import DashboardCard from './DashboardCard.vue'

type UserStatisticsProps = {
    gamesPlayed: number
    currentStreak: number
    createdAt?: number
}

const props = defineProps<UserStatisticsProps>()

const daysOfMembership = computed<number>(() => {
    if (props.createdAt) {
        const date1 = new Date(props.createdAt)
        const date2 = new Date()
        // Convert dates to UTC timestamps
        return calculateDifferenceInDays(date1, date2)
    }
    return 0
})

const { t } = useI18n()
</script>

<style module>
.statisticsList {
    display: flex;
    flex-direction: column;
    gap: var(--gap-regular);
}

.statisticsItem {
    composes: headerTwo from global;
    display: flex;
    justify-content: space-between;
}

.statisticsField {
    color: var(--white-50);
}
</style>

<i18n lang="json">
{
    "en": {
        "statistics": "Statistics",
        "gamesPlayed": "Games Played",
        "memberFor": "Member for",
        "membershipDays": "1 day | {n} days",
        "playstreak": "Playstreak",
        "playstreakDays": "{days} days"
    }
}
</i18n>
