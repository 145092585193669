<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M7.2 2.4a.8.8 0 0 0-.8.8v7.2H4.941L3.013 8.284a.8.8 0 0 0-1.383.732l.564 2.184H1.6a.8.8 0 1 0 0 1.6h.594l-.564 2.184a.8.8 0 0 0 1.363.754L4.94 13.6H6.4v7.2a.8.8 0 0 0 .8.8H8l1.964-2.4H12a.8.8 0 1 0 0-1.6h-.729l3.274-4H20c.787 0 2.01-.907 2.052-.94a.8.8 0 0 0 .014-1.31l-.033-.023h-.001c-.096-.071-1.264-.927-2.032-.927h-5.454l-3.272-4H12a.8.8 0 1 0 0-1.6H9.965L8 2.4z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Simulator',
})
</script>
