<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M17.997 1.44a2.16 2.16 0 1 0 .005 4.32 2.16 2.16 0 0 0-.005-4.32M11.04 4.8a.95.95 0 0 0-.516.153l-.018.003L7.18 7.343v.001a.96.96 0 0 0-.46.816c0 .53.43.96.96.96.236 0 .45-.088.617-.23l.002.003 2.876-2.068 2.117.671-2.368 3.86.001.005a1.9 1.9 0 0 0-.365 1.119 1.91 1.91 0 0 0 1.04 1.697v.005l3.642 2.247-.814 4.197A1.2 1.2 0 0 0 15.6 22.08a1.2 1.2 0 0 0 1.178-.979l.974-5.122-.005-.012q.011-.063.013-.127c0-.28-.122-.53-.313-.705l-.004-.011-2.593-2.3 2.243-4.432-.003-.009c.118-.209.19-.446.19-.703a1.44 1.44 0 0 0-.867-1.32v-.004l-1.205-.67-3.918-.854-.012.002a1 1 0 0 0-.238-.034M4.422 7.864a.96.96 0 0 0-.69.256L2.207 9.535a.96.96 0 0 0-.051 1.357l2.132 2.299h.001a.95.95 0 0 0 .704.306.95.95 0 0 0 .652-.256l1.526-1.415a.956.956 0 0 0 .05-1.358L5.09 8.17a.95.95 0 0 0-.667-.306m13.44 1.132-1.076 2.126.58 1.281.005.004a.96.96 0 0 0 .868.553h3.36a.96.96 0 1 0 0-1.92h-2.88zM9.85 13.64l-1.093 1.72H5.039a1.199 1.199 0 1 0 0 2.4h4.048c.623 0 1.206-.3 1.566-.81l1.097-1.546c-.398-.245-.684-.424-.688-.426a2.87 2.87 0 0 1-1.213-1.338"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Stickman',
})
</script>
