import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type {
    User,
    TasksByTag,
    Task,
    ShopItem,
    UserSocialsType, TaskStats, SimplifiedGame,
} from '@/types'
import { TaskStatus, TaskType } from '@/types'
import { request } from '@/utils/requests/request'
import { reducePriceTag } from '@/utils/helpers'
import { getManyGamesData } from '@/utils/requests/games'

type ResponseStatus = 'success' | 'error'

const DEFAULT_INTERVAL_TO_SHOW_ERROR = 5000

const COINS_FOR_AUTH = 1000

export const useUserStore = defineStore('user', () => {
    const user = ref<User | null>(null)
    const taskStats = ref<TaskStats>({
        tasks: 0,
        fulfilled: 0,
        unclaimed: 0,
    })
    const isUserInfoLoaded = ref(false)
    const isAuthorized = ref<boolean>(false)
    const userError = ref<number | null>(null)
    const tasksError = ref<number | null>(null)
    const tasksByTags = ref<TasksByTag[]>([])
    const shopItems = ref<ShopItem[]>([])
    const tasksMap = computed(() => tasksByTags.value
        .reduce<Record<string, Task>>((acc, { tasks }) => {
            tasks.forEach((task) => {
                acc[task._id] = task
            })
            return acc
        }, {}))
    const fennecsLabel = computed(() => reducePriceTag(
        isAuthorized.value && user.value ? user.value.fennecs : COINS_FOR_AUTH,
    ))
    const taskGamesCache:Record<string, SimplifiedGame> = {}

    async function getUser(): Promise<User | null> {
        const { data, originalResponse } = await request<User>('/api/v1/user/current')

        isUserInfoLoaded.value = true
        if (!data) {
            isAuthorized.value = false
            user.value = null
            userError.value = originalResponse.status
            setTimeout(() => {
                userError.value = null
            }, DEFAULT_INTERVAL_TO_SHOW_ERROR)
            return null
        }

        isAuthorized.value = true
        user.value = data
        return data
    }

    async function getUserTasks() {
        const { originalResponse, data } = await request<TasksByTag[]>('/api/v1/tasks')
        if (!originalResponse.ok) {
            tasksError.value = originalResponse.status
            setTimeout(() => {
                tasksError.value = null
            }, DEFAULT_INTERVAL_TO_SHOW_ERROR)
            return
        }
        tasksByTags.value = data

        taskStats.value = tasksByTags.value.reduce((acc, item) => {
            item.tasks.forEach((task) => {
                acc.tasks += 1
                switch (task.status) {
                case TaskStatus.FULFILLED:
                    acc.fulfilled += 1
                    acc.unclaimed += 1
                    break
                case TaskStatus.CLAIMED:
                    acc.fulfilled += 1
                    break
                default:
                    break
                }
            })
            return acc
        }, {
            tasks: 0,
            fulfilled: 0,
            unclaimed: 0,
        })

        tasksByTags.value.forEach(({ tasks }) => {
            tasks.forEach(async (task) => {
                if (task.type === TaskType.PLAY_GAMES) {
                    const games = task.settings.split(',')
                    const gamesToFetch = games.filter((hru) => !taskGamesCache[hru])
                    if (gamesToFetch.length) {
                        const fetchedGames = await getManyGamesData(gamesToFetch)
                        fetchedGames.forEach((game) => {
                            taskGamesCache[game.hru] = game
                        })
                    }
                    // eslint-disable-next-line no-param-reassign
                    task.games = games.map((hru) => taskGamesCache[hru])
                }
            })
        })
    }

    async function getShopItems(): Promise<void> {
        const { data } = await request<ShopItem[]>('/api/v1/shop/items')
        shopItems.value = data
    }

    async function claimUserBonus(task: Task): Promise<ResponseStatus> {
        const { originalResponse, data } = await request<{ status: ResponseStatus }>(
            `/api/v1/tasks/claim?task=${task._id}`,
            {
                method: 'POST',
            },
        )
        if (!originalResponse.ok) {
            return 'error'
        }
        const { status } = data
        if (status === 'error') {
            return status
        }

        tasksMap.value[task._id].status = TaskStatus.CLAIMED

        user.value!.fennecs += task.reward

        return status
    }

    async function logout() {
        await request('/api/v1/auth/logout', { method: 'post' })
        user.value = null
        shopItems.value = []
        isAuthorized.value = false
        tasksByTags.value = []
    }

    async function buyItem(item: ShopItem): Promise<ResponseStatus> {
        const { originalResponse, data } = await request<{ status: ResponseStatus }>(
            `/api/v1/shop/buy?itemId=${item._id}`,
            {
                method: 'POST',
            },
        )
        if (!originalResponse.ok) {
            return 'error'
        }
        const { status } = data
        if (status === 'error') {
            return status
        }

        user.value!.fennecs -= item.price

        shopItems.value!.forEach((i) => {
            if (i._id === item._id) {
                // eslint-disable-next-line no-param-reassign
                i.boughtCount += 1
                if (i.boughtCount >= i.maxItemsPerUser) {
                    // eslint-disable-next-line no-param-reassign
                    i.status = 'BOUGHT'
                }
            }
        })
        return status
    }

    async function updateSocials({ key, account }: UserSocialsType) {
        const { originalResponse } = await request('/api/v1/user', {
            method: 'post',
            body: JSON.stringify({
                [key]: account,
            }),
        })
        if (originalResponse.ok) {
            user.value![key] = account
        } else {
            // ???
        }
    }

    return {
        user,
        taskStats,
        isAuthorized,
        isUserInfoLoaded,
        getUser,
        getUserTasks,
        tasksByTags,
        claimUserBonus,
        logout,
        getShopItems,
        shopItems,
        buyItem,
        updateSocials,
        fennecsLabel,
    }
})
