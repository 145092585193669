<template>
    <Modal
        :isOpen="isAuthUserModalOpen"
        @closeModal="closeModal"
    >
        <div :class="$style.modalContent">
            <img
                :class="$style.loginImg"
                src="/img/user-icon.png"
                alt="fennec"
            >
            <div :class="$style.container">
                <div :class="$style.header">{{ t('signIn') }}</div>
                <div :class="['supportingText', $style.supportingText]">{{ t('join') }}</div>
                <GoogleAuth />
            </div>
        </div>
    </Modal>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import GoogleAuth from '@/components/GoogleAuth.vue'
import Modal from '@/components/Modal.vue'
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app-store'

const appStore = useAppStore()

const { setIsAuthUserModalOpen } = appStore
const { isAuthUserModalOpen } = storeToRefs(appStore)

function closeModal() {
    setIsAuthUserModalOpen(false)
}

const { t } = useI18n()
</script>
<style module>
.modalContent {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: var(--gap-medium);
    background: radial-gradient(circle at 50% 35%, var(--purple-16) 0 , transparent 45%, transparent)
}

.loginImg {
    width: 150px;
    margin-bottom: var(--gap-large);
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap-large);
    min-height: 180px;
}

.terms {
    margin-left: var(--gap-small);
    color: var(--white-50);
}

.supportingText {
    color: var(--white-50);
}

.link {
    text-decoration: underline;
    color: var(--accent-purple)
}
.header {
    composes: headerOne from global;
    text-align: center;
}
@media (--mobile) {
    .supportingText {
        text-align: center;
        padding: 0 var(--gap-large);
    }
}
</style>
<i18n lang="json">
{
    "en": {
        "signIn": "Sign in you Playgama Account",
        "join": "Join Playgama and Track Your Gaming\xa0Progress!"
    }
}
</i18n>
