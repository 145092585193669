/* eslint-disable max-len */
export const strategy = `
    <section class="textRegular">
        <h2 class="headerOne">Strategy Games — Play Online</h2>
        <p>Embrace your tactical prowess and engage in intricate gameplay with our diverse collection of online strategy games. The genre has its roots ingrained in the ancient cultures and civilizations, where strategic warfare was crucial for survival. With the rise of computers, these strategy elements transformed into an interactive experience, first marking their footprint in the world of gaming with the classic game 'Computer Bismarck' in 1980. Strategy games test your decision making and planning abilities to limit, making each gameplay a different experience. From mind-boggling puzzles, thrilling tower defense tasks, to the intricate build and conquer quests, our collection of online strategy games is poised to challenge the strategist in you.</p>
    </section>
    <section class="textRegular">
        <h2 class="headerOne">How to play Strategy Games Online</h2>
        <p>In strategy games, the gameplay primarily revolves around, as the name suggests, strategy. Unlike most other genres, where reflexes and luck might win the day, strategy games require careful thought, patience, and a knack for resource management. These games often simulate aspects such as combat, city building, or politics, and the player must use their resources efficiently to achieve the game's goals. War games require careful troop formation, economical games need wise spending, while certain themed strategy games like chess demand pure tact. The victory lies not in the strongest or the fastest but the smartest. Mastering strategy games online takes time and strategic thinking.</p>
        <ul>
            <li><b>Plan Ahead:</b>Strategy games are all about planning. Think a few steps ahead of your opponent, anticipate their moves, and have a counter-strategy ready.</li>
            <li><b>Manage Resources Wisely:</b>Resource management is key in these games. Use your resources, whether it's money, troops, or materials, wisely to gain an advantage.</li>
            <li><b>Patience:</b>Don't rush your moves. Take the time to analyze the situation and make the best decision.</li>
            <li><b>Learn from mistakes:</b>You won't win every game. When you lose, try to understand what went wrong and adjust your strategy for next time.</li>
            <li><b>Practice:</b>Last but not least, practice! The best way to improve at strategy games is by playing them. Practice different tactics, try different strategies, and evolve your gameplay.</li>
        </ul>
    </section>
`
