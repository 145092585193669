<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="m8.8 1.6-.4 2.792-1.894 3.524A3.2 3.2 0 0 1 3.688 9.6H3.2V20h1.6a5.46 5.46 0 0 0 3.863 1.6H16.8a1.6 1.6 0 0 0 1.6-1.6v-.844A2 2 0 0 0 20 17.2a2 2 0 0 0-.506-1.327 2 2 0 0 0 1.094-2.768 2 2 0 0 0-.59-.705 2 2 0 0 0 .802-1.6 2 2 0 0 0-2-2h-7.6s.8-2.613.8-3.2c0-1.8 0-4-2.4-4z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Recommended',
})
</script>
