<template>
    <div :class="$style.container">
        <header :class="$style.header">
            <button @click="openUserProfile" :class="$style.coinsBtn">
                <span :class="$style.leftBlock">
                    <img
                        src="/img/fennec-coin.png"
                        alt="fennec coin"
                        :class="$style.fennecCoin"
                    />
                    <span :class="$style.fennecsAmount">{{ fennecsLabel }}</span>
                    <template v-if="user && isAuthorized">
                        <span :class="[$style.coinsInfoIcon, {[$style.taskUnclaimed]: taskStats.unclaimed}]">📬</span>
                        <span :class="$style.coinsInfo">
                            {{ taskStats.fulfilled }}/{{ taskStats.tasks }}
                            <span :class="$style.taskText">{{ t('tasksToday') }}</span>
                        </span>
                    </template>
                    <template v-else>
                        <span :class="$style.coinsInfoIcon">🔒</span>
                    </template>
                </span>
            </button>
            <span v-if="isUserInfoLoaded" :class="$style.rightBlock">
                <template v-if="isAuthorized && user">
                    <RouterLink
                        :class="$style.userInfo"
                        to="/profile"
                    >
                        <UserAvatar
                            :class="$style.avatar"
                            :avatar="user.avatar"
                            :firstName="user.firstName"
                            :lastName="user.lastName"
                        />
                        <span :class="$style.userName">{{ user.firstName }} {{ user.lastName }}</span>
                    </RouterLink>
                </template>
                <Button
                    v-else
                    :class="$style.loginBtn"
                    :title="t('login')"
                    text
                    size="s"
                    @click="openLoginWindow"
                />
            </span>
        </header>
        <LoginModal />
    </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/user-store'
import { useAppStore } from '@/store/app-store'
import Button from '@/components/Button.vue'
import LoginModal from '@/components/LoginModal/LoginModal.vue'
import UserAvatar from '@/components/UserAvatar.vue'

const userStore = useUserStore()
const appStore = useAppStore()
const { t } = useI18n()
const router = useRouter()

const { setIsAuthUserModalOpen } = appStore

const {
    user, taskStats, isAuthorized, fennecsLabel, isUserInfoLoaded,
} = storeToRefs(userStore)

function openLoginWindow() {
    setIsAuthUserModalOpen(true)
}

function openUserProfile() {
    if (isUserInfoLoaded.value) {
        if (!isAuthorized.value) {
            setIsAuthUserModalOpen(true)
            openLoginWindow()
            return
        }
        router.push('/profile')
    }
}
</script>
<!-- eslint-disable max-len -->
<style module>
.container {
    position: relative;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 0 var(--gap-regular);
    margin-bottom: var(--gap-large);
    box-sizing: border-box;
    background: url('@/../public/img/fennec-hello.png') no-repeat center top, linear-gradient(0deg, var(--black) 0%, var(--dark-purple) 100%);
    background-size: contain;
    border: 1px solid var(--dark-purple-50);
    border-radius: var(--radius-large);
    line-height: 0;
}

.rightBlock, .leftBlock {
    display: flex;
    align-items: center;
    line-height: 0;
}

.coinsBtn {
    cursor: pointer;
}

.fennecCoin {
    width: 24px;
    height: 24px;
}

.fennecsAmount {
    composes: headerTwo from global;
    margin-left: var(--gap-small);
}

.taskUnclaimed {
    position: relative;
}

.taskUnclaimed::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: var(--notification-red);
}

.taskText,
.coinsInfoIcon,
.coinsInfo {
    margin-left: var(--gap-small);
    line-height: var(--line-height-regular);
}

.userInfo {
    display: inline-flex;
    margin-left: auto;
    align-items: center;
    cursor: pointer;
}

.loginBtn {
    display: inline-flex;
}

.avatar {
    margin-right: var(--gap-small);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: center center / cover var(--accent-purple);
}

@media (--mobile) {
    .taskText,
    .userName {
        display: none;
    }

    .header {
        background: linear-gradient(0deg, var(--black) 0%, var(--dark-purple) 100%);
    }
}
</style>
<i18n lang="json">
{
    "en": {
        "tasksToday": "Tasks Today",
        "login": "Login"
    }
}
</i18n>
